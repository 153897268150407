import { IconMail, IconMailX } from '@tabler/icons-react'
import React from 'react'
import { Col, ListGroup, OverlayTrigger, Row, Tooltip } from 'react-bootstrap'
import { Form } from 'react-router-dom'
import ModalEmailActivityController from '../../components/modalEmailActivity/indexController'
import InfiniteScroll from 'react-infinite-scroll-component'
import Loading from '../../../../components/loading'

const ListOfEmailsActivity = ({
    showModal,
    handleOpenModal,
    handleCloseModal,
    updateListfunction,
    hasMoreInformation,
    data,
    isLoading,
    convertDate,
    convertStatus,
    emailInfo,
    getEmailInfo,
    emailHist,
    noResults
}) => {

    return (
        <>
            <Loading visibility={isLoading}></Loading>
            {
                data.length > 0 ?
                    <div style={{ marginTop: '2vh' }} className="ps-3">
                        <ListGroup variant="flush">
                            <ListGroup.Item className="TicketsRowCollums">
                                <Row name="ListOfAgentsHead">

                                    <Col md={1} className='nopadding'>
                                        <p>Status</p>
                                    </Col>
                                    <Col md={3} className="nopadding">
                                        <p>De</p>
                                    </Col>
                                    <Col md={3} className="nopadding">
                                        <p>Para</p>
                                    </Col>
                                    <Col md={3} className="nopadding">
                                        <p>Assunto</p>
                                    </Col>
                                    <Col md={2} className="nopadding" /* style={{ width: '24%' }} */>
                                        <p>Data do último evento</p>
                                    </Col>
                                </Row>
                            </ListGroup.Item>
                        </ListGroup>
                        <div style={{ overflow: 'auto', height: '50vh' }}>
                            <InfiniteScroll
                                dataLength={data.length}
                                next={updateListfunction}
                                hasMore={hasMoreInformation as boolean}
                                loader={<h4> </h4>}
                                scrollableTarget={"ListOfBlockEmails"}
                            >
                                <ListGroup variant="flush" className="TicketsRow" >
                                    {
                                        data.map((item, index) => (
                                            <ListGroup.Item id={"*EMAIL*" + item.msg_id} onClick={/* (e) => handleOpenModal(e); */(e) => getEmailInfo(item.to_email, e)} style={{cursor:'pointer'}} key={index}>
                                                <Row className='TicketsRowHeight'>
                                                    <Col md={1} className='align-self-center nopadding'>
                                                        <span>
                                                            {convertStatus(item.status)}
                                                        </span>
                                                    </Col>

                                                    <Col md={3} className='align-self-center nopadding'>
                                                        <div className='oneLineClampEllipsis'>
                                                            <OverlayTrigger
                                                                placement="top"
                                                                overlay={
                                                                    <Tooltip>
                                                                        {item.from_email}
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                <span>
                                                                    {item.from_email}
                                                                </span>
                                                            </OverlayTrigger>
                                                        </div>
                                                    </Col>
                                                    <Col md={3} className='align-self-center nopadding'>
                                                        <div className='oneLineClampEllipsis'>
                                                            <OverlayTrigger
                                                                placement="top"
                                                                overlay={
                                                                <Tooltip>
                                                                    {item.to_email}
                                                                </Tooltip>
                                                                }
                                                            >
                                                                <span>
                                                                    {item.to_email}
                                                                </span>
                                                            </OverlayTrigger>
                                                        </div>
                                                    </Col>
                                                    <Col md={3} className='align-self-center nopadding'>
                                                        <div className='oneLineClampEllipsis'>
                                                        <OverlayTrigger
                                                            placement="top"
                                                            overlay={
                                                            <Tooltip>
                                                                {item.subject}
                                                            </Tooltip>
                                                            }
                                                        >
                                                            <span>
                                                                {item.subject}
                                                            </span>
                                                            
                                                        </OverlayTrigger>
                                                        </div>
                                                    </Col>
                                                    <Col md={2} className='align-self-center'>
                                                        <span>
                                                            {convertDate(item.last_event_time)}
                                                        </span>
                                                    </Col>

                                                </Row>
                                            </ListGroup.Item>
                                        ))}
                                </ListGroup>
                            </InfiniteScroll>
                        </div>

                    </div>
                    :
                    // caso ele tenha os dados zerados, verifica se houve filtragem com o resultado vazio
                    noResults ? 
                        <div className='align-self-center' style={{ overflow: 'hidden', height: '60vh', /* backgroundColor: '#F9F9F9' */ }}>
                            <Row className='d-flex justify-content-center align-content-center' style={{ marginTop: '20vh', /* backgroundColor: '#F9F9F9' */ }}>
                                <Col className='d-flex flex-column justify-content-center align-items-center'>
                                    <IconMailX stroke={1.6} strokeWidth={1.5} color="#4a69bd" size={'4vw'} />
                                    <p style={{ color: '#0C2461', font: 'normal normal bold calc(0.4em + 0.7vw) Montserrat', width: '35%', textAlign: 'center' }}>Sem resultados</p>
                                </Col>
                            </Row>
                        </div>
                        :
                        <div className='align-self-center' style={{ overflow: 'hidden', height: '60vh', /* backgroundColor: '#F9F9F9' */ }}>
                            <Row className='d-flex justify-content-center align-content-center' style={{ marginTop: '20vh', /* backgroundColor: '#F9F9F9' */ }}>
                                <Col className='d-flex flex-column justify-content-center align-items-center'>
                                    <IconMail stroke={1.6} strokeWidth={1.5} color="#4a69bd" size={'4vw'} />
                                    <p style={{ color: '#0C2461', font: 'normal normal bold calc(0.4em + 0.7vw) Montserrat', width: '35%', textAlign: 'center' }}>Busque informações sobre todos os e-mails enviados nos últimos 30 dias</p>
                                </Col>
                            </Row>
                        </div>
            }

            <ModalEmailActivityController
                show={showModal}
                handleClose={handleCloseModal}
                data={emailHist}
                convertDate={convertDate}
                convertStatus={convertStatus}
                emailInfo={emailInfo}
            />

        </>
    )
}

export default ListOfEmailsActivity