import React from 'react';
import Loading from '../../../../components/loading';
import { Button, Col, ListGroup, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import InfiniteScroll from 'react-infinite-scroll-component';
import { TicketStatus } from '../../../../core/enums/ticket-status';
import ModalEditTaskController from '../modalEditTask/indexController';
import Utils from '../../../../core/shared/utils';
import { ListName } from '../../../../core/enums/order-sort-by';
import { IconCheckupList } from '@tabler/icons-react';
import ViewTicketController from '../../homeTickets/viewTicket/indexController';

const ListOfTasks = ({
    tasks,
    widthCols,
    concatenateTags,
    popoverAction,
    setCurrentTaskInfo,
    getTasks,
    updateListFunction,
    isLoading,
    setIsLoading,
    dataEdit,
    isNotEditable,
    getStatusElement,
    t,
    currentTicketId,
    view,
    setView,
    setOpenModalPhone,
    setPhoneNumber,
    getAllTickets,
    currentParams,
    currentParamsTask,
    callbackModalTasks,
    allTicketData,
    setAllTicketData
}) => {
    return (
        <>
            <Loading visibility={isLoading} />
            <ModalEditTaskController setIsLoading={setIsLoading} dataEdit={dataEdit} getTasks={getTasks} isNotEditable={isNotEditable} currentParamsTask={currentParamsTask} callbackModalTasks={callbackModalTasks} allTicketData={allTicketData} setAllTicketData={setAllTicketData}/>
            {view === true ?

                <ViewTicketController setOpenModalPhone={(value: any) => { setOpenModalPhone(value) }} setPhoneNumber={(value: string) => { setPhoneNumber(value) }} currentTicket={({id: currentTicketId})} filters={currentParams} getList={getAllTickets} taskUpdate={true} currentViewFilter={'all'} setCurrentParams={() => {}}></ViewTicketController>
                :

            <div style={{ overflow: 'auto', margin: '2.5vh 0.5vw 0px', padding: '0' }} className="ps-2">
                {
                    tasks && tasks.length > 0 ?
                        <ListGroup variant="flux" className="TicketsRow">
                            <ListGroup.Item className="TicketsRowCollums mb-3" style={{ border: 'none' }} id="headerList" key="headerList">
                                <Row name="ListOfTasksHead">
                                    <Col md={1} className="d-flex" style={{ width: widthCols.requester }}>
                                        {t('home_tasks.list_of_tasks.requester')}
                                    </Col>
                                    <Col md={1} className="nopadding" style={{ width: widthCols.assigned }} >
                                        {t('home_tasks.list_of_tasks.assigned')}
                                    </Col>
                                    <Col md={1} className="nopadding" style={{ width: widthCols.date }}>
                                        {t('home_tasks.list_of_tasks.limit_date')}
                                    </Col>
                                    <Col md={1} className="nopadding" style={{ width: widthCols.description }}>
                                        {t('home_tasks.list_of_tasks.description')}
                                    </Col>
                                    <Col md={1} className="nopadding" style={{ width: widthCols.tags }}>
                                        {t('home_tasks.list_of_tasks.tags')}
                                    </Col>
                                    <Col md={1} className="nopadding" style={{ width: widthCols.status, marginLeft: '2 rem' }}>
                                        {t('home_tasks.list_of_tasks.status')}
                                    </Col>
                                    <Col className='d-flex justify-content-end' style={{}}>
                                        {t('home_tasks.list_of_tasks.actions')}
                                    </Col>
                                </Row>
                            </ListGroup.Item>
                            <div id="ListOfTasks" style={{ overflowY: 'auto', height: '58vh' }}>
                                <InfiniteScroll
                                    dataLength={tasks.length}
                                    next={updateListFunction}
                                    hasMore={true}
                                    loader={<h4> </h4>}
                                    scrollableTarget={"ListOfTasks"}
                                        onScroll={() => Utils.hidePopoverOnScroll(ListName?.ListOfTasks)}
                                >
                                    {
                                        <ListGroup variant="flush" className="TicketsRow" >
                                            {tasks.map((task, index) => {

                                                var firstName = task.assigned_employee_name.split(' ')[0]

                                                return (
                                                    <ListGroup.Item style={{ color: '#707070' }} key={"keyAll" + index.toString()} >
                                                        <Row className='TicketsRowHeight'>
                                                            <Col md={1} className='d-flex  align-self-center' style={{ width: widthCols.requester }}>
                                                                <div className='oneLineClampEllipsis'>
                                                                    <OverlayTrigger
                                                                        key='top'
                                                                        placement='top'
                                                                        overlay={
                                                                            <Tooltip id='tooltip'>
                                                                                {task.requester_employee_name}
                                                                            </Tooltip>
                                                                        }
                                                                    >
                                                                        <span>
                                                                            {task.requester_employee_name}
                                                                        </span>
                                                                    </OverlayTrigger>
                                                                </div>
                                                            </Col>
                                                            <Col md={1} className='align-self-center nopadding' style={{ width: widthCols.assigned }}>
                                                                <div className='oneLineClampEllipsis'>
                                                                    <OverlayTrigger
                                                                        key='top'
                                                                        placement='top'
                                                                        overlay={
                                                                            <Tooltip id='tooltip'>
                                                                                {task.assigned_employee_name}
                                                                            </Tooltip>
                                                                        }
                                                                    >
                                                                        <span>
                                                                            {firstName}
                                                                        </span>
                                                                    </OverlayTrigger>
                                                                </div>
                                                            </Col>
                                                            <Col md={1} className='align-self-center nopadding' style={{ width: widthCols.date }}>
                                                                <OverlayTrigger
                                                                    key='top'
                                                                    placement='top'
                                                                    overlay={
                                                                        <Tooltip id='tooltip'>
                                                                            {new Date(task.date_limit)
                                                                                .toLocaleString('pt-BR', {
                                                                                    timeZone: 'UTC',
                                                                                    year: 'numeric',
                                                                                    month: '2-digit',
                                                                                    day: '2-digit',
                                                                                    hour: '2-digit',
                                                                                    minute: '2-digit'
                                                                                })
                                                                                .replace(',', ' - ')
                                                                            }
                                                                        </Tooltip>
                                                                    }
                                                                >
                                                                    <span>
                                                                        {new Date(task.date_limit)
                                                                            .toLocaleString('pt-BR', {
                                                                                timeZone: 'UTC',
                                                                                year: 'numeric',
                                                                                month: '2-digit',
                                                                                day: '2-digit',
                                                                                hour: '2-digit',
                                                                                minute: '2-digit'
                                                                            })
                                                                            .replace(',', ' - ')
                                                                        }
                                                                    </span>
                                                                </OverlayTrigger>
                                                            </Col>
                                                            <Col md={1} className='align-self-center nopadding pe-3' style={{ width: widthCols.description }} >
                                                                <div className='oneLineClampEllipsis'>
                                                                    <OverlayTrigger
                                                                        key='top'
                                                                        placement='top'
                                                                        overlay={
                                                                            <Tooltip id='tooltip'>
                                                                                {task.description}
                                                                            </Tooltip>
                                                                        }
                                                                    >
                                                                        <span>
                                                                            {task.description}
                                                                        </span>
                                                                    </OverlayTrigger>
                                                                </div>
                                                            </Col>
                                                            <Col md={1} className='align-self-center nopadding pe-3' style={{ width: widthCols.tags }} >
                                                                <div className='oneLineClampEllipsis'>
                                                                    <OverlayTrigger
                                                                        key='top'
                                                                        placement='top'
                                                                        overlay={
                                                                            <Tooltip id='tooltip'>
                                                                                {concatenateTags(task.tags)}
                                                                            </Tooltip>
                                                                        }
                                                                    >
                                                                        <span>
                                                                            {concatenateTags(task.tags)}
                                                                        </span>
                                                                    </OverlayTrigger>
                                                                </div>
                                                            </Col>
                                                            <Col md={1} className='align-self-center nopadding' style={{ width: widthCols.status }} >
                                                                <Col md={0}>
                                                                    {getStatusElement(task.status_task_id)}
                                                                </Col>
                                                            </Col>
                                                            <Col className='d-flex justify-content-end ps-0' style={{}}>
                                                                <OverlayTrigger rootClose={true} trigger={['click']} placement="left" overlay={popoverAction} >
                                                                    <Button id={task?.id} onClick={(e) => { setCurrentTaskInfo(e, task?.requester_employee_user_id, task?.ticket_id) }} className='nopadding d-flex justify-content-start align-self-center' style={{ marginTop: '', backgroundColor: 'transparent', border: 'none', width: "24px" }}>
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="90%" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#6A6A6A" fill="#6A6A6A" strokeLinecap="round" strokeLinejoin="round">
                                                                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                                            <circle cx="12" cy="12" r="1" />
                                                                            <circle cx="12" cy="19" r="1" />
                                                                            <circle cx="12" cy="5" r="1" />
                                                                        </svg>
                                                                    </Button>
                                                                </OverlayTrigger>
                                                            </Col>
                                                        </Row>
                                                    </ListGroup.Item>
                                                );
                                            })}
                                        </ListGroup>
                                    }
                                </InfiniteScroll>
                            </div>
                        </ListGroup>
                        :
                        <div className='align-self-center' style={{ overflow: 'hidden', height: '48vh', backgroundColor: '#F9F9F9' }}>
                            <Row className='d-flex justify-content-center align-content-center' style={{ marginTop: '17vh', backgroundColor: '#F9F9F9' }}>
                                <Col className='d-flex flex-column justify-content-center align-items-center'>
                                    <IconCheckupList stroke={1.6} strokeWidth={1.5} color="#4a69bd" size={'4vw'} />
                                    <p style={{ color: '#0C2461', font: 'normal normal bold calc(0.4em + 0.7vw) Montserrat' }}>{t("home_tasks.list_of_tasks.list_empty")}</p>
                                </Col>
                            </Row>
                        </div>
                }
            </div>
            }
        </>
    );
}

export default ListOfTasks;