import TemplateListNotification from "."
import { useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { FC, useContext, useEffect, useState } from "react"
import UserService from "../../../../services/user-service"
import { AppRequesterController } from "../../../../services/appRequester/appRequesterController"
import { getSoundNotification, getVisualNotification, setShowAlertFeedback, setShowConfirmationModal, setSoundNotification, setVisualNotification } from "../../../../store/internal"
import { Button, Popover } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import { IModalTemplateListNotificationsController } from "./indexModel"
import { SocketContext } from "../../../../core/context/socket-context"
import { TicketNotifyContext } from "../../../../core/context/ticket-notification-context"
import useTypeNotificationRequest from "../../../../hooks/useTypeNotificationRequest"
import useDaysForNotificationExpirationRequest from "../../../../hooks/useDaysForNotificationExpirationRequest"
import useNotificationSearchRequest from "../../../../hooks/useNotificationSearchRequest"
import useNotificationDeleteRequest from "../../../../hooks/useNotificationDeleteRequest"
import useNotificationRequest from "../../../../hooks/useReadNotificationRequest"


const TemplateListNotificationController: FC<IModalTemplateListNotificationsController> = (props) => {

    const dispatch = useDispatch()
    const { t } = useTranslation()

    const [isLoading, setIsLoading] = useState(false)
    const [config, setConfig] = useState(false)
    const [days, setDays] = useState("")
    const [daysExpiration, setDaysExpiration] = useState("")
    const [currentNotificationId, setCurrentNotificationId] = useState(null)
    const [currentNotificationMessage, setCurrentNotificationMessage] = useState(null)
    const [showFeedbackDay, setShowFeedbackDay] = useState(false)

    const [somTicket, setSomTicket] = useState(false)
    const [somMessage, setSomMessage] = useState(false)

    const [aux, setAux] = useState([])

    /**
     * API
     */
    const { refetchTimeExpiration } = useDaysForNotificationExpirationRequest(setDays)
    const { setCurrentFilterSearch, currentFilterSearch, notifications, refetchtGetAllTickets } = useNotificationSearchRequest()
    const { deleteNotificationRequest } = useNotificationDeleteRequest()
    const { readNotificationRequest } = useNotificationRequest()
    const { typeNotificationRequest } = useTypeNotificationRequest(somTicket, somMessage, props?.visualTicket, props?.visualMessage)

    const somNofication = useSelector(getSoundNotification)
    const visualNofication = useSelector(getVisualNotification)

    useEffect(() => {
        refetchtGetAllTickets();
    }, [currentFilterSearch]);

    useEffect(() => {
        refetchTimeExpiration()
    }, []);

    useEffect(() => {
        setShowFeedbackDay(false);
        if (somNofication === "2fc3c59e-edc5-4a00-92c9-f90c72de634a") {
            setSomTicket(true)
            setSomMessage(false)
        }
        else if (somNofication === "101ea4fc-2b7a-40f4-b67a-8d0c9cf13b42") {
            setSomMessage(true)
            setSomTicket(false)
        }

        if (visualNofication === "2fc3c59e-edc5-4a00-92c9-f90c72de634a") {
            props?.setVisualTicket(true)
            props?.setVisualMessage(false)
        }
        else if (visualNofication === "101ea4fc-2b7a-40f4-b67a-8d0c9cf13b42") {
            props?.setVisualMessage(true)
            props?.setVisualTicket(false)
        }

    }, [config]);

    const setCurrentNotificationMessageInfo = (e: { currentTarget: { id: string } }, item: any) => {
        // setCurrentNotificationId(e.currentTarget.id.split("*ID*")[1])
        // setCurrentNotificationMessage(e.currentTarget.id.split("*ID*")[0])
        setCurrentNotificationId(item.id);
        setCurrentNotificationMessage(item.message);
    }

    // Descomentar na próxima sprint, essa é a fução de redirecionamento de tela
    const showEditModal = (e: { currentTarget: { id: string } }, ticket_id: string) => {
        // props.setCurrentTicketFunction(e.currentTarget.id.split("*TICKET*")[1])
        // dispatch(setShowEditTicketsModal(true))
        // props.showPopover(false)
    }

    const deleteNotification = () => {
        dispatch(setShowConfirmationModal({ visibility: true, text: {"title": t('modal_template_list_notification.delete_notification'), "body": `${currentNotificationMessage}`, 'id': '', "buttonReturnText": t('modal_template_list_notification.cancel')}, functionConfirmation: () => deleteNotificationRequest(currentNotificationId)}))
    }

    const readNotificationUnique = () => {
        dispatch(setShowConfirmationModal({ visibility: true, text: { "title": t('modal_template_list_notification.mark_as_read_controller'), "body": `${currentNotificationMessage}`, 'id': '', "buttonReturnText": t('modal_template_list_notification.cancel'), "buttonConfirmationText": t('modal_template_list_notification.ok') }, functionConfirmation: () =>  readNotificationRequest(currentNotificationId)}))
    }

    const readAllNotification = () => {
        props.setShowPopover(false)
        dispatch(setShowConfirmationModal({ visibility: true, text: { "title": t('modal_template_list_notification.mark_all_as_read'), "body": t('modal_template_list_notification.are_you_sure'), 'id': '', 'buttonReturnText': t('modal_template_list_notification.cancel'), "buttonConfirmationText": t('modal_template_list_notification.ok')}, functionConfirmation: () => readNotificationRequest()}))
    }

    const popover = (read_at: any) => {
        return (
            <Popover style={{ backgroundColor: '#e6e6e6', border: 'none', color: '#40484E', font: 'var(--px15_19-font-Roboto)' }}>
                <Popover.Body className="nopadding">
                    {
                        read_at !== null ?
                            <></>
                        :
                            <>
                                <Button className="m-1" style={{ backgroundColor: 'transparent', border: 'none', color: 'black', fontSize: 'inherit' }} onClick={() => readNotificationUnique()}>{t('modal_template_list_notification.mark_as_read_controller')}</Button>
                                <div style={{ backgroundColor: '#707070', opacity: '0.5', height: '0.5px', width: '100%' }}></div>
                            </>
                    }
                    <Button className="m-1" style={{ backgroundColor: 'transparent', border: 'none', color: 'black', fontSize: 'inherit' }} onClick={() => deleteNotification()}>{t('modal_template_list_notification.delete')}</Button>
                </Popover.Body>
            </Popover>
        );
    }
    
    return (
        <TemplateListNotification
            notifications={notifications}
            readAllNotification={readAllNotification}
            popover={popover}
            setCurrentNotificationMessageInfo={setCurrentNotificationMessageInfo}
            userValues={props.userValues}
            showEditModal={showEditModal}
            isLoading={isLoading}
            setCurrentFilterSearch={setCurrentFilterSearch}
            currentFilterSearch={currentFilterSearch}
            setConfig={setConfig}
            config={config}
            setShowFeedbackDay={setShowFeedbackDay}
            showFeedbackDay={showFeedbackDay}
            days={days}
            setIsLoading={setIsLoading}
            daysExpiration={daysExpiration}
            setDaysExpiration={setDaysExpiration}
            getTimeExpiration={refetchTimeExpiration}
            t={t}
            somTicket={somTicket}
            setSomTicket={setSomTicket}
            setSomMessage={setSomMessage}
            somMessage={somMessage}
            visualTicket={props?.visualTicket}
            setVisualTicket={props?.setVisualTicket}
            setVisualMessage={props?.setVisualMessage}
            visualMessage={props?.visualMessage}
            typeNotificationRequest={typeNotificationRequest}
            showTemplateMessage={props?.showTemplateMessage}
        />
    );
}

export default TemplateListNotificationController;