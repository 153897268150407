import { ListGroup, Row, Col, OverlayTrigger, Tooltip, Button, Popover } from "react-bootstrap"
import allStyles from "../styles"
import { useEffect, useState } from "react"
import ModalEditWhatsapp from "../modalEditWhatsapp"
import { useDispatch, useSelector } from "react-redux"

import { useNavigate } from "react-router-dom"
import {  setShowAlertFeedback, setShowConfirmationModal, setShowModalActiveBase } from "../../../../../store/internal"
import { AppRequesterControlPanelController } from "../../../../../services/appRequesterControlPanel/appRequesterControlPanelController"
import UserService from "../../../../../services/user-service"
import ModalEditBroker from "../modalEditBroker"
import { getUserControlPanelPermissions } from "../../../../../store/user_controlpanel"
import constsApi from "../../../../../services/constsApi"
import { TokenInterface } from "../../../../../services/requestsInterfacesModel"
import { getToken } from "../../../../../store/token"
import ModalActiveBaseController from "../modalActiveBase/indexController"

const ListConfigWhatsapp = ({data, getWhatsAppById, isEditAllowed}) => {

    const values: TokenInterface = {
        token: {
            value: useSelector(getToken)
        },
    };

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const userPermissions: { id: string, created_at: string }[] = useSelector(getUserControlPanelPermissions);
    const AppRequesterConst = new AppRequesterControlPanelController()

    const [show, setShow] = useState(false);
    const [showBroker, setShowBroker] = useState(false);
    const [currentWhatsappId, setCurrentWhatsappId] = useState("")
    const [currentWhatsappPhone, setCurrentWhatsappPhone] = useState("")
    const [currentWhatsappData, setCurrentWhatsappData] = useState([])
    const [currentConfig, setCurrentConfig] = useState([])

    const [isLoading, setIsLoading] = useState(false)

    const [botId, setBotId] = useState(null);

    useEffect(() => {
        if (currentWhatsappId) {
            getWhatsAppKeysById(currentWhatsappId)
            // getBrokersById(currentWhatsappId)
        }
    }, [currentWhatsappId])

    const handleClose = () => setShow(false);
    const handleShow = () => {
        setShow(true)
        getWhatsAppKeysById(currentWhatsappId)
        // getBrokersById(currentWhatsappId)

    };

    const handleCloseBroker = () => setShowBroker(false);
    const handleShowBroker = () => {
        setShowBroker(true)
        getWhatsAppKeysById(currentWhatsappId)
        getBrokersById(currentWhatsappId)

    };

    const handleShowActiveBase = (id: string) => {
        dispatch(setShowModalActiveBase(true));
        setBotId(id);
    }

    const onCloseActiveBase = () => {
        setBotId(null);
    }

    const currentWhatsAppInfo = (e: { currentTarget: { id: string } }) => {
        setCurrentWhatsappId(e.currentTarget.id.split("*ID*")[0])
        setCurrentWhatsappPhone(e.currentTarget.id.split("*ID*")[1])
    }

    const getWhatsappEditById = (whatsapp_keys_is) => {
        handleShow()
        getWhatsAppKeysById(whatsapp_keys_is)
        getBrokersById(currentWhatsappId)

    }

    const deleteWhastappNumber = () => {
        dispatch(setShowConfirmationModal({ visibility: true, text: { "title": "Excluir número", "body": "Deseja excluir o número", "id": ' ' + currentWhatsappPhone + '?', "end": " Essa operação não poderá ser desfeita.", "buttonReturnText": "Cancelar" }, functionConfirmation: () => deleteWhatsAppKeys(currentWhatsappId) }))
    }

    const formatDate = (date: string, timeZone: string) => {
        const result = new Date(date).toLocaleDateString(timeZone)
        return result
    }

    const brokerTyper = (id: string) => {
        if (id === "ba3a545f-a77d-44bf-8344-ba05f54f5324") {
            return "Twilio"
        }
        else if (id === "c8ade4e2-993f-42dd-a108-430e9655a10f") {
            return "Positus"
        }
        else if (id === "e7fd7160-c9a3-435f-893d-a2d87ede6de5") {
            return "Meta"
        }
        else if (id === "695e98ef-d32c-472f-aa66-8bc26510eac5") {
            return "Smarters"
        }
        else {
            return "N/A"
        }
    }

    const deleteWhatsAppKeys = async (whatsapp_keys_is) => {
        const headers = {
            "Content-Type": "application/json; charset=utf-8",
            "Authorization": "Bearer " + values.token.value
        };

        await AppRequesterConst.Delete(
            `/channel-whatsapp/whatsapp-key/${whatsapp_keys_is}`, { headers },
            (response: Object) => { },
            (data: any) => {
                dispatch(setShowAlertFeedback({ message: "Whatsapp key excluída com sucesso.", visibility: true, signalIcon: true }))
                getWhatsAppById()
            },
            (error: { response: { status: number } }) => {

            }, navigate, dispatch, setIsLoading
        )
    }

    const getWhatsAppKeysById = async (whatsapp_keys_is) => {
        const headers = {
            "Content-Type": "application/json; charset=utf-8",
            "Authorization": "Bearer " + values.token.value
        };

        await AppRequesterConst.Get(
            `/channel-whatsapp/whatsapp-key/${whatsapp_keys_is}`, { headers },
            (response: Object) => { },
            (data: any) => {
                if (data.status == 200) {
                    setCurrentWhatsappData(data.data)
                }
            },
            (error: { response: { statue: number } }) => {

            }, navigate, dispatch, setIsLoading
        )
    }

    const getBrokersById = async (whatsapp_keys_is) => {
        const headers = {
            "Content-Type": "application/json; charset=utf-8",
            "Authorization": "Bearer " + values.token.value
        };


        await AppRequesterConst.Get(
            `channel-whatsapp/broker-config/${whatsapp_keys_is}`, { headers },
            (response: Object) => { },
            (data: any) => {
                if (data.status == 200) {
                    setCurrentConfig(data.data)
                }
            },
            (error: { response: { data: { message: string , code_cxpress: number }, status: number } }) => {
                if (error.response.status === 400) {
                    dispatch(setShowAlertFeedback({ message: error.response.data.message, visibility: true, signalIcon: false }))
                } else {
                    dispatch(setShowAlertFeedback({ message: 'Ocorreu um erro inesperado', visibility: true, signalIcon: false }))
                }
                setShowBroker(false)
            }, navigate, dispatch, setIsLoading
        )
    }

    const popover = (item) => {
        const permissions = userPermissions.map(item => item.id);
        return (
        <Popover style={{ zIndex: '500', backgroundColor: '#e6e6e6', border: 'none', color: '#40484E', font: 'var(--px15_19-font-Roboto)' }}>
            <Popover.Body className="nopadding">
                <Button className="m-1" style={{ backgroundColor: 'transparent', border: 'none', color: 'black', fontSize: 'inherit' }} onClick={() => handleShow()}>Editar número</Button>
                <div style={{ backgroundColor: '#707070', opacity: '0.5', height: '1px', width: '100%' }}></div>
                <Button className="m-1" style={{ backgroundColor: 'transparent', border: 'none', color: 'black', fontSize: 'inherit' }} onClick={() => deleteWhastappNumber()}>Excluir número</Button>
                    {(item.broken_id === "695e98ef-d32c-472f-aa66-8bc26510eac5" && permissions.includes(constsApi.permissions_controlpanel.master)) &&
                        <>
                            <div style={{ backgroundColor: '#707070', opacity: '0.5', height: '1px', width: '100%' }}></div>
                        <Button className="m-1" style={{ backgroundColor: 'transparent', border: 'none', color: 'black', fontSize: 'inherit' }} onClick={() => handleShowBroker()}>Configurações de Broker</Button>
                        </>
                    }
                {item.has_bot && item.bot_cxpress?
                    <>
                        <div style={{ backgroundColor: '#707070', opacity: '0.5', height: '1px', width: '100%' }}></div>
                        <Button className="m-1" style={{ backgroundColor: 'transparent', border: 'none', color: 'black', fontSize: 'inherit' }} onClick={() => handleShowActiveBase(item.bot_cxpress.id)}>Base de ativos</Button>
                    </>:
                    <></>
                }   
            </Popover.Body>
        </Popover>
    )
    }
    
    return (
        <>
            <ModalActiveBaseController botId={botId} onCloseActiveBase={onCloseActiveBase} />
            <ModalEditWhatsapp show={show} onHide={handleClose} currentWhatsappData={currentWhatsappData} getWhatsAppById={getWhatsAppById} />
            {showBroker && <ModalEditBroker show={showBroker} onHide={handleCloseBroker} currentData={currentConfig} currentWhatsappId={currentWhatsappId} />}
            <div className="ps-3">
                <ListGroup variant="flush">
                    <ListGroup.Item style={allStyles.textRowCollums}>
                        <Row name="ListOfAgentsHead">
                            <Col md={3} className="nopadding">
                                <p>Número</p>
                            </Col>
                            <Col md={2} className="nopadding">
                                <p>Possui bot?</p>
                            </Col>
                            <Col md={3} className="nopadding">
                                <p>Data de criação</p>
                            </Col>
                            <Col md={3} className="nopadding">
                                Broker
                            </Col>
                            <Col md={1} className="nopadding">
                                {isEditAllowed && <p>Ações</p>}
                            </Col>
                        </Row>
                    </ListGroup.Item>
                </ListGroup>
                {
                    data.length !== 0 ?

                        <div id="ListOfAgents" style={{ overflowY: 'auto', height: '45vh' }}>
                            {
                                <ListGroup variant="flush" className="TicketsRow">
                                    {
                                        data.map((item, index) => {

                                            const date = formatDate(item.created_at, 'pt-BR')

                                            return (
                                                <ListGroup.Item key={index} style={{ color: '#707070' }}>
                                                    <Row className="TicketsRowHeight">
                                                        <Col md={3} className="align-self-center nopadding" style={{ cursor: 'pointer' }}>
                                                            <div className="oneLineClampEllipsis me-3">
                                                                <OverlayTrigger
                                                                    key='top'
                                                                    placement="top"
                                                                    overlay={
                                                                        <Tooltip id="tooltip">
                                                                            {item?.phone_number != null ?
                                                                                <>{item?.phone_number}</>
                                                                                :
                                                                                <>N/A</>

                                                                            }
                                                                        </Tooltip>
                                                                    }
                                                                >
                                                                    <span>
                                                                        {item?.phone_number}
                                                                    </span>
                                                                </OverlayTrigger>
                                                            </div>
                                                        </Col>
                                                        <Col md={2} className="align-self-end nopadding" style={{ cursor: 'pointer' }}>
                                                            <div className="oneLineClampEllipsis me-3">
                                                                <OverlayTrigger
                                                                    key='top'
                                                                    placement="top"
                                                                    overlay={
                                                                        <Tooltip id="tooltip">
                                                                            {item?.has_bot == true ?
                                                                                <>SIM</>
                                                                                :
                                                                                <>NÃO</>
                                                                            }
                                                                        </Tooltip>
                                                                    }
                                                                >
                                                                    <span>
                                                                        {item?.has_bot == true ? <>SIM</> : <>NÃO</>}
                                                                    </span>
                                                                </OverlayTrigger>
                                                            </div>
                                                        </Col>
                                                        <Col md={3} className="d-flex justify-content-start align-self-center nopadding" style={{ cursor: 'pointer' }}>
                                                            <div className="oneLineClampEllipsis me-3">
                                                                <OverlayTrigger
                                                                    key='top'
                                                                    placement="top"
                                                                    overlay={
                                                                        <Tooltip id="tooltip">
                                                                            {date}
                                                                        </Tooltip>
                                                                    }
                                                                >
                                                                    <span>
                                                                        {date}
                                                                    </span>
                                                                </OverlayTrigger>
                                                            </div>
                                                        </Col>
                                                        <Col md={3} className="d-flex justify-content-start align-self-center nopadding" style={{ cursor: 'pointer' }}>
                                                            <div className="oneLineClampEllipsis me-3">
                                                                <OverlayTrigger
                                                                    key='top'
                                                                    placement="top"
                                                                    overlay={
                                                                        <Tooltip id="tooltip">
                                                                            {brokerTyper(item.broker_id)}
                                                                        </Tooltip>
                                                                    }
                                                                >
                                                                    <span>
                                                                        {brokerTyper(item.broker_id)}
                                                                    </span>
                                                                </OverlayTrigger>
                                                            </div>
                                                        </Col>
                                                        <Col md={1} className="d-flex justify-content-center align-self-center nopadding" style={{ cursor: 'pointer' }}>
                                                            {
                                                                isEditAllowed &&
                                                                <OverlayTrigger rootClose={true} trigger={['click']} placement="left" overlay={popover(item)}>
                                                                    <Button id={item.id + "*ID*" + item.phone_number + "*ID*"} className='nopadding d-flex justify-content-start align-self-center' style={{ marginTop: '', backgroundColor: 'transparent', border: 'none', width: "24px" }} onClick={(e) => currentWhatsAppInfo(e)}>
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="90%" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#6A6A6A" fill="#6A6A6A" strokeLinecap="round" strokeLinejoin="round">
                                                                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                                            <circle cx="12" cy="12" r="1" />
                                                                            <circle cx="12" cy="19" r="1" />
                                                                            <circle cx="12" cy="5" r="1" />
                                                                        </svg>
                                                                    </Button>
                                                                </OverlayTrigger>
                                                            }
                                                        </Col>
                                                    </Row>
                                                </ListGroup.Item>
                                            )
                                        })
                                    }
                                </ListGroup>
                            }
                        </div>
                    :
                    <></>
                }
            </div>
        </>
    )
}

export default ListConfigWhatsapp