import React, { FC, useEffect, useState } from 'react';
import { Button, Popover } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { getIdUser, getNameUser, getProfileId } from '../../../../../../store/user';

import { setShowAlertFeedback, setShowConfirmationModal, setShowEditTriageModal } from '../../../../../../store/internal';
import { useNavigate } from 'react-router-dom';
import { AppRequesterController } from '../../../../../../services/appRequester/appRequesterController';
import { getToken } from '../../../../../../store/token';

import { ListOfTriageInterface } from './indexModel';
import { TokenInterface } from '../../../../../../services/requestsInterfacesModel';
import ListOfDepartments from './index';
import UserService from '../../../../../../services/user-service';
import { useTranslation } from 'react-i18next';
import Utils from '../../../../../../core/shared/utils';

const ListOfTriageController: FC<ListOfTriageInterface> = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const AppRequesterConst = new AppRequesterController();

  const [currentPage, setCurrentPage] = useState(1);
  const [aux, setAux] = useState([]);

  const [currentTriageId, setCurrentTriageId] = useState('');
  const [currentTriage, setCurrentTriage] = useState({});
  const [/*isLoading*/, setIsLoading] = useState(false)
  const [triageList, setTriageList] = useState([]);

  const { t } = useTranslation()

  const values: TokenInterface = {
    user: {
      id: useSelector(getIdUser),
      name: useSelector(getNameUser),
      profile_id: useSelector(getProfileId)
    },
    token: {
      value: useSelector(getToken)
    },
  }
  
  useEffect(() => {
    Utils.changeActiveScrollBar("ListOfDepartments");
  }, [triageList]);

  useEffect(() => {
    getTriage()
  }, [])

  useEffect(() => {
    if (props.refreshTriage === true) {
      getTriage()
      props.setRefreshTriage(false)
    }
  }, [props.refreshTriage])

  const setCurrentDepartmentInfo = (e: { currentTarget: { id: string; }; }) => {
    //setCurrentDepartmentId(e.currentTarget.id);
    //setCurrentListedAgentIsOwner(e.currentTarget.id.split("*ID*")[2]);
    return handleSetCurrentAgentData(e.currentTarget.id.split("*ID*")[1]);
  }

  const handleSetCurrentAgentData = (selectedAgentId: string) => {
    // let dataAgent = props.datas.filter((item: { sector_id: string; }) => item.sector_id === selectedAgentId)[0];
    // setCurrentTriage(dataAgent);
  }

  const getTriage = async () => {

    const headers = UserService.getHeaders();

    await AppRequesterConst.Get(
      '/triage', { headers },
      (response: Object) => {
        return response;
      },
      (data) => {
        if (data.status === 200) {
          setTriageList(data.data.triage)
          // setOptionsChatbots(data.data.bot_cxpress.length > 0 ? data.data.bot_cxpress.map((item) => ({ label: item.bot_name, value: item.id })) : []);
        }
      },
      (error: Object) => {
      }, navigate, dispatch, setIsLoading, { values: values }
    );
  }

  const getTriageID = async (id: string) => {

    const headers = UserService.getHeaders();

    await AppRequesterConst.Get(
      "/triage/" + id,
      { headers: headers, params: {} },
      (response: Object) => { },
      (data) => {
        if (data.status === 200) {
          setCurrentTriage(data.data.triage[0]);
          dispatch(setShowEditTriageModal(true));
        }
      },
      (error: Object) => { },
      navigate, dispatch, setIsLoading, { values: values }
    )
  }

  const showEditTriageModal = (id = null) => {
    if (id) {
      getTriageID(id);
    } else {
      getTriageID(currentTriageId);
    }

  }

  const setCurrentTriageInfo = (e: { currentTarget: { id: string }; }) => {
    setCurrentTriageId(e.currentTarget.id);
  }

  const callbackModalTriage = () => {
    setCurrentPage(1);
    setAux([]);
    getTriage();
  }

  const deleteTriage = async (id: string) => {
    const headers = UserService.getHeaders()

    await AppRequesterConst.Delete(
      '/triage/' + id, { headers: headers },
      (response: Object) => { },
      (data: Object) => {
        dispatch(setShowAlertFeedback({ message: t('home_departments.triage_tab.delete_success'), visibility: true, signalIcon: true }));
        setTriageList([])
        callbackModalTriage();
      },
      (error: Object) => {
        dispatch(setShowAlertFeedback({ message: t('home_departments.triage_tab.unexpected_error'), visibility: true, signalIcon: false }));
      },
      navigate,
      dispatch,
      setIsLoading,
    );
  }

  const deleteBotConfirm = (e) => {
    dispatch(setShowConfirmationModal({
      visibility: true, text: { "title": t('home_departments.triage_tab.delete_triage'), "body": t('home_departments.triage_tab.delete_question'), "buttonConfirmationText": t('home_departments.triage_tab.delete'), "buttonReturnText": t('home_departments.triage_tab.cancel') },
      functionConfirmation: () => {
        deleteTriage(e.target.parentElement.parentElement.id)
      }
    }));
  }

  const popoverActions = (
    <Popover id={currentTriageId} style={{ zIndex: '500', backgroundColor: '#e6e6e6', border: 'none', color: '#40484E', font: 'var(--px15_19-font-Roboto)' }}>
      <Popover.Body className="nopadding">
        <Button onClick={() => showEditTriageModal()} className="m-1" style={{ backgroundColor: 'transparent', border: 'none', color: 'black', fontSize: 'inherit' }}>{t('home_departments.triage_tab.edit_triage')}</Button>
        <div style={{ backgroundColor: '#707070', opacity: '0.5', height: '1px', width: '100%' }}></div>
        <Button onClick={deleteBotConfirm} className="m-1" style={{ backgroundColor: 'transparent', border: 'none', color: 'black', fontSize: 'inherit' }}>{t('home_departments.triage_tab.delete_triage')}</Button>
      </Popover.Body>
    </Popover>
  );

  return (
    <ListOfDepartments
      popoverActions={popoverActions}
      props={props}
      triageList={triageList}
      callbackModalTriage={callbackModalTriage}
      setCurrentTriageInfo={setCurrentTriageInfo}
      currentTriage={currentTriage}
      t={t}
      showEditTriageModal={showEditTriageModal}
    />
  );
}

export default ListOfTriageController;