import { OrderBy } from "../../../../core/enums/order-sort-by";

export class ButtonOrderByListController {
    private dispatch: Function
    private setSortByList: Function
    private props
    private orderBy: OrderBy
    private setOrderBy: Function
    private getSortByList

    constructor(dispatch: Function, setSortByList: Function, props, orderBy: OrderBy, setOrderBy: Function, getSortByList) {
        this.dispatch = dispatch
        this.setSortByList = setSortByList
        this.props = props
        this.orderBy = orderBy
        this.setOrderBy = setOrderBy
        this.getSortByList = getSortByList
    }

    orderByFunction(sort_by = this.props.columnIdName, listChanged?: boolean) {
        if(listChanged && listChanged === true && this.getSortByList['sort_by'] !== sort_by) {
            this.dispatch(this.setSortByList({ sort_by, listName: this.props.listName }));
            this.props.callbackOrderBy(sort_by, this.orderBy);
        } else {
            this.dispatch(this.setSortByList({ sort_by, listName: this.props.listName }));
            if (this.orderBy === OrderBy.asc) {
                this.setOrderBy(OrderBy.desc);
                this.props.callbackOrderBy(sort_by, OrderBy.desc); 
            } else {
                this.setOrderBy(OrderBy.asc);
                this.props.callbackOrderBy(sort_by, OrderBy.asc);
            }
        }
    }
}