import { useSelector } from 'react-redux'
import { Container, Row, Col, Button, OverlayTrigger, ListGroup, Tooltip } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import {
    getShowEditConsumersModal,
    getShowCallNotificationBar,
    getShowAcquirePlatformComponent,
    getShowModalConsumersSimilarData,
} from '../../../../store/internal'
import Loading from '../../../../components/loading/index'
import ModalEditConsumersController from '../../components/modalEditConsumers/indexController';
import CallNotificationBar from '../../components/callNotificationBar';
import ButtonSearchCleanInput from '../../components/buttonSearchCleanInputComponent';
import AcquirePlatformNotificationBar from '../../components/acquirePlatformNotificationBar';
import ButtonListNotificationController from '../../../../components/buttonListNotification/indexController';
import ModalConsumersSimilarDataController from '../unificationConsumers/modalConsumersSimilarData/indexController';
import ButtonStatusAgent from '../../components/buttonStatusAgent';
import FilterOptionComponentController from '../../components/filterOptionComponent/indexController';
import ViewTicketController from '../../homeTickets/viewTicket/indexController';
import ListOfTicketsController from '../../homeTickets/listOfTickets/indexController';

const ViewConsumers = ({
    values,
    currentConsumer,
    setCurrentConsumerForSimilarData,
    setSimilarConsumers,
    currentConsumerForSimilarData,
    similarConsumers,
    callbackModalCustomers,
    callbackOrderByTickets,
    currentTicketsConsumers,
    currentTicket,
    currentParams,
    setCurrentFilterSearch,
    currentFilterSearch,
    currentState,
    setCurrentState,
    yoursTickets,
    setYoursTickets,
    filteringTickets,
    cleanList,
    getAllTickets,
    updateListAfterDelete,
    updateListfunction,
    showCreateTicket,
    listOfConsumersPage,
    showEditModal,
    resetingFilters,
    setResetingFilters,
    isLoading,
    phoneNumber,
    openModalPhone,
    setOpenModalPhone,
    setPhoneNumber,
    setCurrentTicketFunction,
    t,
    employeesData,
    createdByEmployee,
}) => {

    return (
        <>
            <Container fluid className="nopadding" style={{overflow: 'hidden'}}>
                <Loading visibility={isLoading}></Loading>
                <ModalEditConsumersController employeesData={employeesData} token={values.token.value} visibility={useSelector(getShowEditConsumersModal)} callback={callbackModalCustomers} data={currentConsumer} setCurrentConsumer={setCurrentConsumerForSimilarData} setSimilarConsumers={setSimilarConsumers} />
                <ModalConsumersSimilarDataController originRedirectPage='view_consumer_page' visibility={useSelector(getShowModalConsumersSimilarData)} similarConsumers={similarConsumers} currentConsumer={currentConsumerForSimilarData} />
                <div className="wrapper">
                    {/* <SidebarController currentPage={SidebarButtonPage.Consumers}></SidebarController> */}
                    <div className="content py-0 ps-0" >
                        <CallNotificationBar visibility={useSelector(getShowCallNotificationBar)} setCurrentTicketFunction={(value) => setCurrentTicketFunction(value)} updateListfunction={updateListfunction}></CallNotificationBar>
                        <AcquirePlatformNotificationBar visibility={useSelector(getShowAcquirePlatformComponent).visibility} limitDays={useSelector(getShowAcquirePlatformComponent).limitDays} />
                        {
                            values.internal.getShowEditTicketsModal === true ?
                                <>
                                    <Row className="nopadding mt-3 ps-3">
                                        <Col md={5} style={{ marginRight: '-8vw' }}>
                                            <Row style={{ marginBottom: '1.5vh' }}>
                                                <Col className="d-flex justify-content-start align-content-start">
                                                    <div style={{ font: 'var(--px20_24_Bold-font)' }}>{t('home_consumers.view_consumers.welcome_main')}</div>
                                                </Col>
                                            </Row>
                                            <Row style={{ marginBottom: '1vh' }}>
                                                <Col className="d-flex" style={{ marginTop: "0vh", marginLeft: '0.5vw' }} >
                                                    <div className="nopadding" style={{ font: 'var(--px16_19-font)', color: '#1E1E1E', textDecoration: 'underline', cursor: 'pointer' }} onClick={listOfConsumersPage}>
                                                        <span>
                                                            <svg id="Grupo_11161" data-name="Grupo 11161" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 2 24 24">
                                                                <path id="Caminho_9641" data-name="Caminho 9641" d="M0,0H24V24H0Z" fill="none" />
                                                                <path id="Caminho_9642" data-name="Caminho 9642" d="M9,13,5,9,9,5M5,9H16a4,4,0,0,1,0,8H15" fill="none" stroke="#1e1e1e" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                                            </svg>
                                                        </span>
                                                        {t('home_consumers.view_consumers.back_to_list')}
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col className="d-flex justify-content-end" >
                                            <ButtonSearchCleanInput inputPlaceholder={t('home_consumers.view_consumers.input_placeholder')} filteringTickets={(e, auxObj, action) => filteringTickets(e, auxObj, action)} setCurrentFilterSearch={setCurrentFilterSearch} currentFilterSearch={currentFilterSearch} ></ButtonSearchCleanInput>
                                            <ButtonListNotificationController/>
                                            <ButtonStatusAgent/>
                                        </Col>
                                        <Row style={{ margin: '1vh 0vh 0vh 0vh' }}>
                                            <p style={{ color: '#0C2461', font: 'normal normal bold calc(0.2em + 0.8vw) Montserrat' }}>
                                                {currentConsumer != null && currentConsumer.name}
                                                {createdByEmployee === "false" &&
                                                <OverlayTrigger
                                                    key='top'
                                                    placement='top'
                                                    overlay={
                                                        <Tooltip id='tooltip' className='tooltip-arrow-Top'>
                                                            {t('home_consumers.view_consumers.consumer_data')}
                                                        </Tooltip>
                                                    }
                                                >
                                                    <Button className="mx-3 nopadding buttonTicketsSec" onClick={showEditModal}>
                                                        <svg id="Grupo_11248" data-name="Grupo 11248" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                                            <path id="Caminho_9655" data-name="Caminho 9655" d="M0,0H24V24H0Z" fill="none" />
                                                            <path id="Caminho_9656" data-name="Caminho 9656" d="M9,7H6A2,2,0,0,0,4,9v9a2,2,0,0,0,2,2h9a2,2,0,0,0,2-2V15" fill="none" stroke="#0c2461" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                                            <path id="Caminho_9657" data-name="Caminho 9657" d="M9,15h3l8.5-8.5a2.121,2.121,0,0,0-3-3L9,12v3" fill="none" stroke="#0c2461" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                                            <line id="Linha_183" data-name="Linha 183" x2="3" y2="3" transform="translate(16 5)" fill="none" stroke="#0c2461" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                                        </svg>
                                                    </Button>
                                                </OverlayTrigger>
                                                }
                                            </p>
                                        </Row>
                                    </Row>
                                    <ViewTicketController setOpenModalPhone={(value: any) => { setOpenModalPhone(value) }} setPhoneNumber={(value: string) => { setPhoneNumber(value) }} currentTicket={currentTicket} filters={currentParams} getList={getAllTickets} currentViewFilter={'all'} setCurrentParams={() => {}}></ViewTicketController>
                                </>
                                :
                                <>
                                    <Row className="nopadding ps-3 mt-3">
                                        <Col md={7} style={{ marginRight: '-8vw' }}>
                                            <Row>
                                                <Col className="d-flex justify-content-start align-self-start">
                                                    <div style={{ font: 'var(--px20_24_Bold-font)' }}>{t('home_consumers.view_consumers.welcome_main')}</div>
                                                </Col>
                                            </Row>
                                            <Row className='mt-2'>
                                                <div style={{ font: 'var(--px15_19-font)', color: '#707070' }}>{t('home_consumers.view_consumers.welcome_sub')}</div>
                                            </Row>
                                        </Col>
                                        <Col className="d-flex justify-content-end" >
                                            <ButtonSearchCleanInput inputPlaceholder={t('home_agents.view_agents.search_ticket')} filteringTickets={(e, auxObj, action) => filteringTickets(e, auxObj, action)} setCurrentFilterSearch={setCurrentFilterSearch} currentFilterSearch={currentFilterSearch} ></ButtonSearchCleanInput>
                                            <ButtonListNotificationController/>
                                            <ButtonStatusAgent/>
                                        </Col>
                                    </Row>
                                    <Row className="ps-4" style={{ marginTop: '1.5vh' }}>
                                        <Col className="d-flex" style={{ marginTop: "0vh", marginLeft: '0.5vw' }} >
                                            <p style={{ font: 'var(--px16_19-font)', color: '#1E1E1E', textDecoration: 'underline', cursor: 'pointer' }} onClick={listOfConsumersPage}>
                                                <span>
                                                    <svg id="Grupo_11161" data-name="Grupo 11161" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 2 24 24">
                                                        <path id="Caminho_9641" data-name="Caminho 9641" d="M0,0H24V24H0Z" fill="none" />
                                                        <path id="Caminho_9642" data-name="Caminho 9642" d="M9,13,5,9,9,5M5,9H16a4,4,0,0,1,0,8H15" fill="none" stroke="#1e1e1e" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                                    </svg>
                                                </span>
                                                {t('home_consumers.view_consumers.back_to_list')}
                                            </p>
                                        </Col>
                                    </Row>
                                    <Row className="ps-3" id="buttonEditConsumer" style={{ margin: '1vh 0vw 0px 0vw' }}>
                                        <Col md style={{ marginLeft: '1vw' }}>
                                            <p style={{ color: '#0C2461', font: 'normal normal bold calc(0.2em + 0.8vw) Montserrat' }}>
                                                {currentConsumer != null && currentConsumer.name}
                                                {createdByEmployee === "false" &&
                                                <OverlayTrigger
                                                    key='top'
                                                    placement='top'
                                                    overlay={
                                                        <Tooltip id='tooltip' className='tooltip-arrow-Top'>
                                                            {t('home_consumers.view_consumers.consumer_data')}
                                                        </Tooltip>
                                                    }
                                                >
                                                    <Button className="mx-3 nopadding buttonTicketsSec" onClick={showEditModal}>
                                                        <svg id="Grupo_11248" data-name="Grupo 11248" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                                            <path id="Caminho_9655" data-name="Caminho 9655" d="M0,0H24V24H0Z" fill="none" />
                                                            <path id="Caminho_9656" data-name="Caminho 9656" d="M9,7H6A2,2,0,0,0,4,9v9a2,2,0,0,0,2,2h9a2,2,0,0,0,2-2V15" fill="none" stroke="#0c2461" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                                            <path id="Caminho_9657" data-name="Caminho 9657" d="M9,15h3l8.5-8.5a2.121,2.121,0,0,0-3-3L9,12v3" fill="none" stroke="#0c2461" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                                            <line id="Linha_183" data-name="Linha 183" x2="3" y2="3" transform="translate(16 5)" fill="none" stroke="#0c2461" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                                        </svg>
                                                    </Button>

                                                </OverlayTrigger>
                                                }
                                            </p>

                                        </Col>
                                    </Row>
                                    <div className='FilterTicketsRow ps-3' style={{ margin: '2vh 0.5vw 0px', padding: '0' }}>
                                        <ListGroup variant="flush" >
                                            <ListGroup.Item style={{ backgroundColor: 'rgba(207, 227, 255,0.25)', padding: '0.6%' }}>
                                                <FilterOptionComponentController
                                                    optionNames={['Canal', 'Automação', 'Atribuido', 'Status', 'Setor', 'Data']}
                                                    currentState={currentState}
                                                    setCurrentState={setCurrentState}
                                                    values_user={values.user}
                                                    values_internal_getShowEditTicketsModal={values.internal.getShowEditTicketsModal}
                                                    values_internal_getShowCreateTicketsModal={false}
                                                    filteringTickets={(e, auxObj, action) => filteringTickets(e, auxObj, action)}
                                                    cleanList={cleanList}
                                                    values_token_value={values.token.value}
                                                    values_company_id={values.company.id}
                                                    resetingFilters={resetingFilters}
                                                    setResetingFilters={setResetingFilters}
                                                    yoursTickets={yoursTickets}
                                                    setYoursTickets={setYoursTickets}
                                                    screen={'view_consumers'}
                                                />
                                            </ListGroup.Item>
                                        </ListGroup>
                                    </div>
                                    <ListOfTicketsController datas={currentTicketsConsumers} updateListAfterDelete={() => updateListAfterDelete()} updateListfunction={() => updateListfunction()} showCreateTicket={() => showCreateTicket()} setCurrentTicketFunction={(value) => setCurrentTicketFunction(value)} assignedEmployeeActiveColumn={true} requesterUserActiveColumn={false} actionsActiveColumn={true} checkboxes={true} callbackOrderByTickets={(column, order) => callbackOrderByTickets(column, order)}></ListOfTicketsController>
                                </>
                        }
                    </div>
                </div>
            </Container >
        </>
    );
}

export default ViewConsumers;