import {
    Row,
    Col,
    OverlayTrigger,
    Tooltip,
    Button,
    Form,
    InputGroup,
    FormControl,
} from "react-bootstrap"
import allStyles from "./styles"
import { AppRequesterController } from '../../../../services/appRequester/appRequesterController'
import { Formik } from "formik"
import { setShowAlertFeedback } from "../../../../store/internal"
import ModalWithInput from "../modalWhitInput"
import { verifyCode } from "../../../../services/codeCxpressInterface"


const AppRequesterConst = new AppRequesterController()

const FormConfigCompany = ({
    showModal,
    handleCloseModal,
    // handleCadastroPagina,
    resendEmail,
    tokenGPT,
    showfeedbackToken,
    setShowFeedbackToken,
    setToken,
    token,
    navigate,
    dispatch,
    setIsLoading,
    id,
    acessToken,
    name,
    getCompany,
    activeCompany,
    handleClose,
    handleShow,
    show,
    linkAtivation,
    copyTextToClipboard,
    buttonCopyUrl,
    t,
    isActiveCompany,
    showfeedbackTokenTranscription,
    setShowFeedbackTokenTranscription,
    validationToken,
    setValidationToken,
    token_transcription,
    setTokenTranscription,
    tokenTranscription
}) => {

    return (
        <>  
            <ModalWithInput title='Link de ativação' show={show} onHide={handleClose} information={linkAtivation} copyTextToClipboard={copyTextToClipboard} buttonCopyUrl={buttonCopyUrl}/>
            <Row className="mt-1 noppadding" style={{ display: "flex", alignItems: "center" }}>
                <Col style={{
                    textAlign: 'left',
                    font: 'normal normal bold calc(0.2em + 1vw) Montserrat',
                    letterSpacing: '0px',
                    color: '#0C2461',
                    opacity: '1',
                    display: 'flex',
                    alignItems: 'center',
                }}
                    md={5}
                    className="mt-2"
                >
                    <div style={{ paddingRight: "20px", color: "black" }}>
                        Configuração da empresa
                    </div>
                </Col>
            </Row>
            <div style={{ backgroundColor: '#707070', opacity: '0.5', height: '0.1vh', width: '100%', marginTop: '2px' }}></div>
            <Row style={allStyles.textFontGlay} className="d-flex justify-content-flex-start mt-2" >
                <Col sm={true} md={4}>
                    <p style={{ color: '#0C2461', font: 'normal normal bold min(max(10px, calc(0.2em + 0.7vw)), 19px) Montserrat' }}>
                        <OverlayTrigger
                            key='top'
                            placement='top'
                            overlay={
                                <Tooltip id='tooltip' className='tooltip-arrow-Top'>
                                    { isActiveCompany ? 'Empresa já ativada' : 'Reenviar e-mail de ativação' }
                                </Tooltip>
                            }
                        >
                            {
                                isActiveCompany ?
                                <Button size="sm" disabled className="buttonWhite" style={{ marginTop: '1.5vh' }}>Empresa já ativada</Button>
                                :
                                <Button size="lg" className="buttonWhite" style={{ width: '100%', marginTop: '1.5vh' }} onClick={resendEmail}>Reenviar e-mail de ativação</Button>
                            }
                        </OverlayTrigger>
                    </p>
                </Col>
                {/* * */}
                {/* * */}
                {/* * */}
                {/* Não apagar este código comentado, ainda será utilizado para geração de link de ativação de empresas*/}
                {/* * */}
                {/* * */}
                {/* * */}
                {/* <Col sm={true} md={3}>
                    <p style={{ color: '#0C2461', font: 'normal normal bold min(max(10px, calc(0.2em + 0.7vw)), 19px) Montserrat' }}>
                        {
                            <OverlayTrigger
                                key='top'
                                placement='top'
                                overlay={
                                    <Tooltip id='tooltip' className='tooltip-arrow-Top'>
                                        Gerar link de ativação
                                    </Tooltip>
                                }
                            >
                                <Button size="lg" className="buttonWhite" style={{ width: '100%', marginTop: '1.5vh' }} onClick={activeCompany}>Gerar link de ativação</Button>
                            </OverlayTrigger>
                        }
                    </p>
                </Col> */}
            </Row>
            <Row className="mt-1 noppadding" style={{ display: "flex", alignItems: "center" }}>
                <Col style={{
                    textAlign: 'left',
                    font: 'normal normal bold calc(0.2em + 1vw) Montserrat',
                    letterSpacing: '0px',
                    color: '#0C2461',
                    opacity: '1',
                    display: 'flex',
                    alignItems: 'center',
                }}
                    md={5}
                    className="mt-2"
                >
                    <div style={{ paddingRight: "20px", color: "black" }}>
                        Tokens
                    </div>
                </Col>
            </Row>
            <div style={{ backgroundColor: '#707070', opacity: '0.5', height: '0.1vh', width: '100%', marginTop: '2px' }}></div>
            <Formik
                enableReinitialize={true}
                initialValues={{ token: tokenGPT, token_transcription: token_transcription}}
                validate={values => {
                    let errors = {};
                    setToken(values.token);
                    setTokenTranscription(values.token_transcription)

                    // if (!values.token) {
                    //     errors["token"] = 'Preencha este campo';
                    //     setShowFeedbackToken(true);
                    // } else if (values.token.length < 51) {
                    //     errors["token"] = 'Tamanho do token menor que 51 caracteres';
                    //     setShowFeedbackToken(true)
                    // } else if (values.token.split('-')[0] + "-" !== "sk-") {
                    //     errors["token"] = `O token deve iniciar com "${'sk-'}"`;
                    //     setShowFeedbackToken(true)
                    // } else if (!/^[a-zA-Z0-9-_]+$/i.test(values.token)) {
                    //     errors['token'] = 'O token não deve possuir caracteres especiais' 
                    //     setShowFeedbackToken(true)
                    // } else {
                    //     setShowFeedbackToken(false)
                    // }

                    // if (values.token_transcription) {
                    //     if (values.token_transcription.length < 51) {
                    //         errors["token_transcription"] = 'Tamanho do token menor que 51 caracteres';
                    //         setShowFeedbackTokenTranscription(true)
                    //     } else if (values.token_transcription.split('-')[0] + "-" !== "sk-") {
                    //         errors["token_transcription"] = `O token deve inicial com "${'sk-'}"`;
                    //         setShowFeedbackTokenTranscription(true)
                    //     } else if (!/^[a-zA-Z0-9-_]+$/i.test(values.token)) {
                    //         errors['token'] = 'O token não deve possuir caracteres especiais'
                    //         setShowFeedbackTokenTranscription(true)
                    //     } else {
                    //         setShowFeedbackTokenTranscription(false)
                    //     }

                    // } else {
                    //     setShowFeedbackTokenTranscription(false)
                    // }


                    // return errors;
                }}
                onSubmit={() => {
                    const JsonSend = {
                        "id": id,
                        "token_chatgpt": token,
                        "token_transcription": tokenTranscription ? tokenTranscription : undefined
                    };

                    const headers = {
                        "Content-Type": "application/json; charset=utf-8",
                        "Authorization": "Bearer " + acessToken
                    };

                    AppRequesterConst.Put(
                        `/panel/company`,
                        JsonSend,
                        { headers: headers },
                        (response: Object) => {
                            return response;
                        },
                        (data) => {
                            setShowFeedbackToken(false);
                            dispatch(setShowAlertFeedback({ message: `Token atualizado com sucesso!`, visibility: true, signalIcon: true }))
                            getCompany()
                        },
                        (error: { response: { status: number; data: { message: any []; code_cxpress: number } } }) => {
                            if (error.response.status == 400) {
                                dispatch(setShowAlertFeedback({ message: verifyCode(error.response.data.code_cxpress, t), visibility: true, signalIcon: false }))
                            } else if (error.response.status === 500 && error.response.data.code_cxpress === 1201) {
                                dispatch(setShowAlertFeedback({ message: error.response.data.message, visibility: true, signalIcon: false }))
                            } else {
                                dispatch(setShowAlertFeedback({ message: verifyCode(error.response.data.code_cxpress, t), visibility: true, signalIcon: false }))
                            }
                        },
                        navigate,
                        dispatch,
                        setIsLoading
                    );
                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                }) => (
                    <Form onSubmit={handleSubmit} noValidate validated={false}>
                    <Col md={12}>
                        <Form.Label htmlFor="name" style={{ marginTop: '1.5rem', color: '#707070' }}>
                            Token do ChatGPT
                        </Form.Label>
                        <InputGroup>
                            <FormControl
                                className="form-control-Default"
                                isInvalid={showfeedbackToken}
                                placeholder="Informe o token do chat GPT"
                                aria-label="Informe o token do chat GPT"
                                aria-describedby="Informe o token do chat GPT"
                                size="lg"
                                type="token"
                                id="token"
                                name="token"
                                // maxLength={51}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.token}
                            ></FormControl>
                            <Form.Control.Feedback type="invalid" id="feedbacktoken">
                                {errors['token']}
                            </Form.Control.Feedback>
                        </InputGroup>
                    </Col>
                    <Col md={12}>
                        <Form.Label htmlFor="name" style={{ marginTop: '1.5rem', color: "#707070" }}>
                            Token da Transcrição
                        </Form.Label>
                        <InputGroup>
                            <FormControl
                                className="form-control-Default"
                                isInvalid={showfeedbackTokenTranscription}
                                placeholder="Informe o token de transcrição"
                                aria-label="Informe o token de transcrição"
                                aria-describedby="Informe o token de transcrição"
                                size="lg"
                                type="token"
                                id="token_transcription"
                                name="token_transcription"
                                // maxLength={51}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.token_transcription}
                            ></FormControl>
                            <Form.Control.Feedback type="invalid" id="feedbackTokenTranscription">
                                {errors['token_transcription']}
                            </Form.Control.Feedback>
                        </InputGroup>
                    </Col>
                        <Row className="d-flex justify-content-start mt-4">
                            <Col xs={3} className="d-flex justify-content-start">
                                <Button size='lg' type='submit' className="buttonBlue" style={{ width: '56%' }}>Salvar</Button>
                            </Col>
                        </Row>
                    </Form>
                )}
            </Formik>
        </>
    )
}
export default FormConfigCompany