import { useNavigate } from 'react-router-dom';
import LoginControlPanel from './index';
import { useDispatch, useSelector } from 'react-redux';
import { AppRequesterController } from '../../../services/appRequester/appRequesterController';
import { DataLoginControlPanelInterface, RequestDataLoginControlPanelInterface } from '../../../services/requestsInterfacesModel';
import { setToken } from "../../../store/control_panel";
import constsRouters from "../../../routes/constsRouter";
import { BodyInterface, ErrorLoginControlPanelInterface } from './indexModel';
import { useEffect, useState } from 'react';
import { setForceLogout, setShowAlertFeedback } from '../../../store/internal';
import constsApi from '../../../services/constsApi';
import { 
  getUserControlPanelAuthMethodDefault, 
  setUserControlPanelAuthMethodDefault, 
  setUserControlPanelEmail, 
  setUserControlPanelHasAppAuth, 
  setUserControlPanelId, 
  setUserControlPanelPermissions, 
  getUserControlPanelId 
} from '../../../store/user_controlpanel';
import { ErrorLoginInterface } from '../../login/indexModel';

const AppRequesterConst = new AppRequesterController();

const LoginPanelController = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [email, setEmail] = useState(null as string || null);
  const [password, setPassword] = useState(null as string || null);
  const [tempToken, setTempToken] = useState(null as string || null);
  const [tempDataLogin, setTempDataLogin] = useState(null);

  const [showfeedbackPassword, setShowfeedbackPassword] = useState(false);
  const [showfeedbackConfirmPassword, setShowfeedbackConfirmPassword] = useState(false);
  const [showfeedbackEmail, setShowfeedbackEmail] = useState(false);
  const [showfeedback2FA, setShowFeedback2FA] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [scene, setScene] = useState<'login'|'signup'|'signup_success'|'confirm-email'|'verify_2fa'|'config_2fa'>('login');
  const [type2fa, setType2fa] = useState<'email'|'app'>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [validated] = useState(false);
  const [hasApp2FA, setHasApp2FA] = useState(false);

  const authMethodDefault = useSelector(getUserControlPanelAuthMethodDefault);
  const credentialId = useSelector(getUserControlPanelId);

  useEffect(() => {
    document.title = `Painel Cxpress | Login`;
    localStorage.getItem('hasLogin') === 'true' && navigate(constsRouters.routers.controlPanelclients.path);
  }, []);

  const clickPassword = () => {
    if ((document.getElementById('user_password') as HTMLInputElement).type === "text") {
      (document.getElementById('user_password') as HTMLInputElement).type = "password";
    } else {
      (document.getElementById('user_password') as HTMLInputElement).type = "text";
    }
  
    setShowPassword(!showPassword);
  };

  const clickConfirmPassword = () => {
    if ((document.getElementById('confirmPassword') as HTMLInputElement).type === "text") {
      (document.getElementById('confirmPassword') as HTMLInputElement).type = "password";
    } else {
      (document.getElementById('confirmPassword') as HTMLInputElement).type = "text";
    }
  
    setShowConfirmPassword(!showConfirmPassword);
  }

  const submitLogin = async () => {
    const body = { email, password };

    const headers = {
      'Content-Type': 'application/json; charset=utf-8'
    };

    await AppRequesterConst.Post(
      '/panel/login', body, { headers },
      (response: Object) => { },
      (response: RequestDataLoginControlPanelInterface) => {
        if(response.data !== undefined) {
          dispatch(setToken(response.data.token));
          dispatch(setUserControlPanelId(response.data.id));
          dispatch(setUserControlPanelAuthMethodDefault(response.data.authentication_method_default));
          
          if (email === 'admin@elife.com.br') {
            processDispatch(response.data);
            navigate(constsRouters.routers.controlPanelclients.path);
          } else {
            setTempDataLogin(response.data);
            setTempToken(response.data.token);
            setScene('verify_2fa');
            setHasApp2FA(response.data.has_app_authentication);
            
            if (response.data.authentication_method_default === constsApi.authentication_methods.app) {
              if (response.data.has_app_authentication) {
                setType2fa('app');
              } else {
                setScene('config_2fa');
              }
            } else {
              setType2fa('email');
            }
          }
        }
      }, 
      (error: ErrorLoginControlPanelInterface) => {
        if (error.response !== undefined) {
          if (error.response.status === 422) {
            document.getElementById("feedbackEmail").innerHTML = "Email inválido";
            setShowfeedbackEmail(true);
          } else if (error.response.status === 401) {
            if (error.response.data.code_cxpress === 2614) {
              setScene('confirm-email');
            } else {
              document.getElementById("feedbackEmail").innerHTML = "Email ou senha incorretos";
              setShowfeedbackEmail(true);
              document.getElementById("feedbackPassword").innerHTML = "Email ou senha incorretos";
              setShowfeedbackPassword(true);
            }
          } 
        }
      },
      navigate, dispatch, setIsLoading,
      () => { // no caso de 401 ou acesso inautorizado o forceLogout fica como true, mesmo que não esteja logado, forçando o logout em seguida que for logado        
        dispatch(setForceLogout(false));
      }
    );
  }

  const submitVerify2FA = async (values) => {
    setShowFeedback2FA(false);
    const body = {
      code: String(values.code_2fa),
      token: tempToken,
      autenticationType: authMethodDefault,
    };

    const headers = {
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': 'Basic ' + process.env.REACT_APP_TOKEN_BASIC_AUTH,
    };

    await AppRequesterConst.Post(
      '/panel-two-factor-authentication/verify-code', body, { headers },
      (_response: Object) => {},
      (response: any) => {
        if (response.data) {
          const newData: DataLoginControlPanelInterface = Object.assign({}, tempDataLogin);
          newData.token = response.data.token;
          setTempDataLogin(null);
          setTempToken(null);
          dispatch(setToken(response.data.token));
          processDispatch(newData);
          navigate(constsRouters.routers.controlPanelclients.path);
        }
      },
      (error: ErrorLoginInterface) => {
        setIsLoading(false);
        if (error.response?.status === 401) {
          document.getElementById('feedback_code_2fa').innerHTML = 'Código de autenticação inválido';
          setShowFeedback2FA(true);
        } else if (error.response?.status === 400) {
          if (String(error.response?.data).includes('números')) {
            document.getElementById('feedback_code_2fa').innerHTML = 'O código deve conter apenas números';
          } else if (String(error.response?.data).includes('caracteres')) {
            document.getElementById('feedback_code_2fa').innerHTML = 'O código deve ter 6 caracteres';
          } else {  
            document.getElementById('feedback_code_2fa').innerHTML = 'Algo de errado aconteceu. Tente novamente';
          }
          setShowFeedback2FA(true);
        }
      },
      navigate, dispatch, setIsLoading,
      () => {
        dispatch(setForceLogout(false));
      }
    )
  }

  const submitSignup = async () => {
    const body = { email, password };

    const headers = {
      'Content-Type': 'application/json; charset=utf-8'
    };

    await AppRequesterConst.Post(
      '/panel/credentials', body, { headers },
      (_response: Object) => { },
      (response: RequestDataLoginControlPanelInterface) => {
        if(response.data !== undefined) {
          dispatch(setToken(response.data.token));
          dispatch(setShowAlertFeedback({ message: 'Conta cadastrada com sucesso', visibility: true, signalIcon: true }));
          setScene('signup_success');
          navigate(constsRouters.routers.controlPanelclients.path)
        }
      }, 
      (error: { response: { status: number; data: { message: { message: string, path: []; }, code_cxpress: number; }; }; }) => {
        if (error.response !== undefined) {
          if (error.response.status === 422) {
            if(error.response.data.message[0].message.includes("password")){
              document.getElementById("feedbackPassword").innerHTML = "Senha inválida";
              setShowfeedbackPassword(true);
            }else {
              document.getElementById("feedbackEmail").innerHTML = "Email inválido";
              setShowfeedbackEmail(true);
            }
          } else if (error.response.status === 401) {
            document.getElementById("feedbackEmail").innerHTML = "Login ou senha incorretos";
            setShowfeedbackEmail(true);
            document.getElementById("feedbackPassword").innerHTML = "Login ou senha incorretos";
            setShowfeedbackPassword(true);
          } else if (error.response.status === 400 && error.response.data.code_cxpress === 2606) {
            dispatch(setShowAlertFeedback({ message: error.response.data.message, visibility: true, signalIcon: false }));
          } else {
            dispatch(setShowAlertFeedback({ message: 'Falha ao cadastrar conta', visibility: true, signalIcon: false }));
          }
        }
      },
      navigate, dispatch, setIsLoading,
    );
  }

  const validateLogin = (values: BodyInterface) => {
    const errors = {};

    setEmail(values.email);
    setPassword(values.password);

    const regex = new RegExp(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,10}$/i);
    if (!values.email) {
      errors['email'] = 'Preencha este campo';
      setShowfeedbackEmail(true);
    } else if (!regex.test(values.email)) {
      errors['email'] = 'E-mail inválido';
      setShowfeedbackEmail(true);
    } else {
      setShowfeedbackEmail(false);
    }

    if (errors['email'] === undefined) {
      if (!values.password) {
        errors['password'] = 'Preencha este campo';
        setShowfeedbackPassword(true);
      } else {
        setShowfeedbackPassword(false);
      }
    } else {
      setShowfeedbackPassword(false);
    }
    
    return errors;
  }

  const validate2FA = (values: { code_2fa: string }) => {
    const errors = {};
    if (isNaN(Number(values.code_2fa))) {
      errors['code_2fa'] = 'O código deve conter apenas números';
      setShowFeedback2FA(true);
      changeDocument('feedback_code_2fa', 'O código deve conter apenas números');
    } else if (!values.code_2fa || String(values.code_2fa).length === 0) {
      errors['code_2fa'] = 'Preencha este campo';
      setShowFeedback2FA(true);
      changeDocument('feedback_code_2fa', 'Preencha este campo');
    } else if (String(values.code_2fa).length !== 6) {
      errors['code_2fa'] = 'O código deve ter 6 caracteres';
      setShowFeedback2FA(true);
      changeDocument('feedback_code_2fa', 'O código deve ter 6 caracteres');
    } else {
      setShowFeedback2FA(false);
      changeDocument('feedback_code_2fa', '');
    }
    return errors;
  }

  const validateSignup = (values: BodyInterface) => {
    const errors = {};

    setEmail(values.email);
    setPassword(values.password);

    const regex = new RegExp(/^[A-Z0-9._%+-]+@(elife\.com\.br|gocxpress\.com\.br)$/i);

    if (!values.email) {
      errors['email'] = 'Preencha este campo';
      setShowfeedbackEmail(true);
    } else if (!regex.test(values.email)) {
      errors['email'] = 'Apenas domínio @elife.com.br ou @gocxpress.com.br são permitidos';
      setShowfeedbackEmail(true);
    } else {
      setShowfeedbackEmail(false);
    }

    if (errors['email'] === undefined) {
      if (!values.password) {
        errors['password'] = 'Preencha este campo';
        setShowfeedbackPassword(true);
      } else if (values.password.length > 0 && values.password.length <= 4) {
        errors['password'] = 'Insira ao menos 5 caracteres';
        setShowfeedbackPassword(true);
      } else if (values.password.length > 128) {
        errors['password'] = 'Senha com tamanho maior que 128 caracteres';
        setShowfeedbackPassword(true);
      } else if (email?.includes(values?.password)) {
        errors['password'] = 'A senha e o e-mail devem ser diferentes';
        setShowfeedbackPassword(true);
      } else if (!/[!@#$%^&*()_+{}\[\]:;<>,.?~\\-]/.test(values.password)) {
        errors['password'] = 'A senha deve conter pelo menos um caractere especial';
        setShowfeedbackPassword(true);
      } else if (!/[A-Z]/.test(values.password)) {
        errors['password'] = 'A senha deve conter pelo menos uma letra maiúscula';
        setShowfeedbackPassword(true);
      } else if (!/[a-z]/.test(values.password)) {
        errors['password'] = 'A senha deve conter pelo menos uma letra minúscula';
        setShowfeedbackPassword(true);
      } else if (!/\d/.test(values.password)) {
        errors['password'] = 'A senha deve conter pelo menos um número';
        setShowfeedbackPassword(true);
      } else {
        setShowfeedbackPassword(false);
      }
    } else {
      setShowfeedbackPassword(false);
    }

    if (errors['password'] === undefined && errors['email'] === undefined) {
      if (!values.confirmPassword) {
        errors['confirmPassword'] = 'Preencha este campo';
        setShowfeedbackConfirmPassword(true);
      } else if (values.password !== values.confirmPassword) {
        errors['confirmPassword'] = 'Senhas precisam ser idênticas.';
        setShowfeedbackConfirmPassword(true)
      } else {
        setShowfeedbackConfirmPassword(false);
      }
    } else {
      setShowfeedbackConfirmPassword(false);
    }
    
    
    return errors;
  }

  const changeDefault2FA = async (type: 'email' | 'app') => {
    const body = {
      credencial_id: credentialId,
      authentication_method_default: type === 'email' ? constsApi.authentication_methods.email : constsApi.authentication_methods.app,
    };

    const headers = {
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': 'Basic ' + process.env.REACT_APP_TOKEN_BASIC_AUTH,
    };

    await AppRequesterConst.Put(
      '/panel-two-factor-authentication/set-authentication_method_default', body, { headers },
      (_response: Object) => { },
      async (_response: any) => {
        dispatch(setUserControlPanelAuthMethodDefault(body.authentication_method_default));
        if (type === 'email') {
          await submitLogin();
          setType2fa('email');
        } else {
          setType2fa('app');
        }
      },
      (error: ErrorLoginInterface) => {
        setIsLoading(false);
        if (error.response?.status === 401) {
          document.getElementById('feedback_code_2fa').innerHTML = 'Não foi possível alterar o método default de autenticação';
          setShowFeedback2FA(true);
        } else if (error.response?.status === 400) {
          document.getElementById('feedback_code_2fa').innerHTML = 'Algo de errado aconteceu';
          setShowFeedback2FA(true);
        }
      },
      navigate,
      dispatch,
      setIsLoading,
    );
  }

  const processDispatch = (data: DataLoginControlPanelInterface) => {
    localStorage.setItem('hasLogin', 'true');
    dispatch(setUserControlPanelId(data.id));
    dispatch(setUserControlPanelEmail(data.email));
    dispatch(setUserControlPanelAuthMethodDefault(data.authentication_method_default));
    dispatch(setUserControlPanelHasAppAuth(data.has_app_authentication));
    dispatch(setUserControlPanelPermissions(data.permissions));
  }

  const changeDocument = (element_id: string, text: string) => {
    if (document.getElementById(element_id)) {
      if (text) {
        document.getElementById(element_id).innerHTML = text;
      } else {
        document.getElementById(element_id).innerHTML = '';
      }
    }
  }

  const goToForgotPassword = () => {
    navigate(constsRouters.routers.controlPanelForgotPassword.path);
  }

  return (
    <LoginControlPanel
      submitLogin={submitLogin}
      validateLogin={validateLogin}
      submitSignup={submitSignup}
      validateSignup={validateSignup}
      validated={validated}
      showfeedbackPassword={showfeedbackPassword}
      showfeedbackConfirmPassword={showfeedbackConfirmPassword}
      showfeedbackEmail={showfeedbackEmail}
      showfeedback2FA={showfeedback2FA}
      showPassword={showPassword}
      showConfirmPassword={showConfirmPassword}
      setShowPassword={setShowPassword}
      isLoading={isLoading}
      clickPassword={clickPassword}
      clickConfirmPassword={clickConfirmPassword}
      scene={scene}
      setScene={setScene}
      type2fa={type2fa}
      hasApp2FA={hasApp2FA}
      validate2FA={validate2FA}
      submitVerify2FA={submitVerify2FA}
      changeDefault2FA={changeDefault2FA}
      goToForgotPassword={goToForgotPassword}
    />
  );
}

export default LoginPanelController;
