import { FC, useState } from "react";
import { IMiniChat } from "./indexModel";
import { Button, Col, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import styles from "./styles.module.css"; // O CSS já está importado
import { IconX } from "@tabler/icons-react";
import Utils from "../../../../../../core/shared/utils";

const MiniChat: FC<IMiniChat> = (props) => {
    return (
        /* Este row serve como o container */
        <div style={props.showChat || props.isAnimating ? {width: '100%', height: '100%', position: 'fixed', bottom: 0, right: 0, zIndex: 1000 } : { overflow: "hidden", position: 'fixed', bottom: 0, right: 0, zIndex: 1000}} className="nopadding">
            {props.showChat || props.isAnimating ? (
                <Row
                    id="chat-container"
                    className={`nopadding ${props.showChat ? styles.slideIn : styles.slideOut}`} // Classes do styles.module.css
                    style={{ borderWidth: "0px 2px", borderStyle: "solid", borderColor: props.headerColor || "#000000", borderRadius: "5px", height: '100vh' }}
                >
                    <Row
                        style={{ backgroundColor: props.headerColor || '#1E1E1E', borderRadius: '3.2px 3.2px 0px 0px', color: '#FFFFFF', font: 'var(--px15_19_Bold-font)', height: '10vh' }}
                        className="nopadding py-1 d-flex justify-content-end"
                    >
                        <Col style={{ paddingLeft: '0px' }} className="d-flex align-items-center">
                            {props.previewImageURL && (
                                <img src={props.previewImageURL} className={`my-1 mx-1 me-2 ${styles['img-iframe']}`} style={{ backgroundColor: '#fff', borderRadius: '50%', padding: '2px' }} alt="" width={'24px'} height={"24px"} />
                            )}
                            <OverlayTrigger
                                placement="bottom"
                                overlay={
                                    <Tooltip>
                                        {props.botData !== null && props.botData.bot_name}
                                    </Tooltip>
                                }
                            >
                                <span className='oneLineClampEllipsis' style={{ color: props.nameColor || "#fff", width: '250px' }}>
                                    {props.botData !== null && props.botData.bot_name}
                                </span>
                            </OverlayTrigger>
                        </Col>
                        <Col className="d-flex justify-content-end" style={{ height: '100%' }}>
                            <Button onClick={props.handleChatToggle} className="nopadding" style={{ border: 'none', background: 'none' }}>
                                <IconX color="#FFFFFF" />
                            </Button>
                        </Col>
                    </Row>
                    <Row
                        id="#chat"
                        className="d-flex align-items-center justify-content-start nopadding"
                        style={{
                            height: '75vh',
                            backgroundColor: props.backgroundColor || '#f9f9f9',
                            overflowY: 'auto',
                            overflowX: 'hidden',
                        }}
                    >
                        {props.hasBot ? (
                            <>
                                <div className="chat-wrapper" style={{ minHeight: '100%' }}>
                                    {props.renderMessages()}
                                </div>
                                <div ref={props.messagesEndRef} />
                            </>
                        ) : (
                            props.noBot()
                        )}
                    </Row>
                    {props.botData !== null ? (
                        <Row
                            className="d-flex justify-content-start py-2 mt-0 nopadding"
                            onClick={() => { props.inputRef.current.focus() }}
                            style={{
                                backgroundColor: '#f8f9fa',
                                font: 'normal normal bold calc(0.2em + 0.7vw) Roboto',
                                color: '#707070',
                                borderWidth: "2px 0px",
                                borderStyle: "solid",
                                borderColor: props.headerColor || "#1E1E1E",
                                borderRadius: "0px 0px 5px 5px",
                                height: '15vh'
                            }}
                        >
                            <Col className="nopadding me-3">
                                <textarea
                                    placeholder={props.t("configurations.chatbots.view.custom_chat_bot.write_message")}
                                    className={styles.editor + " nopadding align-items-center"}
                                    onKeyUp={props.enterClick}
                                    ref={props.inputRef}
                                    style={{ border: 'none', background: 'transparent' }}
                                ></textarea>
                            </Col>
                            <Col md={2} className="nopadding d-flex align-items-center justify-content-end me-1">
                                <Button
                                    id="sendButtonMessage"
                                    type="submit"
                                    onClick={props.handleClickSendMessage}
                                    className={styles.buttonBlueSendMessage + " d-flex justify-content-center align-items-center"}
                                    style={{ minWidth: '60px', backgroundColor: props.sendButtonColor || "#707070", color: Utils.getTextColorForBackground(props.sendButtonColor) }}
                                >
                                    {props.t("configurations.chatbots.view.iframe.send")}
                                </Button>
                            </Col>
                        </Row>
                    ) : <></>}
                </Row>
            ) : (
                <Row className="d-flex justify-content-end nopadding">
                    <Col
                        className="d-flex justify-content-center align-items-center"
                        style={{
                            borderRadius: '50%',
                            border: `2px solid ${props.headerColor || '#000000'}`,
                            backgroundColor: '#fff',
                            width: '70px',
                            height: '70px',
                            cursor: 'pointer',
                            padding: '5px'
                        }}
                        onClick={props.handleChatToggle}
                    >
                        {props.previewImageURL && (
                            <img
                                src={props.previewImageURL}
                                className={`${styles['img-iframe']}`}
                                style={{ borderRadius: '50%', /*border: `2px solid ${props.headerColor || '#000000'}`,*/ cursor: 'pointer' }}
                                width={'60px'}
                                height={"60px"}
                            />
                        )}
                    </Col>
                </Row>
            )}
        </div>
    );
}

export default MiniChat;