import { useTranslation } from "react-i18next";
import { BodyInterface } from "./indexModel";
import Utils from "../../../core/shared/utils";

export class RegisterFormControler {

    private setShowfeedbackName: Function
    private setShowfeedbackLastname: Function
    private setForceValidateWhats: Function
    private setWhatsappError: Function
    private whatsappTag: string
    private setshowfeedbackEmail: Function
    private setShowfeedbackCompany_name: Function
    private setShowfeedbackPassword: Function
    private validSelectDropdownCompanyEmployeeNumber
    private setShowfeedbackDocument_number: Function
    private user_email: string
    private setShowPassword: Function
    private showPassword: boolean
    private setStage: Function
    private stage: number
    private setProgress: Function
    private progress: number
    private Setcompany_employee_number
    private RegisterControlerConst
    private props
    private t = useTranslation().t;

    constructor(setShowfeedbackName: Function, setShowfeedbackLastname: Function, setForceValidateWhats: Function,
        setWhatsappError: Function, whatsappTag: string, setshowfeedbackEmail: Function, setShowfeedbackCompany_name: Function,
        setShowfeedbackPassword: Function, validSelectDropdownCompanyEmployeeNumber: Function, setShowfeedbackDocument_number: Function,
        user_email: string, setShowPassword: Function, showPassword: boolean, setStage: Function, stage: number, setProgress: Function,
        progress: number, Setcompany_employee_number, RegisterControlerConst, props) {

        this.setShowfeedbackName = setShowfeedbackName
        this.setShowfeedbackLastname = setShowfeedbackLastname
        this.setForceValidateWhats = setForceValidateWhats
        this.setWhatsappError = setWhatsappError
        this.whatsappTag = whatsappTag
        this.setshowfeedbackEmail = setshowfeedbackEmail
        this.setShowfeedbackCompany_name = setShowfeedbackCompany_name
        this.setShowfeedbackPassword = setShowfeedbackPassword
        this.validSelectDropdownCompanyEmployeeNumber = validSelectDropdownCompanyEmployeeNumber
        this.setShowfeedbackDocument_number = setShowfeedbackDocument_number
        this.user_email = user_email
        this.setShowPassword = setShowPassword
        this.showPassword = showPassword
        this.setStage = setStage
        this.stage = stage
        this.setProgress = setProgress
        this.progress = progress
        this.Setcompany_employee_number = Setcompany_employee_number
        this.RegisterControlerConst = RegisterControlerConst
        this.props = props
    }

    ValidateRegisterForm(values: BodyInterface) {
        let errors = {};
        if (values.name && !/^[a-zA-ZÀ-ÿ\s'.,-]+$/i.test(values.name)) {
            errors['name'] = this.t("register.errors.invalid_name");
            this.setShowfeedbackName(true);
        } else if (values.name.length > 25) {
            errors['name'] = this.t("register.errors.limit_chars_name");
            this.setShowfeedbackName(true);
        } else {
            this.setShowfeedbackName(false);
        }

        if (values.lastname && !/^[a-zA-ZÀ-ÿ\s'.,-]+$/i.test(values.lastname)) {
            errors['lastname'] = this.t("register.errors.invalid_lastname");
            this.setShowfeedbackLastname(true);
        } else if (values.lastname.length > 25) {
            errors['lastname'] = this.t("register.errors.limit_chars_lastname");
            this.setShowfeedbackLastname(true);
        } else {
            this.setShowfeedbackLastname(false);
        }

        // if (!values.name) {
        //     errors['name'] = this.t("register.errors.fill_name");
        //     this.setShowfeedbackName(true);
        // } else if (values.name.length > 25) {
        //     errors['name'] = this.t("register.errors.limit_chars_name");
        //     this.setShowfeedbackName(true);
        // }
        // else if (!/^[a-zA-ZÀ-ÿ\s'.,-]+$/i.test(values.name)) {
        //     errors['name'] = this.t("register.errors.invalid_name");
        //     this.setShowfeedbackName(true);
        // }
        // else {
        //     this.setShowfeedbackName(false);
        // }

        // if (!values.lastname) {
        //     errors['lastname'] = this.t("register.errors.fill_lastname");
        //     this.setShowfeedbackLastname(true);
        // } else if (values.lastname.length > 25) {
        //     errors['lastname'] = this.t("register.errors.limit_chars_lastname");
        //     this.setShowfeedbackLastname(true);
        // }
        // else if (!/^[a-zA-ZÀ-ÿ\s'.,-]+$/i.test(values.lastname)) {
        //     errors['lastname'] = this.t("register.errors.invalid_lastname");
        //     this.setShowfeedbackLastname(true);
        // }
        // else {
        //     this.setShowfeedbackLastname(false);
        // }

        // if (!values.whatsapp) {
        //     this.setWhatsappError((errorWhats) => {
        //         if (errorWhats !== null)
        //             errors[this.whatsappTag] = errorWhats;
        //     });
        // }

        return errors;
    }

    ValidateEmailForm(values: BodyInterface) {
        let errors = {};
        if (!values.email) {
            errors['email'] = this.t("login.error_fill_field");
            this.setshowfeedbackEmail(true);
        } else if (values.email &&
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,10}$/i.test(values.email)
        ) {
            errors['email'] = this.t("login.error_invalid_email");
            this.setshowfeedbackEmail(true);
        }
        else if (values.email.length >= 100) {
            errors['email'] = this.t("register.errors.limit_chars_email");
            this.setshowfeedbackEmail(true);
        } else {
            this.setshowfeedbackEmail(false);
        }
        return errors;
    }

    ValidateCompanyForm(values: BodyInterface) {
        let errors = {};

        if (
            values.company_name && !/^[a-zA-Z0-9À-ÿÇç&-]+(([',. -][a-zA-Z0-9 ])?[a-zA-Z0-9]*)*$/g.test(values.company_name)
        ) {
            errors['company_name'] = this.t("register.errors.invalid_company_name");
            this.setShowfeedbackCompany_name(true);
        } else if (values.company_name.length > 25) {
            errors['company_name'] = this.t("register.errors.limit_chars_company_name");
            this.setShowfeedbackCompany_name(true);
        } else {
            this.setShowfeedbackCompany_name(false);
        }

        if (values.password) {    
            if (values.password.length > 0 && values.password.length <= 4) {
                errors['password'] = this.t("register.errors.limit_short_password");
                this.setShowfeedbackPassword(true);
            } else if (values.password.length > 128) {
                errors['password'] = this.t("register.errors.limit_password");
                this.setShowfeedbackPassword(true);
            } else if (this.user_email?.includes(values?.password)) {
                errors['password'] = this.t("register.errors.distinct_password_email");
                this.setShowfeedbackPassword(true);
            } else if (!/[!@#$%^&*()_+{}\[\]:;<>,.?~\\-]/.test(values.password)) {
                errors['password'] = this.t("register.errors.fill_special_character_password");
                this.setShowfeedbackPassword(true);
            } else if (!/[A-Z]/.test(values.password)) {
                errors['password'] = this.t("register.errors.fill_uppercase_character_password");
                this.setShowfeedbackPassword(true);
            } else if (!/[a-z]/.test(values.password)) {
                errors['password'] = this.t("register.errors.fill_lowercase_character_password");
                this.setShowfeedbackPassword(true);
            } else if (!/\d/.test(values.password)) {
                errors['password'] = this.t("register.errors.fill_number_password");
                this.setShowfeedbackPassword(true);
            } else if (/012|123|234|345|456|567|678|789/.test(values.password)) {
                errors['password'] = this.t("register.errors.no_sequential_numbers");
                this.setShowfeedbackPassword(true);
            } else {
                this.setShowfeedbackPassword(false);
            }
        } else {
            this.setShowfeedbackPassword(false);
        }

        this.validSelectDropdownCompanyEmployeeNumber();

        if (values.document_number && values.document_number.search("_") !== -1) {
            //errors['document_number'] = `${this.t('defaults.cnpj_nif')} inválido`;
            errors['document_number'] = this.t("register.errors.invalid_document");
            this.setShowfeedbackDocument_number(true);
        } else {
            this.setShowfeedbackDocument_number(false);
        }

        return errors;
    }

    ClickToViewPassword() {
        this.setShowPassword(!this.showPassword);

        if ((document.getElementById("user_password") as HTMLInputElement).type === "text") {
            (document.getElementById("user_password") as HTMLInputElement).type = "password";
        } else {
            (document.getElementById("user_password") as HTMLInputElement).type = "text";
        }
    }


    clickNextStage() {
        this.setStage(this.stage + 1);
        this.setProgress(this.progress + 34);

        this.RegisterControlerConst.ClickStage();

        this.setStage((state) => {
            Utils.changeStyleElementById('stage' + state, 'display', 'block');
            return state;
        });
    }

    clickPreviousStage() {
        this.setStage(this.stage - 1);
        this.setProgress(this.progress - 34);

        this.RegisterControlerConst.ClickStage();

        this.setStage((state) => {
            Utils.changeStyleElementById('stage' + state, 'display', 'block');
            return state;
        });

    }

    OnSelectDropdownCompanyEmployeeNumber(value: string) {
        this.Setcompany_employee_number(value);
        this.RegisterControlerConst.OnSelectDropdownCompanyEmployeeNumber(value, this.props.isMobile);
    }
}