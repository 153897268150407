import { FC } from "react";
import { IModalActiveBase } from "./indexModal";
import { Button, Col, Form, ListGroup, Modal, OverlayTrigger, Popover, Row, Tooltip } from "react-bootstrap";
import { Formik } from "formik";
import styles from "./styles";
import { useSelector } from "react-redux";
import { getShowModalActiveBase } from "../../../../../store/internal";
import LocalizedPhoneInputController from "../../../../homeApp/components/localizedPhoneInput/indexController";
import { IconFileUpload } from "@tabler/icons-react";
import { ListName } from "../../../../../core/enums/order-sort-by";
import Utils from "../../../../../core/shared/utils";
import { formatPhoneNumberIntl } from "react-phone-number-input";
import AttachmentsInMessageController from "../formConfigTemplateMessages/attachmentsInMessage/indexController";

const ModalActiveBase: FC<IModalActiveBase> = (props) => {
  const popover = (number: string) => {
    return (
      <Popover style={{ zIndex: '3000', backgroundColor: '#e6e6e6', border: 'none', color: '#40484E', font: 'var(--px15_19-font-Roboto)' }}>
        <Popover.Body className="nopadding">
          <Button className="m-1" style={{ backgroundColor: 'transparent', border: 'none', color: 'black', fontSize: 'inherit' }} onClick={() => { props.deleteNumber(number); Utils.hidePopoverOnScroll(ListName.ListOfPhones); }}>Excluir</Button>
        </Popover.Body>
      </Popover>
    )
  }

  return (
    <Modal show={useSelector(getShowModalActiveBase) && !props.isLoading} onHide={props.onHide} centered>
      <Modal.Header closeButton className="mx-2">
        <Modal.Title style={{ textAlign: 'left', font: 'normal normal bold calc(0.2em + 1vw) Montserrat', letterSpacing: '0px', color: 'black', opacity: '1', display: 'flex', alignItems: 'center' }}>Atualizar base de ativos</Modal.Title>
      </Modal.Header>
      <Modal.Body className="pt-0 mx-2">
        <Formik
          initialValues={{ phone: "" }}
          validate={props.validate}
          onSubmit={props.onSubmit}
        >
          {({
            handleBlur,
            handleSubmit
          }) => (
            <Form onSubmit={handleSubmit} noValidate>
              <div className="switch-input mb-3" >
                <span style={styles.textFontGlay}>Deseja utilizar o controle de acesso?</span>
                <OverlayTrigger
                  key='top'
                  placement='top'
                  overlay={
                    <Tooltip id='tooltip'>
                      {props.useControlAcess ?
                        "Ativo" :
                        "Inativo"
                      }
                    </Tooltip>
                  }
                >
                  <label className="switch">
                    <input
                      type="checkbox"
                      checked={props.useControlAcess}
                      onChange={props.useControlAcess ? () => props.setUseControlAcess(false) : () => props.setUseControlAcess(true)}
                    />
                    <span className="slider round"></span>
                  </label>
                </OverlayTrigger>
              </div>
              {props.useControlAcess &&
                <>
                  <Row style={styles.textFontGlay} className="d-flex justify-content-left mb-3">
                    <Col>
                      <Row className="nopadding">
                        <Form.Label htmlFor="basic-url" className="ps-0">Número adicional</Form.Label>
                      </Row>
                      <Row>
                        <Col sm={9} className="pe-0">
                          <LocalizedPhoneInputController
                            placeholder={"Informe o telefone"}
                            value={props.phone}
                            onChange={(e) => props.setPhone(e)}
                            handleBlur={handleBlur}
                            phoneBorder={props.phoneBorder}
                            previewCountry={'br'}
                          />
                        </Col>
                        <Col className="d-flex justify-content-end">
                          <Button size="sm" className="buttonBlue" disabled={props.phone?.length < 13} onClick={props.addPhone} /* style={{ width: '56%' }} */ type="submit">Adicionar</Button>
                        </Col>
                      </Row>
                      <Row>
                        <div className='invalid-feedback' style={{ display: props.showFeedbackPhone ? "block" : "none", height: '0.75rem' }}>
                          {props.t('home_consumers.modal_edit.form_phone.invalid_number')}
                        </div>
                      </Row>
                    </Col>
                  </Row>
                  <Row style={styles.textFontGlay} className="mb-3 d-flex justify-content-center">
                    <Col sm={true}>
                      <Form.Label htmlFor="basic-url">
                        <span className="me-2">Substituir lista de números</span>
                        <IconFileUpload size={20} color="rgb(74, 105, 189)" />
                      </Form.Label>
                      <AttachmentsInMessageController setAttachmentsFilesIds={props.setGfids} setAttachmentFiles={(files) => props.setFiles(files)} customUrl="/panel/upload/attachment/whatsapp" largerStyle={true} hiddenTitle={true} filesType={"csv"} dropMessage="Selecione ou arraste uma planilha de números" />
                    </Col>
                  </Row>
                  <Row style={styles.textFontGlay} className="mb-3 d-flex justify-content-center">
                    <Col>
                      <div className="px-1" style={{ border: '1px solid #E8E8E8', borderRadius: '1px', boxShadow: '1px 1px #E8E8E8, -1px -1px #E8E8E8' }}>
                        <ListGroup variant="flush">
                          <ListGroup.Item style={styles.textRowCollums}>
                            <Row name="ListOfAgentsHead">
                              <Col md={8} className="nopadding">
                                Número
                              </Col>
                              <Col className="nopadding d-flex justify-content-end">
                                Ações
                              </Col>
                            </Row>
                          </ListGroup.Item>
                        </ListGroup>
                        {
                          props.phoneList.length > 0 ?
                            <div id={ListName.ListOfPhones} style={{ overflowY: 'auto', height: '20vh' }}
                              onScroll={() => { Utils.hidePopoverOnScroll(ListName.ListOfPhones) }}
                            >
                              {
                                <ListGroup variant="flush" className="TicketsRow">
                                  {
                                    props?.phoneList.map((item, index) => {
                                      let formatedNumber = formatPhoneNumberIntl("+" + item?.user_id) === "" ? item?.user_id : formatPhoneNumberIntl("+" + item?.user_id);

                                      return (
                                        <ListGroup.Item key={index} style={{ color: '#707070' }}>
                                          <Row className="TicketsRowHeight">
                                            <Col md={8} className="align-self-center nopadding" style={{ cursor: 'pointer' }}>
                                              <div className="oneLineClampEllipsis me-3">
                                                <OverlayTrigger
                                                  key='top'
                                                  placement='top'
                                                  overlay={
                                                    <Tooltip id='tooltip'>
                                                      {formatedNumber}
                                                    </Tooltip>
                                                  }
                                                >
                                                  <span>
                                                    {formatedNumber}
                                                  </span>
                                                </OverlayTrigger>
                                              </div>
                                            </Col>
                                            <Col className="nopadding d-flex justify-content-end">
                                              <OverlayTrigger rootClose={true} key={item.user_id} trigger={['click']} placement="left" overlay={popover(item.user_id)}>
                                                <Button id={item.id + "*ID*" + item.user_id + "*ID*"} className='nopadding d-flex justify-content-start align-self-center' style={{ marginTop: '', backgroundColor: 'transparent', border: 'none', width: "24px" }} onClick={(e) => { }}>
                                                  <svg xmlns="http://www.w3.org/2000/svg" width="90%" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#6A6A6A" fill="#6A6A6A" strokeLinecap="round" strokeLinejoin="round">
                                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                    <circle cx="12" cy="12" r="1" />
                                                    <circle cx="12" cy="19" r="1" />
                                                    <circle cx="12" cy="5" r="1" />
                                                  </svg>
                                                </Button>
                                              </OverlayTrigger>
                                            </Col>
                                          </Row>
                                        </ListGroup.Item>
                                      );
                                    })
                                  }
                                </ListGroup>
                              }
                            </div> :
                            <></>
                        }
                      </div>
                    </Col>
                  </Row>
                </>
              }
              <Row className="d-flex justify-content-center mt-4">
                <Col xs={6} className="d-flex justify-content-start">
                  <Button size='lg' className="buttonWhite" style={{ width: '56%' }} onClick={props.onHide}>Cancelar</Button>
                </Col>
                <Col xs={6} className="d-flex justify-content-end">
                  <Button size="lg" className="buttonBlue" style={{ width: '56%' }} type="submit" disabled={props.isLoading}>Salvar</Button>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  )
}

export default ModalActiveBase;