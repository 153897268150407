import { IconPhoto } from '@tabler/icons-react';
import './styles.scss';
import { FormControl } from 'react-bootstrap';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { setShowAlertFeedback } from '../../../../../../store/internal';

const AddImage = (props: any) => {

  const dispatch = useDispatch();

  const [url, setUrl] = useState('');

  const handleChange = (evt) => {
    setUrl(evt.target.value);
  }

  const addImage = () => {
    if (isImageUrl(url)) {
      const { editorState, onChange } = props;
      onChange(props.modifier(editorState, url));
      props.setShowAddImage(false);
      setUrl('');
    } else {
      setUrl('');
      dispatch(setShowAlertFeedback({ message: props.t('home_tickets.chat_editor.error_image'), visibility: true, signalIcon: false }));
    }
  }

  const isImageUrl = (url) => {
    const imageExtensions = ['.jpg', '.jpeg', '.png', '.gif', '.bmp', '.webp'];
    return imageExtensions.some(ext => url.toLowerCase().endsWith(ext));
  }

  return (
    <>
    <div className="icon-img-editor">
      {props.showAddImage && 
      <div className="input-url">
        <FormControl
          className="form-control-Default"
          placeholder={props.t('home_tickets.chat_editor.url_image')}
          aria-label={props.t('home_tickets.chat_editor.url_image')}
          aria-describedby={props.t('home_tickets.chat_editor.url_image')}
          size="lg"
          type="text"
          id="text"
          name="text"
          value={url}
          onChange={handleChange}
      ></FormControl>
        <button disabled={url.trim() === ""} type="button" className="add-img" onClick={() => addImage()}>+</button>
      </div>
      }
      <IconPhoto className='hover' size={20}  onClick={() => props.setShowAddImage(!props.showAddImage)}/>
    </div>
    </>
  )
}

export default AddImage;