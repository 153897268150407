import { useContext, useEffect, useState } from "react"
import ButtonListNotification from "."
import { Popover } from "react-bootstrap"
import TemplateListNotificationController from "../../pages/homeApp/components/modalTemplateListNotification/indexController"
import { TokenInterface } from "../../services/requestsInterfacesModel"
import { useDispatch, useSelector } from "react-redux"
import { getIdCompany } from "../../store/company"
import { getIdEmployee } from "../../store/employee"
import { getIdUser, getNameUser, getProfileId, getUserLastName } from "../../store/user"
import { getToken } from "../../store/token"
import { getMute, getOrderByList, getShowConfirmationModal, getShowEditTagsModal, getShowMeetButton, getSortByList, getSoundNotification, getUpdateButtonNumberOfTickets, getVisualNotification, setShowConfirmationModal, setShowTicketNotification } from "../../store/internal"
import { getOwner_id } from "../../store/callcenter"
import './style.css';
import { TicketNotifyContext } from "../../core/context/ticket-notification-context"
import Logo from "../../core/assets/Logo.jpg"
import { useNavigate } from "react-router-dom"
import constsRouters from "../../routes/constsRouter"
import { useTranslation } from "react-i18next"
import './style.css';
import useTicketNotificationSocket from "../../hooks/useTicketNotificationSocket"
import useTaskNotificationSocket from "../../hooks/useTaskNotificationSocket"
import useReadNotificationTicketSocket from "../../hooks/useReadNotificationTicketSocket"
import { SocketContext } from "../../core/context/socket-context"
import NotificationSoundController from "../notificationSoundComponent/indexController"
import { AppRequesterController } from "../../services/appRequester/appRequesterController"
import UserService from "../../services/user-service"
import constsApi from "../../services/constsApi"
import { TicketStatus } from "../../core/enums/ticket-status"

const ButtonListNotificationController = (props: any) => {

    const values: TokenInterface = {
        company: {
            id: useSelector(getIdCompany)
        },
        employee: {
            id: useSelector(getIdEmployee)
        },
        user: {
            id: useSelector(getIdUser),
            name: useSelector(getNameUser),
            lastname: useSelector(getUserLastName),
            profile_id: useSelector(getProfileId)
        },
        token: {
            value: useSelector(getToken)
        },
        internal: {
            getShowEditTicketsModal: useSelector(getShowEditTagsModal),
            getShowConfirmationModal: useSelector(getShowConfirmationModal),
            getUpdateButtonNumberOfTickets: useSelector(getUpdateButtonNumberOfTickets),
            getOrderByList: useSelector(getOrderByList),
            getSortByList: useSelector(getSortByList),
        },
        callcenter: {
            owner_id: useSelector(getOwner_id),
            buttonShowPhone: useSelector(getShowMeetButton)
        },
        mute: {
            value: useSelector(getMute)
        }
    }

    const { t } = useTranslation()
    const socket = useContext(SocketContext);
    const ticketNo = useContext(TicketNotifyContext)
    const dispatch = useDispatch()
    const { ticket_notification } = useTicketNotificationSocket()
    const { task_notification } = useTaskNotificationSocket()
    const { ticket_read_notification } = useReadNotificationTicketSocket()
    const appRequester = new AppRequesterController();

    const [showPopover, setShowPopover] = useState(false)
    const [visualTicket, setVisualTicket] = useState(false)
    const [visualMessage, setVisualMessage] = useState(false)

    const visualNofication = useSelector(getVisualNotification)
    const soundNotification = useSelector(getSoundNotification);
    const [/* visualNotificationType */, setVisualNotificationType] = useState("");
    const [/* soundNotificationType */, setSoundNotificationType] = useState("");
    const NotificationSoundConst = new NotificationSoundController(values.mute.value, dispatch);

    const [, setIsLoading] = useState();

    const navigate = useNavigate()

    const [socketNotification, setSocketNotification] = useState(false);

    const [, setListNotifications] = useState([]);

    useEffect(() => {
        if (visualNofication === "2fc3c59e-edc5-4a00-92c9-f90c72de634a") {
            setVisualNotificationType("ticket");
        } else if (visualNofication === "101ea4fc-2b7a-40f4-b67a-8d0c9cf13b42") {
            setVisualNotificationType("message");
        } else {
            setVisualNotificationType("");
        }
    }, [visualNofication]);

    useEffect(() => {
        if (soundNotification === "2fc3c59e-edc5-4a00-92c9-f90c72de634a") {
            setSoundNotificationType("ticket");
        } else if (soundNotification === "101ea4fc-2b7a-40f4-b67a-8d0c9cf13b42") {
            setSoundNotificationType("message");
        } else {
            setSoundNotificationType("");
        }
    }, [soundNotification]);

    useEffect(() => {
        if(ticket_notification != undefined) {
            ticket_notification.onmessage = (e) => {
                onSocketNotification(e.data)
            }
        }
    }, [ticket_notification])

    useEffect(() => {
        if (task_notification != undefined) {
            task_notification.onmessage = (e) => {
                onSocketNotification(e.data)
            }
        }
    }, [task_notification])

    useEffect(() => {
        if (ticket_read_notification != undefined) {
            ticket_read_notification.onmessage = (e) => {
                onSocketNotificationRead(e.data)
            }
        }
    }, [ticket_read_notification])

    useEffect(() => {
        const notificationType = (data) => {
            // tickets do historico não contam
            if ((data?.ticket?.status_id === TicketStatus.New || data?.ticket?.status_id === TicketStatus.Pending || data?.ticket?.status_id === TicketStatus.Open || data?.ticket?.status_id == TicketStatus.OnHold) && (data?.ticket?.deleted_at === null)) {
                // notificações são permitidas para agente atribuido ao ticket, ticket sem atribuição e agente no setor do ticket
                let assignedTicket = data?.ticket?.assigned_employee?.user_id === values?.user.id;
                let isAdmin = values.user.profile_id === constsApi.profile[0].id;
                let nonAgent = !data?.ticket?.assigned_employee;
                let nonDepartment = !data?.ticket?.sector_id;

                const activateNotification = () => {
                    setVisualNotificationType((visualType) => {
                        // notificação visual

                        if (data?.isNewTicket === true && visualType === "ticket") {
                            notification("ticket", data, true);
                        } else if (visualType === "message"/*  && !data?.isNewTicket */) {
                            notification("message", data, true);
                        }

                        return visualType;
                    });

                    setSoundNotificationType((soundType) => {
                        // notificação sonora

                        if (data?.isNewTicket === true && soundType === "ticket") {
                            notification("ticket", data, false);
                        } else if (soundType === "message"/*  && !data?.isNewTicket */) {
                            notification("message", data, false);
                        }

                        return soundType;
                    });
                }

                // ativação da notificação se o agente esta no setor do ticket e ticket sem atribuido
                const checkUserInDepartment = async (sectorId: string, userId: string) => {
                    const headers = UserService.getHeaders()

                    await appRequester.Get(
                        `/sector/${sectorId}`, { headers },
                        (response: Object) => {
                        },
                        (data: { data: { code_cxpress: number, sectors: { sector_id: string, agents: any [] }[] } }) => {
                            if (data?.data?.sectors?.length > 0) {
                                let currentSector = data.data.sectors[0];
                                let userInDepartment = currentSector?.agents?.length > 0 && (currentSector.agents.find((agent) => agent.user_id === userId) !== undefined);

                                // user do setor e ticket sem agente atribuido
                                if (userInDepartment) {
                                    activateNotification();
                                }
                            }
                        },
                        (error) => { }, navigate, dispatch, setIsLoading, {  }
                    )
                }

                // ativar notificação com atribuição do user && sem setor e sem agente && admin e ticket sem agente
                if (assignedTicket || (nonAgent && nonDepartment) || (isAdmin && nonAgent)) { 
                    activateNotification();
                } else if (nonAgent) { // caso do ticket sem atribuido mas user pertecence ao setor do ticket
                    // checkando se o user pertence ao setor do ticket
                    checkUserInDepartment(data.ticket.sector_id, values?.user.id);
                }
            }
        }

        socket.socket?.on('new-consumer-message', notificationType);
        // socket.socket?.on('ticket-update', notificationUpdate);

        return () => {
            socket.socket?.off("new-consumer-message", notificationType);
            // socket.socket?.on('ticket-update', notificationUpdate);
        }
    }, [socket.socket?.active]);

    // criação da notificação visual
    const createVisualNotification = (type: string, data) => {
        if (type === "ticket") {
            const text = `${t("notifications.ticket_notification")}: #${data?.ticket?.ticket_reference_id}`;
            const notification = new Notification(t("notifications.ticket_notification"), { body: text, icon: Logo });

            try {
                notification.onclick = () => {
                    navigate(constsRouters?.routers?.tickets?.path)
                    notification.close();
                    setTimeout(() => {

                        ticketNo.setID(data?.ticket?.id);
                        ticketNo.setClick(true)
                        dispatch(setShowTicketNotification(true))
                    }, 1000)

                };
            } catch (error) {
                console.log(error)
            }

        } else if (type === "message") {
            const text = `${t("notifications.message_notification")}: #${data?.ticket?.ticket_reference_id}`;
            const notification1 = new Notification(t("notifications.message_notification"), { body: text, icon: Logo });

            try {
                notification1.onclick = () => {
                    navigate(constsRouters?.routers?.tickets?.path)
                    notification1.close();
                    setTimeout(() => {
                        ticketNo.setID(data?.ticket?.id);
                        ticketNo.setClick(true)
                        dispatch(setShowTicketNotification(true))
                    }, 1000)

                };
            } catch (error) {
                console.log(error)
            }
        }
    }

    const notification = (type: string, data, isVisual: boolean) => {
        // verificando se ja existe uma notificação
        setListNotifications((notifications) => {
            let notificationId = data?.ticket?.id + (isVisual? "-visual-" : "-sound-") + (data?.ticket?.consumer_last_message? data?.ticket?.consumer_last_message.toString() : "");

            // verificar se esta mutado
            const isMuted = localStorage.getItem('cx_notification_mute_sound') === 'true';

            if (!notifications.includes(notificationId) && !isMuted) {
                notifications.push(notificationId);
                if (isVisual) {
                    // faz a verificação das permissões para notificação
                    if (Notification.permission === 'default' || Notification.permission === 'denied') {
                        Notification.requestPermission().then().finally(() => {
                            createVisualNotification(type, data);
                        });
                    } else { // com notificação permitidas apenas cria a notificação
                        createVisualNotification(type, data);
                    }
                } else {
                    NotificationSoundConst.playSound();
                }
            }
            return notifications;
        });
    }

    const onSocketNotificationRead = async (user_id: string) => {
        if (user_id === values.user.id) {
            setSocketNotification(false)
        }
    }

    const onSocketNotification = async (user_id: string) => {
        if (user_id === values.user.id) {
            setSocketNotification(true)
        }
    }

    const showTemplateMessage = () => {
        if (showPopover == false) {
            setShowPopover(true)
        } else {
            setSocketNotification(false)
            setShowPopover(false)
        }
    }

    const popOverListNotification = (
        <Popover id={"popOverListNotification"}
            style={{ border: 'none' }} 
            data-trigger="focus"
        >
            <Popover.Body className="nopadding">
                <TemplateListNotificationController userValues={values} setShowPopover={setShowPopover} visualTicket={visualTicket} setVisualTicket={setVisualTicket} setVisualMessage={setVisualMessage} visualMessage={visualMessage} showTemplateMessage={showTemplateMessage} />
            </Popover.Body>
        </Popover>
    )

    return (
        <ButtonListNotification
            showPopover={showPopover}
            showTemplateMessage={showTemplateMessage}
            popOverListNotification={popOverListNotification}
            currentNotification={socketNotification}
        />
    )
}

export default ButtonListNotificationController