import React, { useState, useEffect, useContext, useRef, FC } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import {
    setShowAlertFeedback,
    getShowEditTicketsModal,
    setRegisterCustomersModal,
    setShowEditConsumersModal,
    setShowConfirmationModal,
    setCleanMessageEditor,
    setUpdateButtonNumberOfTickets,
    getOrderByList,
    getMute,
    getShowEditConsumersModal,
    getSoundNotification
} from '../../../../../store/internal'

import { getIdCompany } from "../../../../../store/company";
import { getIdEmployee } from "../../../../../store/employee";
import { getIdUser, getNameUser, getProfileId, getUserLastName } from "../../../../../store/user";
import { getToken } from "../../../../../store/token";


import constsApi from "../../../../../services/constsApi";
import { TicketStatus } from '../../../../../core/enums/ticket-status';
import { useNavigate } from 'react-router-dom';
import { AppRequesterController } from '../../../../../services/appRequester/appRequesterController';

import { SocketContext } from "../../../../../core/context/socket-context";
import NotificationSocket from "../../../../../sockets/notification-socket";
import BackendConstants from '../../../../../core/constants/backend-constants';
import UserService from '../../../../../services/user-service';
import { OrderBy } from '../../../../../core/enums/order-sort-by';
import { TokenInterface } from '../../../../../services/requestsInterfacesModel';

import { ItemTicketInterface, Option, TicketInterface } from './indexModel';
import { ViewTicketInterface } from './indexModel';


import { ConsumerInterface, UserInterface, SectorActiveInterface, TicketsHistoryInterface, GetAllTicketsInterface } from '../../../../../services/requestsInterfacesModel';
import { TicketFilterType } from '../../../../../core/enums/ticket-filter-type';

import NotificationSoundController from '../../../../../components/notificationSoundComponent/indexController';

import { useTranslation } from 'react-i18next';
import ViewService from '.';
import Utils from '../../../../../core/shared/utils';

const AppRequesterConst = new AppRequesterController();

const ViewServiceController: FC<ViewTicketInterface> = (props) => {

    const socket = useContext(SocketContext);
    const notificationService: NotificationSocket = socket.getNotificationSocket();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const values: TokenInterface = {
        company: {
            id: useSelector(getIdCompany)
        },
        employee: {
            id: useSelector(getIdEmployee)
        },
        user: {
            id: useSelector(getIdUser),
            name: useSelector(getNameUser),
            profile_id: useSelector(getProfileId),
            lastname: useSelector(getUserLastName),
        },
        token: {
            value: useSelector(getToken)
        },
        internal: {
            getShowEditTicketsModal: useSelector(getShowEditTicketsModal),
            getOrderByList: useSelector(getOrderByList),
            getShowEditConsumersModal: useSelector(getShowEditConsumersModal),
        }, mute: {
            value: useSelector(getMute)
        }

    };

    const [aux, setAux] = useState([]);
    const [tags, setTags] = useState([]);
    const [/*followers*/, setFollowers] = useState([]);

    const [currentFilterDatas, setcurrentFilterDatas] = useState(undefined);

    const [currentTicket, setCurrentTicket] = useState(null);
    const [currentLastUpdatedDate, setCurrentLastUpdatedDate] = useState(new Date());
    const [/*nextTicket*/, setNextTicket] = useState(null);
    const [currentStatus, setCurrentStatus] = useState(null);
    const [currentRequester_user, setCurrentRequester_user] = useState(null);
    const [/*currentAssigned_employee_user_id*/, setCurrentAssigned_employee_user_id] = useState("");
    const [currentFilterSearch, SetCurrentFilterSearch] = useState(null);
    const [/*currentFollowers*/, setCurrentFollowers] = useState([]);
    const [currentTags, setCurrentTags] = useState([]);
    const [currentSubject, setCurrentSubject] = useState("");
    const [disableSaveButton, setDisableSaveButton] = useState(false);

    const [/*departmentsOptions*/, setDepartmentsOptions] = useState([]);
    const [/*allDepartments*/, setAllDepartments] = useState(null);
    const [/*selectedDepartments*/, setSelectedDepartments] = useState([]);

    const [consumerCreateItem] = useState({ label: t("home_tickets.home_create_ticket.select_component.create_consumer"), value: "create", key: "create" });
    const [consumersOptions, setConsumersOptions] = useState([consumerCreateItem]);
    const [selectedConsumers, setSelectedConsumers] = useState([]);

    const [agentNotItem] = useState({ label: t("home_tickets.home_create_ticket.null_option"), value: "NA", key: "NA" });
    const [agentsOptions, setAgentsOptions] = useState([agentNotItem]);
    const [/*selectedAgents*/, setSelectedAgents] = useState([]);

    const [/*selectedStatus*/, setSelectedStatus] = useState([{ label: t("home_tickets.home_create_ticket.select_options.status_new"), value: TicketStatus.New }]);

    const [currentPage, SetCurrentPage] = useState(1);
    const [/*currentTicketIsUpdated*/, setCurrentTicketIsUpdated] = useState(false);
    const [currentLimit] = useState(BackendConstants.limit);
    const [/*currentParams*/, SetCurrentParams] = useState({});
    const [currentHasMoreInformation, /*SetCurrentHasMoreInformation*/] = useState(true);
    const [/*isLoading*/, setIsLoading] = useState(false);
    const [currentConsumersData, setCurrentConsumersData] = useState(null);
    const [currentStatusPage, setCurrentStatusPage] = useState("");
    const [forcedUpdate, setForcedUpdate] = useState(false);
    const [/*currentOrderBy*/, setCurrentOrderBy] = useState(values.internal.getOrderByList);

    const [disableEditTicket, setDisableEditTicket] = useState(false);
    const [/*showOptionsButton*/, setShowOptonsButton] = useState(true);

    const [blockBool, setBlockBool] = useState(false);

    const inputs = [useRef(null), useRef(null), useRef(null)];

    const [overlayId, setOverlayId] = useState(null);

    const [/*selected_ccEmails*/, setSelected_ccEmails] = useState((currentTicket && currentTicket.cc_list) ? currentTicket.cc_list : null);
    const [/*selected_ccoEmails*/, setSelected_ccoEmails] = useState((currentTicket && currentTicket.cco_list) ? currentTicket.cco_list : null);


    // let myTimeOut: NodeJS.Timeout


    const [onlyRead, setOnlyRead] = useState(false);

    const NotificationSoundConst = new NotificationSoundController(values.mute.value, dispatch);

    // select create option click
    const customCreateRequesterButtonClick = () => {
        dispatch(setRegisterCustomersModal(true));
    }

    const updateListfunction = () => {
        SetCurrentPage(currentPage + 1);
    };

    useEffect(() => {
        if (values.internal.getShowEditConsumersModal) {
            dispatch(setShowEditConsumersModal(false));
        }
    }, []);

    // desabilitar edição do ticket
    useEffect(() => {
        let disableEdit = (currentTicket && (currentTicket.status_id === TicketStatus.Resolved || currentTicket.deleted_at !== null || currentTicket.finished_at !== null || currentTicket.status_id === TicketStatus.Abandoned)) ? true : false;

        if (props.currentViewFilter) {
            if (props.currentViewFilter === TicketFilterType.Deleted || props.currentViewFilter === TicketFilterType.Finalized)
                setDisableEditTicket(true);
            else if (disableEdit)
                setDisableEditTicket(true);
            else
                setDisableEditTicket(false);

            // view de ticket dentro de tickets excluídos
            if (props.currentViewFilter === TicketFilterType.Deleted)
                setShowOptonsButton(false);
            else
                setShowOptonsButton(true);
        } else if (disableEdit) {
            setDisableEditTicket(true);
            // não inclui a view em deletados então pode mostrar o botão de excluir
            setShowOptonsButton(true);
        } else {
            setDisableEditTicket(blockBool || onlyRead);
            // Bloqueando o ticket, o botão de opções deve ser escondido
            setShowOptonsButton(blockBool || onlyRead);
        }
    }, [currentTicket, props.currentViewFilter, blockBool, onlyRead]);

    useEffect(() => {
        const controller = new AbortController();

        if (values.internal.getShowEditTicketsModal && !forcedUpdate) {
            setIsLoading(true);
            getInformations(controller);

            SetCurrentPage((state) => {
                SetCurrentParams((state2) => {
                    let auxObj = Object.assign({}, props.filters);
                    getAllTicketsToPagination(Object.assign(auxObj, Object.assign(state2, { limit: currentLimit, page: state })), false, controller);
                    return Object.assign(auxObj, Object.assign(state2, { limit: currentLimit, page: state }));
                });
                return state;
            });
        }

        return () => {
            controller.abort();
        }

    }, [currentPage, currentStatusPage]);

    useEffect(() => {
        socket.socket?.on("new-consumer-message", (data) => onSocketUpdateTicketList(data))
        socket.socket?.on("ticket-deleted", (data) => onSocketDeletetList(data))

        return () => {
            socket.socket?.off("new-consumer-message", () => {})
            socket.socket?.off("ticket-deleted", () => {})
        }
    }, [])

    // useEffect(() => {
        // socket.addOnConnectCallback((notificationService) => {
        //     // define os eventos quando a conexao é reiniciada ao recarregar a pagina
        //     if (notificationService) {
        //         // Removendo eventos do componente na hometickets
        //         notificationService.removeCallback('homeTicket');
        //         notificationService.removeDeleteCallback('homeTicket');

        //         notificationService.addCallback('homeTicket', onSocketUpdateTicketList);
        //         notificationService.addDeleteCallback('homeTicket', onSocketDeletetList);
        //     }
        // });

        // if (notificationService) {
        //     // Removendo eventos do componente na hometickets
        //     notificationService.removeCallback('homeTicket');
        //     notificationService.removeDeleteCallback('homeTicket');

        //     // seta novamente os callbacks voltar a tela
        //     notificationService.addCallback('homeTicket', onSocketUpdateTicketList);
        //     notificationService.addDeleteCallback('homeTicket', onSocketDeletetList);
        // }

        // return () => {
        //     if (notificationService) {
        //         notificationService.removeCallback('homeTicket');
        //         notificationService.removeDeleteCallback('homeTicket');
        //     }
        // }

    // }, [values.internal.getShowEditTicketsModal]);

    useEffect(() => {
        const controller = new AbortController();

        if (forcedUpdate) {
            if (document.getElementById("listOfTickets") !== null) {
                document.getElementById("listOfTickets").scrollTop = 0;
            }

            setAux([]);

            SetCurrentPage((state) => {
                SetCurrentParams((state2) => {
                    let auxObj = Object.assign({}, props.filters);
                    getAllTicketsToPagination(Object.assign(auxObj, Object.assign(state2, { limit: currentLimit, page: state })), true, controller);
                    return state2;
                });
                return state;
            });
        }

        return () => {
            controller.abort();
        }
    }, [forcedUpdate]);


    useEffect(() => {
        // atualizando data ao trocar de ticket
        getLastUpdatedDate(currentTicket);

        socket.addOnConnectCallback((notificationService) => {
            // define os eventos quando a conexao é reiniciada ao recarregar a pagina
            if (notificationService) {
                notificationService.setTicketAccessCallback((access: any) => { //access nunca está sendo usado
                    blockTicket(values);
                });
            }
        });

        if (notificationService) {
            notificationService.setTicketAccessCallback((access: any) => {
                blockTicket(values);
            });
        }


        for (let index = 0; index < inputs.length; index++) {
            if (inputs[index]) {
                if (inputs[index].current) {
                    inputs[index].current.disabled = false;
                }
            }
        }
        setBlockBool(false);
        setOnlyRead(false);

        return () => {
            if (notificationService) {
                notificationService.setTicketAccessCallback(() => { });

                if (currentTicket) {
                    if (currentTicket.who_is_viewing === `${values.user.name} ${(values.user.lastname ? values.user.lastname : "")}`) {
                        setAux(aux => {
                            let newAux = [];
                            if (aux) {
                                newAux = aux.map((item) => {
                                    if (item && (item.id === currentTicket.id)) {
                                        item.who_is_viewing = null;
                                    }
                                    return item;
                                });
                                return newAux;
                            }
                            return aux;
                        });
                    }
                    // console.log('useEffect currentTicket currentTicket?.id - if')
                    // socket.getInstance().emit("leave-ticket-chat", currentTicket.id);
                } else {
                    // console.log('useEffect currentTicket currentTicket?.id - else')
                    // socket.getInstance().emit("leave-ticket-chat", props.currentTicket);
                }

            }
        }
    }, [currentTicket && currentTicket?.id]);

    useEffect(() => {
        Utils.changeActiveScrollBar("listOfTickets");
    }, [currentFilterDatas]);
    
    const onSocketUpdateTicketList = async (ticket: { updated_level2_at: string | number | Date; status_id: string; is_bot: boolean; assigned_employee: { user_id: string; }; channel_id: string; sector_id: string; bold: boolean; id: string; who_is_viewing: string; last_message: string }) => {
        setCurrentOrderBy(order => {
            SetCurrentParams((param) => {
                let dateCheck = new Date(ticket?.updated_level2_at);
                let dateStart = new Date(param["start_date"]);
                let dateEnd = new Date(param["end_date"]);

                dateStart = new Date(dateStart.getTime() + (1 * 86400000));
                dateEnd = new Date(dateEnd.getTime() + (1 * 86400000));

                if ((!param["status"] || ticket?.status_id === param["status"]) &&
                    (!param["bot"] || ticket?.is_bot === param["bot"]) &&
                    (!param["employee"] || (ticket?.assigned_employee && ticket?.assigned_employee.user_id === param["employee"])) &&
                    (!param["channel"] || ticket?.channel_id === param["channel"]) &&
                    (!param["sector"] || ticket?.sector_id === param["sector"]) &&
                    (!param["dateStart"] || (dateCheck >= dateStart && dateCheck <= dateEnd))) {

                    setAux((prevState) => {
                        let newAux = [];
                        if (ticket?.bold) {
                            let is_new = true;
                            if (prevState) {
                                newAux = prevState.map((item) => {
                                    if (item && (item.id === ticket.id) && item.bold === true) {
                                        item.bold = ticket.bold;
                                        item.status_id = ticket.status_id;
                                        item.last_message = ticket.last_message;
                                        is_new = false;
                                    } else if (item && (item.id === ticket.id) && item.bold === false) {
                                        item.bold = ticket.bold;
                                        item.status_id = ticket.status_id;
                                        item.last_message = ticket.last_message;
                                        is_new = true;
                                    }
                                    return item;
                                });

                                if (is_new && ticket.status_id !== TicketStatus.Resolved && ticket.updated_level2_at) {
                                    // NotificationSoundConst.playSound();
                                    
                                    if (order === OrderBy.asc) {
                                        const concatArray = newAux.concat([ticket]).filter((item) => {
                                            return item && (item?.status_id !== TicketStatus.Resolved)
                                        });
                                        return concatArray;
                                    } else {
                                        // adiciona no inicio da listagem
                                        return [ticket, ...newAux].filter((item) => {
                                            return item && (item?.status_id !== TicketStatus.Resolved)
                                        });
                                    }


                                    // atualiza total de tickets                  
                                } else {
                                    return prevState.filter((item) => {
                                        return item && (item?.status_id !== TicketStatus.Resolved)
                                    });
                                }
                            } else {
                                return newAux.filter((item) => {
                                    return item && (item?.status_id !== TicketStatus.Resolved)
                                });
                            }
                        } else {
                            //se o bold for removido atualiza a lista

                            if (prevState) {
                                newAux = prevState.map((item) => {
                                    if (item && (item?.id === ticket?.id)) {
                                        item.status_id = ticket.status_id;
                                        item.who_is_viewing = ticket.who_is_viewing;
                                        item.last_message = ticket.last_message;
                                    }

                                    return item;
                                });
                                return newAux.filter((item) => {
                                    return item && (item?.status_id !== TicketStatus.Resolved)
                                });
                            } else {
                                return newAux.filter((item) => {
                                    return item && (item?.status_id !== TicketStatus.Resolved)
                                });
                            }
                        }
                    });
                    // setCurrentStatusPage("updating SOKET" + Date.now().toString());
                }
                return param;
            });
            return order;
        })
    }

    const onSocketDeletetList = async (ticket_ids: any) => {
        setAux((prevState) => {
            if (prevState) {
                //remove o ticket caso ele esteja na lista
                let newAux = prevState.filter((item: { id: string }) => {
                    return item && !ticket_ids.includes(item.id);
                })
                // adiciona o ticket no começo da listagem
                setcurrentFilterDatas([...newAux]);

                return [...newAux];
            } else {
                return [];
            }
        });
    }

    // get tickets to all, agent tickets, finalized or deleted
    const getAllTickets = async (params: { channel?: string; bot?: string; employee?: string; status?: string; date?: string; } & { limit: number; page: number; }, signal: AbortSignal) => {
        const headers = UserService.getHeaders()

        // assuming that all tickets are initially
        let urlGetTickets = "/ticket";

        let filterParams = params;
        // finalized or deleted tickets
        if (props.currentViewFilter === TicketFilterType.Finalized || props.currentViewFilter === TicketFilterType.Deleted) {
            urlGetTickets += "/historic?level=1";
            if (props.currentViewFilter === TicketFilterType.Deleted) {
                filterParams = Object.assign({ deleted: true }, filterParams);
            }
        }

        await AppRequesterConst.Get(
            urlGetTickets, { headers, params: filterParams, signal },
            (response: Object) => { },
            (data: GetAllTicketsInterface | TicketsHistoryInterface) => {
                if (data.status === 200 && data.data.tickets.length > 0) {
                    setAux((state) => {

                        if (state.length > 0 && state.length >= 50) {
                            return listValidator(state, data.data.tickets);
                        } else {
                            setcurrentFilterDatas(data.data.tickets);
                            return data.data.tickets;
                        }
                    });
                } else if (aux.length === 0) {
                    setcurrentFilterDatas(undefined);
                }

            },
            (error: Object) => { }, navigate, dispatch, setIsLoading, { values: values }
        );
    }

    const getTicket = async (params: { id: string; }, signal: any) => {
        const headers = UserService.getHeaders()
        const options = Object.keys(signal).length !== 0 ? { headers, signal } : { headers };

        await AppRequesterConst.Get(
            '/ticket/' + params?.id, options,
            (response: Object) => { },
            (data: TicketsHistoryInterface | GetAllTicketsInterface) => {

                if (data.status === 200) {
                    if (data.data.tickets[0].id !== (currentTicket && currentTicket.id)) {
                        setSelected_ccEmails((data.data.tickets[0] && data.data.tickets[0].cc_list) ? data.data.tickets[0].cc_list : null);
                        setSelected_ccoEmails((data.data.tickets[0] && data.data.tickets[0].cco_list) ? data.data.tickets[0].cco_list : null);
                    }

                    selectCurrentTicket(data.data.tickets[0]);

                    if (data.data.tickets[0].who_is_viewing !== null && data.data.tickets[0].who_is_viewing !== `${values.user.name} ${(values.user.lastname ? values.user.lastname : "")}`) {
                        setAux(aux => {
                            let newAux = [];
                            if (aux) {
                                newAux = aux.map((item) => {
                                    if (currentTicket && item && (item.id === currentTicket.id)) {
                                        item.who_is_viewing = null;
                                    }

                                    return item;
                                });
                                return newAux;
                            }
                            return aux;
                        })
                    } else if (data.data.tickets[0].who_is_viewing === null) {
                        setAux(aux => {
                            let newAux = [];
                            if (aux) {
                                newAux = aux.map((item) => {
                                    if (currentTicket && item && (item.id === currentTicket.id)) {
                                        if (item.who_is_viewing === `${values.user.name} ${(values.user.lastname ? values.user.lastname : "")}`)
                                            item.who_is_viewing = null;
                                    }

                                    if (item && (item.id === data.data.tickets[0].id)) {
                                        item.who_is_viewing = `${values.user.name} ${(values.user.lastname ? values.user.lastname : "")}`
                                    }
                                    return item;
                                });
                                return newAux;
                            }
                            return aux;
                        })
                    }

                    setCurrentStatus(status => {
                        if (status !== TicketStatus.Resolved)
                            setDisableSaveButton(false);

                        return status
                    })


                }
            },
            (error: Object) => { },
            navigate, dispatch, setIsLoading, { values: values }
        );
    }

    const getInformations = async (controller: any) => {

        const signal = controller.signal;

        // const headers = {
        //     "Content-Type": "application/json; charset=utf-8",
        //     "Authorization": "Bearer " + values.token.value
        // };

        const headers = UserService.getHeaders()


        const getSector = AppRequesterConst.Get(
            '/sector/actives/', { headers, params: { companyid: values.company.id }, signal },
            (response: Object) => { },
            (data: SectorActiveInterface) => {
                if (data.data.sectors) {
                    setAllDepartments(data.data.sectors);
                }
            }, (error: Object) => { }, navigate, dispatch, setIsLoading, { values: values }
        )

        const getUsers = AppRequesterConst.Get(
            '/agent', { headers, signal },
            (response: Object) => { },
            (data: UserInterface) => {
                let auxObj2 = [];
                if (data.status === 200 && data.data.employees) {
                    let users = [agentNotItem];
                    data.data.employees.forEach((item) => {
                        users.push({ ...item, label: item.name, value: item.user_id });
                        auxObj2.push({ name: item.name + (item.lastname ? (" " + item.lastname) : ""), id: item.user_id });
                    });
                    setAgentsOptions(users);
                }

                setFollowers(auxObj2);
            },
            (error: Object) => { }, navigate, dispatch, setIsLoading, { values: values }
        );

        const getConsumers = AppRequesterConst.Get(
            '/user', { headers, signal },
            (response: Object) => { },
            (data: UserInterface) => {
                /* setConsumers(data.data.users);
                setInitialConsumers(data.data.users); */
                if (data.status === 200 && data.data.users) {
                    let users = [consumerCreateItem];
                    data.data.users.forEach((item) => {
                        users.push({ ...item, label: item.name, value: item.user_id });
                    });
                    setConsumersOptions(users);
                }
            },
            (error: Object) => { }, navigate, dispatch, setIsLoading, { values: values }


        );

        const getTags = AppRequesterConst.Get(
            `/tag?is_active=true`, { headers, signal },
            (response: Object) => { },
            (data: { data: { tags: { name: string, id: string }[] }, status: number }) => {
                let auxObj = [];
                if (data.status === 200) {
                    data.data.tags.forEach((element) => {
                        auxObj.push({ name: element.name, id: element.id });
                    });
                }

                setTags(auxObj);
            },
            (error: Object) => { }, navigate, dispatch, setIsLoading, { values: values }
        );

        return Promise.all([getSector, getUsers, getConsumers, getTags]);
    }

    const defaultInputs = () => {
        setCurrentTicket((ticket: { requester_user: { user_id: string; name: string; }; }) => {
            setConsumersOptions((consumers) => {
                if (ticket && ticket.requester_user && consumers !== undefined) {
                    let finded = consumers.find((item) => item.value === ticket.requester_user.user_id);
                    if (finded) {
                        consumers.forEach((element) => {
                            if (ticket !== null && ticket.requester_user !== null && element.value === ticket.requester_user.user_id) {
                                // selectRequester([element]);
                                setSelectedConsumers([element]);
                                setCurrentRequester_user(element);
                            }
                        });
                    } else {
                        let requesterNotInList = Object.assign(ticket.requester_user, { value: ticket.requester_user.user_id, label: ticket.requester_user.name, key: ticket.requester_user.user_id });

                        addRequesterNotInOptions(requesterNotInList);
                    }
                } else if (ticket && ticket.requester_user === null) {
                    // selectRequester([]);
                    setSelectedConsumers([]);
                    setCurrentRequester_user(null);
                }
                return consumers;
            });
            return ticket;
        });

        setAllDepartments((state: { name: string; id: string; }[]) => {
            if (state !== null) {
                if (state) {
                    let departments = [];
                    state.forEach((department: { name: string; id: string; }) => {
                        departments.push({ label: department.name, value: department.id });
                    });

                    // add current department when inactive
                    setCurrentTicket((ticket: { sector: { active: boolean, name: string, id: string } }) => {
                        if (ticket) {
                            let department = ticket.sector;
                            if (department && !department.active)
                                departments.unshift({ label: department.name, value: department.id });
                        }
                        return ticket;
                    });

                    setDepartmentsOptions(departments);
                }
            }
        });

        setCurrentTicket((ticket: { assigned_employee: { user_id: React.SetStateAction<string>; name: string; lastname: string; }; followers: any[]; tags: React.SetStateAction<any[]>; subject: React.SetStateAction<string>; sector: { id: string; name: string; }; requester_user: any }) => {
            setCurrentTags([]);
            setCurrentFollowers([]);

            if (ticket !== null) {
                if (ticket.assigned_employee) {
                    setAgentsOptions((agents) => {
                        let findTicketAgent = agents.find((item) => item.value === ticket.assigned_employee.user_id);
                        if (agents && findTicketAgent) {
                            agents.forEach((agent) => {
                                if (ticket.assigned_employee !== null && agent.value === ticket.assigned_employee.user_id) {
                                    selectAssigned([agent]);
                                    setCurrentAssigned_employee_user_id(ticket.assigned_employee.user_id);
                                }
                            });
                        } else {
                            let assignedNotInList = Object.assign(ticket.assigned_employee, { value: ticket.assigned_employee.user_id, label: ticket.assigned_employee.name + (ticket.assigned_employee.lastname ? (" " + ticket.assigned_employee.lastname) : "") });
                            selectAssigned([assignedNotInList]);
                            setCurrentAssigned_employee_user_id(ticket.assigned_employee.user_id);
                        }
                        return agents;
                    });
                } else if (ticket && ticket.assigned_employee === null) {
                    setSelectedAgents([]);
                    setCurrentAssigned_employee_user_id("");
                }

                if (ticket.requester_user) {
                    setConsumersOptions((consumers) => {
                        // if (ticket && ticket.requester_user && consumers !== undefined) {
                        let finded = consumers.find((item) => item.value === ticket.requester_user.user_id);
                        if (finded) {
                            consumers.forEach((element) => {
                                if (ticket !== null && ticket.requester_user !== null && element.value === ticket.requester_user.user_id) {
                                    // setSelectedConsumers([element]);
                                    selectRequester([element], true);
                                    setCurrentRequester_user(element);
                                }
                            });
                        } else {
                            let requesterNotInList = Object.assign(ticket.requester_user, { value: ticket.requester_user.user_id, label: ticket.requester_user.name, key: ticket.requester_user.user_id });

                            addRequesterNotInOptions(requesterNotInList);

                            /* selectRequester([requesterNotInList]);
                            setCurrentRequester_user(requesterNotInList); */
                        }
                        // } 
                        return consumers;
                    });
                } else if (ticket && ticket.requester_user === null) {
                    // selectRequester([]);
                    setSelectedConsumers([]);
                    setCurrentRequester_user(null);
                }

                if (ticket.followers != null) {
                    if (ticket.followers[0] != null) {

                        let auxObj = [];
                        ticket.followers.forEach((element) => {
                            auxObj.push({ name: element.name + (element.lastname ? (" " + element.lastname) : ""), id: element.user_id });
                        });
                        setCurrentFollowers(auxObj);
                    }
                } else {
                    setCurrentFollowers([]);
                }

                if (ticket.tags && ticket.tags.length > 0) {
                    setCurrentTags(ticket.tags);
                } else {
                    setCurrentTags([]);
                }

                if (ticket.subject != null) {
                    setCurrentSubject(ticket.subject);
                }

                if (ticket.sector != null) {
                    setDepartmentsOptions(state2 => {
                        let findedDepartment = state2.find((item) => item.value === ticket.sector.id && item.label === ticket.sector.name);

                        if (findedDepartment) {
                            setSelectedDepartments(state2.filter(elem => elem.label === ticket.sector.name && elem.value === ticket.sector.id));
                        }
                        return state2;
                    })

                } else {
                    setSelectedDepartments([]);
                }
            }
            return ticket;
        });

        constsApi.status.forEach((element) => {
            setCurrentTicket((state: { status_id: string; }) => {
                if (state !== null) {
                    if (element.id === state.status_id) {
                        /* document.getElementById("select_status").innerHTML = "<span style='align-items:center; display:flex;'>" + element.name + "</span>";
                        document.getElementById("select_status").className = "dropdownButtonTicketsActive dropdown-toggle btn btn-primary"; */
                        selectStatus([{ value: element.id, label: element.name, key: element.id }]);
                        // setCurrentStatus(element.name);
                    }
                }
                return state;
            });
        });
    }

    const updateTicket = async () => {
        setDisableSaveButton(true);

        if (!currentSubject) {
            dispatch(setShowAlertFeedback({ visibility: true, message: t("home_tickets.home_create_ticket.alert_feedback.ticket_without_subject"), signalIcon: false }));
            setDisableSaveButton(false);
        } else {
            /******set next ticket id********/
            if (aux.length >= 2) {
                let indexNextTicket: number;
                aux.forEach((ticketAux, index) => {
                    if (currentTicket.id === ticketAux.id) {
                        indexNextTicket = index + 1;
                    }
                })
                // current ticket == last ticket
                if (indexNextTicket === aux.length && aux.length >= 2) {
                    // next ticket == back ticket
                    indexNextTicket = aux.length - 2;
                }
                if (indexNextTicket >= 0 && indexNextTicket < aux.length) {
                    setNextTicket(aux[indexNextTicket]);
                } else {
                    setNextTicket(0);
                }
            } else {
                setNextTicket(null);
            }
            setCurrentTicketIsUpdated(true);
            /******set next ticket id********/

            let datasConverted = { status: '' };

            constsApi.status.forEach((element) => {
                if (element.id === currentStatus) {
                    datasConverted.status = element.id;
                }
            });

            const JsonSend = {};

            if (currentTags.length !== 0) {
                if (currentTags[0] !== undefined) {
                    let auxArray = [];
                    currentTags.forEach((element) => {
                        auxArray.push(element.id);
                    });
                    JsonSend['tags'] = auxArray;
                }
            }

            const headers = UserService.getHeaders()

            await AppRequesterConst.Patch(
                'ticket/' + currentTicket.id + '/tags', JsonSend, { headers },
                (response: Object) => { },
                (data) => {
                    dispatch(setShowAlertFeedback({ message: t("home_tickets.view_ticket.alert_feedback.success"), visibility: true, signalIcon: true }));

                    if (props.resetCloseCreateTicketState)
                        props.resetCloseCreateTicketState();
                },
                (error: { response: { status: number, data: { message: string } } }) => {
                    if (error.response !== undefined) {
                        if (error.response.status === 401) {

                        }
                        if (error.response.status === 403) {

                        }
                        if (error.response.status === 422) {
                            dispatch(setShowAlertFeedback({ message: t("home_tickets.view_ticket.alert_feedback.without_subject"), visibility: true, signalIcon: false }));
                        }
                        if (error.response.status === 500) {
                            dispatch(setShowAlertFeedback({ message: error.response.data.message, visibility: true, signalIcon: false }));
                        }
                    }
                },
                navigate, dispatch, setIsLoading,
                () => {
                    setAux([]);

                    // if (document.getElementById("listOfTickets") !== null) {
                    //     document.getElementById("listOfTickets").scrollTop = 0;
                    // }

                    SetCurrentPage((state) => {
                        setCurrentStatusPage("updating" + Date.now().toString());
                        return 1;
                    });

                    if (JsonSend['status_id'] === TicketStatus.Resolved) {
                        dispatch(setUpdateButtonNumberOfTickets(true));
                    }
                },
            )

            // await AppRequesterConst.Put(
            //     '/ticket', JsonSend, { headers },
            //     (response: Object) => { },
            //     (data: GetAllTicketsInterface) => {
            //         dispatch(setShowAlertFeedback({ message: t("home_tickets.view_ticket.alert_feedback.success"), visibility: true, signalIcon: true }));

            //         if (props.resetCloseCreateTicketState)
            //             props.resetCloseCreateTicketState();
            //     },
            //     (error: { response: { status: number, data: { message: string } } }) => {
            //         if (error.response !== undefined) {
            //             if (error.response.status === 401) {

            //             }
            //             if (error.response.status === 403) {

            //             }
            //             if (error.response.status === 422) {
            //                 dispatch(setShowAlertFeedback({ message: t("home_tickets.view_ticket.alert_feedback.without_subject"), visibility: true, signalIcon: false }));
            //             }
            //             if (error.response.status === 500) {
            //                 dispatch(setShowAlertFeedback({ message: error.response.data.message, visibility: true, signalIcon: false }));
            //             }
            //         }
            //     },
            //     navigate, dispatch, setIsLoading,
            //     () => {
            //         setAux([]);

            //         if (document.getElementById("listOfTickets") !== null) {
            //             document.getElementById("listOfTickets").scrollTop = 0;
            //         }

            //         SetCurrentPage((state) => {
            //             setCurrentStatusPage("updating" + Date.now().toString());
            //             return 1;
            //         });

            //         if (JsonSend['status_id'] === TicketStatus.Resolvido) {
            //             dispatch(setUpdateButtonNumberOfTickets(true));
            //         }
            //     },
            // );

            await AppRequesterConst.Get(
                '/ticket/' + currentTicket?.id.toString(), { headers },
                (response: Object) => { },
                (data: GetAllTicketsInterface) => {
                    if (data.status === 200 && data.data.tickets)
                        setCurrentTicket(data.data.tickets[0]);
                },
                (error: Object) => { },
                navigate, dispatch, setIsLoading,
                {
                    values: values,
                    finallyFunction: () => {
                        setDisableSaveButton(false);
                        if (currentStatus === TicketStatus.Resolved)
                            setDisableSaveButton(true);
                    }
                }
            );

            // close update ticket status on finalized tickets view
            if (currentStatus !== TicketStatus.Resolved && props.currentViewFilter && props.currentViewFilter === TicketFilterType.Finalized) {
                props.saveFinalizedClick();
            }

            setDisableSaveButton(false);
        }
    }

    const deleteSelectedTicket = async () => {

        // const headers = {
        //     "Content-Type": "application/json; charset=utf-8",
        //     "Authorization": "Bearer " + values.token.value
        // };

        const headers = UserService.getHeaders()

        const JsonSend = {
            "id": currentTicket?.id,
        };


        await AppRequesterConst.Delete(
            '/ticket/' + JsonSend['id'], { headers },
            (response: Object) => { },
            (data: GetAllTicketsInterface) => {
                dispatch(setShowAlertFeedback({ message: t("home_tickets.view_ticket.alert_feedback.success_deleted"), visibility: true, signalIcon: true }));

                if (props.resetCloseCreateTicketState)
                    props.resetCloseCreateTicketState();
            },
            (error: Object) => { },
            navigate, dispatch, setIsLoading,
            () => {
                if (aux.length > 1) {
                    setAux([]);
                    SetCurrentPage(1);
                    setCurrentStatusPage("deleting" + Date.now().toString());
                }

                dispatch(setUpdateButtonNumberOfTickets(true));
            },
        );
        if (aux.length >= 2) {
            let indexNextTicket: number; //= aux.indexOf(currentTicket) + 1;
            aux.forEach((ticketAux, index) => {
                if (currentTicket.id === ticketAux.id) {
                    indexNextTicket = index + 1;
                }
            })
            if (indexNextTicket === aux.length && aux.length >= 2) {
                indexNextTicket = aux.length - 2;
            }

            if (indexNextTicket >= 0 && indexNextTicket < aux.length) {
                setNextTicket(aux[indexNextTicket]);
            } else {
                setNextTicket(0);
            }

            setCurrentTicket(aux[indexNextTicket]);

            getTicket({ id: aux[indexNextTicket].id }, {}).finally(() => {
                defaultInputs();
            });
        } else {
            props.homeTicketsResetReturn();
        }
    }

    const showEditConsumerButton = async (requesterId: string) => {
        const headers = UserService.getHeaders()

        await AppRequesterConst.Get(
            '/consumer/' + requesterId, { headers },
            (response: Object) => { },
            (data: ConsumerInterface) => {
                if (data.status === 200 && data.data.consumers != null) {
                    setCurrentConsumersData(data.data.consumers[0]);
                } else {
                    setCurrentConsumersData(null);
                }
                dispatch(setShowEditConsumersModal(true));
            },
            (error: Object) => { },
            navigate, dispatch, setIsLoading,
            { values: values }
        );
    }

    const addRequesterNotInOptions = async (selected: { user_id: string; value: string; key: string; }) => {
        const headers = UserService.getHeaders();

        let user: { name: string; lastname: string; user_id: string; key?: string; id?: string; confirmation_code?: string; };
        await AppRequesterConst.Get(
            '/user/' + selected.user_id, { headers },
            (response: Object) => { },
            (data: UserInterface) => {
                if (data.status === 200 && data.data.users != null) {
                    user = data.data.users[0];
                    let requester = Object.assign(user, { value: user.user_id, label: user.name, key: user.user_id });

                    selectRequester([requester], true);
                    setCurrentRequester_user(requester);
                }

            },
            (error: Object) => { },
            navigate, dispatch, setIsLoading, { values: values }
        );
    }

    // const multiselectFilterDepartments = async (options: Option[], filter: Object) => {
    //     const headers = UserService.getHeaders()

    //     const params = {
    //         "search": filter,
    //         "companyid": values.company.id
    //     }

    //     if (!filter) {
    //         return options;
    //     } else {
    //         let departments = [];
    //         await AppRequesterConst.Get(
    //             '/sector/actives/', { headers, params: params },
    //             (response: Object) => { },
    //             (data: SectorActiveInterface) => {
    //                 if (data.status === 200 && data.data.sectors) {
    //                     data.data.sectors.forEach((item: { name: string; id: string; }) => {
    //                         departments.push({ ...item, label: item.name, value: item.id });
    //                     });
    //                 }
    //             }, (error: Object) => { }, navigate, dispatch, () => { }, { values: values }
    //         );
    //         return departments;
    //     }
    // }

    const multiselectFilterUsers = async (options: Option[], filter: Object) => {
        const headers = UserService.getHeaders()

        const params = {
            "search": filter,
        }

        if (!filter) {
            return options;
        } else {
            let users = [consumerCreateItem];
            await AppRequesterConst.Get(
                '/user', { headers, params: params },
                (response: Object) => { },
                (data: UserInterface) => {
                    if (data.status === 200 && data.data.users) {
                        data.data.users.forEach((item: any) => {
                            users.push({ ...item, label: item.name, value: item.user_id });
                        });
                    }
                },
                (error: Object) => { }, navigate, dispatch, null, { values: values }
            );

            return users;
        }
    }

    const getLastUpdatedDate = (currentTicket) => {
        let date = new Date();

        if (currentTicket !== null) {
            if (currentTicket.deleted_at !== null) {
                date = new Date(currentTicket?.deleted_at);
            } else if (currentTicket?.finished_at !== null) {
                date = new Date(currentTicket.finished_at);
            } else if (currentTicket?.ended_at !== null) {
                date = new Date(currentTicket.ended_at);
            } else if (currentTicket?.updated_level2_at !== null) {
                date = new Date(currentTicket?.updated_level2_at);
            } else if (currentTicket?.updated_at !== null) {
                date = new Date(currentTicket?.updated_at);
            } else {
                date = new Date(currentTicket?.created_at);
            }
        }

        setCurrentLastUpdatedDate(date);
    }

    const resetOverlayIdOnScroll = () => {
        if (overlayId !== null)
            setOverlayId(null)
    }

    const blockTicket = (values) => {

        if ((values.user.profile_id === constsApi.profile[0].id) || (values.user.profile_id === constsApi.profile[2].id)) {
            setOnlyRead(true);
        } else {
            for (let index = 0; index < inputs.length; index++) {
                if (inputs[index]) {
                    if (inputs[index].current) {
                        inputs[index].current.disabled = true;
                    }
                }
            }
            setBlockBool(true);

            dispatch(setShowConfirmationModal({
                visibility: true,
                text: {
                    title: t("home_tickets.view_ticket.confirmation.blocked_ticket.title"),
                    body: t("home_tickets.view_ticket.confirmation.blocked_ticket.body"),
                    buttonConfirmationText: t("home_tickets.view_ticket.confirmation.blocked_ticket.confirm")
                },
                functionConfirmation: () => { }
            }));
        }
    }

    const getAllTicketsToPagination = (params: { channel?: string; bot?: string; employee?: string; status?: string; date?: string; } & { limit: number; page: number; }, forcedUpdate: boolean, controller: AbortController) => {
        const signal = controller.signal;

        if (props.getList === undefined) {
            getAllTickets(params, signal).finally(() => {
                setIsLoading(false);
                if (currentTicket === null) {
                    if (props.currentTicket === null) {
                        setAux((state) => {
                            getTicket({ id: state[0].id }, signal).finally(() => {
                                defaultInputs();
                            });

                            return state;
                        })

                    } else {
                        getTicket({ id: props.currentTicket }, signal).finally(() => {
                            defaultInputs();
                        });
                    }

                } else {
                    if (forcedUpdate)
                        setForcedUpdate(false);
                }
                // dispatch(setUpdateButtonNumberOfTickets(true));
            });
        } else {
            props.getList(params, setcurrentFilterDatas, aux, setAux).finally(() => {
                setIsLoading(false);
                if (currentTicket === null) {
                    if (props.currentTicket === null) {
                        setAux((state) => {
                            getTicket({ id: state[0].id }, signal).finally(() => {
                                defaultInputs();
                            });

                            return state;
                        })

                    } else {
                        getTicket({ id: props.currentTicket }, signal).finally(() => {
                            defaultInputs();
                        });
                    }

                } else {
                    if (forcedUpdate)
                        setForcedUpdate(false);
                }
            });
        }
    }

    const listValidator = (state: any[], tickets: Array<TicketInterface>) => {
        let aux2 = [];

        for (let i = 0; i < tickets.length; i++) {
            let validador = true;
            for (let j = 0; j < state.length; j++) {
                if ((state[j].id === tickets[i].id)) {
                    validador = false;
                }
            }

            if (validador) {
                aux2.push(tickets[i]);
            }
        }

        let concatArrays = state.concat(aux2);
        setcurrentFilterDatas(concatArrays);
        return concatArrays;
    }

    const onClickButtonEdit = () => {
        showEditConsumerButton(currentRequester_user.user_id);
    }

    const selectCurrentTicket = (itemTicket: ItemTicketInterface) => {

        let oldTicket = currentTicket;
        let newTicket = itemTicket;

        if (oldTicket && newTicket && oldTicket.id !== newTicket.id) {
            dispatch(setCleanMessageEditor(true));
        }

        setCurrentTicket(newTicket);

        defaultInputs();

        if (props.resetCloseCreateTicketState)
            props.resetCloseCreateTicketState();
    }

    const isbot = () => {
        if (currentTicket) {
            if (currentTicket.is_bot) {
                return <>{t("history_tickets_chatbot.yes")}</>;
            } else {
                return <>{t("history_tickets_chatbot.no")}</>;
            }
        }

        return <></>;
    }

    const confirmationDeleteTicket = () => {
        if (currentTicket) {
            dispatch(setShowConfirmationModal({
                visibility: true,
                text: {
                    "title": t("home_tickets.view_ticket.confirmation.delete_ticket.title"),
                    "body": t("home_tickets.view_ticket.confirmation.delete_ticket.body"),
                    "id": t("home_tickets.view_ticket.confirmation.delete_ticket.id", { ticket_reference_id: currentTicket.ticket_reference_id }),
                    "buttonReturnText": t("home_tickets.view_ticket.confirmation.delete_ticket.buttonReturnText")
                },
                functionConfirmation: deleteSelectedTicket
            }));
        }
    }

    const callbackModalCustomers = (userIdCustomer: string) => {
        getInformations({}).then(() => {
            setConsumersOptions((state) => {
                state.map((element) => {
                    if (element.value === userIdCustomer) {
                        selectRequester([element], true);
                    }
                });
                return state;
            });
        });

        setAux([]);
        if (document.getElementById("listOfTickets") !== null) {
            document.getElementById("listOfTickets").scrollTop = 0;
        }

        SetCurrentPage(() => {
            setCurrentStatusPage("updating" + Date.now().toString());
            return 1;
        });
    }


    const selectRequester = (value: string | any[], editOrCreate?: boolean) => {
        if (value.length > 0) {
            setCurrentRequester_user(value[value.length - 1]);

            if (editOrCreate)
                setSelectedConsumers([value[value.length - 1]]);

            // addUserNotInOptions(value[value.length - 1], consumersOptions, setConsumersOptions);

            if (value[value.length - 1].profile_id === null) {
                Utils.changeStyleElementById('editConsumerButtonId', 'display', 'inline');
            } else {
                Utils.changeStyleElementById('editConsumerButtonId', 'display', 'none');
            }
        } else {
            // setSelectedConsumers([]);
            setCurrentRequester_user(null);
        }
    }

    const filteringTickets = (auxObj: {}, action: string) => {
        SetCurrentPage(1);
        setAux([]);

        auxObj = {};

        if (action === "clean") {
            SetCurrentFilterSearch("");
        } else if (currentFilterSearch !== null && currentFilterSearch !== "") {
            auxObj["search"] = currentFilterSearch;
        }

        setCurrentStatusPage("searching");

        if (currentStatusPage === "searching") {
            setCurrentStatusPage("");
        }

        SetCurrentParams(auxObj);
    }

    const addItemNotInOptions = (selected: { value: string, key: string }, list, setList: Function) => {
        setList((state: Array<Object>) => {
            if (state && !state.find((item: { value: string }) => item.value === selected.value) && selected.key !== "NA") {
                state.splice(1, 0, selected);
            }
            return state;
        });
    }

    const selectStatus = (value: string | any[]) => {
        if (value.length > 0) {
            setSelectedStatus([value[value.length - 1]]);
            setCurrentStatus(value[value.length - 1].value);
        } else {
            setSelectedStatus([]);
            setCurrentStatus(TicketStatus.New);
        }
    }

    const selectAssigned = (value: string | any[]) => {
        if (value.length > 0) {
            // not assigned option selected
            if (value[value.length - 1].value === "NA") {
                setSelectedAgents([]);
                setCurrentAssigned_employee_user_id("");
            } else {
                setSelectedAgents([value[value.length - 1]]);
                setCurrentAssigned_employee_user_id(value[value.length - 1].value);
                addItemNotInOptions(value[value.length - 1], agentsOptions, setAgentsOptions);
            }
        } else {
            setSelectedAgents([]);
            setCurrentAssigned_employee_user_id("");
        }
    }

    const onSelectRequester = (value: string | any[]) => {
        selectRequester(value);
        props.setFilledEditTicket(true);
    }

    const onSelectDepartment = (value: string | any[]) => {
        // selectDepartment(value);
        props.setFilledEditTicket(true);
    }

    const selectTags = (value) => {
        setCurrentTags(value);
    }

    return <ViewService
        values={values}
        callbackModalCustomers={callbackModalCustomers}
        currentConsumersData={currentConsumersData}
        props={props}
        t={t}
        setAux={setAux}
        SetCurrentPage={SetCurrentPage}
        setForcedUpdate={setForcedUpdate}
        resetOverlayIdOnScroll={resetOverlayIdOnScroll}
        filteringTickets={filteringTickets}
        SetCurrentFilterSearch={SetCurrentFilterSearch}
        currentFilterSearch={currentFilterSearch}
        currentFilterDatas={currentFilterDatas}
        updateListfunction={updateListfunction}
        currentHasMoreInformation={currentHasMoreInformation}
        currentTicket={currentTicket}
        getTicket={getTicket}
        onlyRead={onlyRead}
        currentRequester_user={currentRequester_user}
        blockBool={blockBool}
        setCurrentStatusPage={setCurrentStatusPage}
        disableEditTicket={disableEditTicket}
        defaultInputs={defaultInputs}
        confirmationDeleteTicket={confirmationDeleteTicket}
        onClickButtonEdit={onClickButtonEdit}
        setCurrentTicket={setCurrentTicket}
        consumersOptions={consumersOptions}
        setConsumersOptions={setConsumersOptions}
        selectedConsumers={selectedConsumers}
        setSelectedConsumers={setSelectedConsumers}
        multiselectFilterUsers={multiselectFilterUsers}
        customCreateRequesterButtonClick={customCreateRequesterButtonClick}
        onSelectRequester={onSelectRequester}
        currentTags={currentTags}
        disableSaveButton={disableSaveButton}
        updateTicket={updateTicket}
        isbot={isbot}
        currentLastUpdatedDate={currentLastUpdatedDate}
        tags={tags}
        selectTags={selectTags}
    />
}

export default ViewServiceController