import { FC, useEffect, useState } from "react";
import IListOfCustomFields from "./indexModel";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { AppRequesterController } from "../../../../../services/appRequester/appRequesterController";
import { Button, Popover } from "react-bootstrap";
import UserService from "../../../../../services/user-service";
import ListOfCustomFields from "./index";
import { useTranslation } from "react-i18next";
import { setShowAlertFeedback, setShowConfirmationModal } from "../../../../../store/internal";
import { verifyCode } from "../../../../../services/codeCxpressInterface";

const AppRequesterConst = new AppRequesterController()

const ListOfCustomFieldsController: FC<IListOfCustomFields> = (props) => {
    const [currentFieldId, /*setCurrentFieldId*/] = useState('')
    const [currentFieldName, setCurrentFieldName] = useState('')
    const [showCustomFieldModalEdit, setShowCustomFieldModalEdit] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [idCustom, setIdCustom] = useState("")
    const [customData, setCustomData] = useState([])
    const [customEdit, setCustomEdit] = useState([])

    const { t } = useTranslation()

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const handleCloseCustomFieldModalEdit = () => setShowCustomFieldModalEdit(false)
    const handleShowCustomFieldModalEdit = () => setShowCustomFieldModalEdit(true)

    const AppRequesterConst = new AppRequesterController()

    // useEffect(() => {
    //     if(showCustomFieldModalEdit){
    //         getCustomId()
    //     }
    // }, [setIdCustom, idCustom, showCustomFieldModalEdit])

    useEffect(() => {
        if(setCurrentCustomInfo && idCustom !== "" && showCustomFieldModalEdit){
            getCustomId()
        }
    }, [setIdCustom, idCustom, showCustomFieldModalEdit])
    
    useEffect(() => {
        if (props?.datas) {
            setCustomData(props.datas)
        }
    }, [props.datas]);
    
    const setCurrentCustomInfo = (e: { currentTarget: { id: string; }; }) => {
        const [name, id] = e.currentTarget.id.split("*ID*");
        setCurrentFieldName(name);
        setIdCustom(id);
    }

    const getCustomId = async () => {
        const headers = UserService.getHeaders()

        await AppRequesterConst.Get(
            `/custom-fields/${idCustom}`, { headers },
            (response: Object) => { },
            (data: any) => {
                if (data.status === 200) {
                    setCustomEdit(data.data.custom_fields[0])
                }
            },
            (error: { response: { status: number } }) => {
                if (error.response.status === 400) {

                }
                if (error.response.status === 401) {

                }
            }, navigate, dispatch, setIsLoading, {}
        )
    }

    const deleteCustomField = async () => {
        const headers = UserService.getHeaders();

        await AppRequesterConst.Delete(
            `/custom-fields/${idCustom}`, {headers},
            (response: Object) => { },
            (data: Object) => {
                dispatch(setShowAlertFeedback({ message: t('configurations_consumers.list_of_custom_fields.custom_field_deleted'), visibility: true, signalIcon: true }));
                props.updateListfunction();
                props.callBackModalCustomField();
            },
            (error: { response: { status: number; data: { code_cxpress: number } }; }) => {
                if (error.response.data.code_cxpress === 3309) {
                    dispatch(setShowAlertFeedback({ message: t('configurations_ticket.list_of_custom_fields.custom_field_used_in_triage'), visibility: true, signalIcon: false }));
                } else { 
                    dispatch(setShowAlertFeedback({ message: verifyCode(error.response.data.code_cxpress, t), visibility: true, signalIcon: false }));
                }
                setIsLoading(false);
            }, navigate, dispatch, setIsLoading
        );
    }

    const confirmDeleteCustomField = () => {
        let aux = [];
        aux.push({ name: currentFieldName, id: idCustom });
        const body1 = t('configurations_consumers.list_of_custom_fields.delete_custom_field.body1');
        const body2 = t('configurations_consumers.list_of_custom_fields.delete_custom_field.body2');
        const name = <strong>{currentFieldName}</strong>;
        dispatch(setShowConfirmationModal({
            visibility: true,
            text: {
                title: `${t('configurations_consumers.list_of_custom_fields.delete_custom_field.title')}`,
                body: <>{body1} {name} {body2}</>,
                buttonReturnText: `${t('configurations_consumers.list_of_custom_fields.delete_custom_field.cancel')}`
            },
            functionConfirmation: () => deleteCustomField()
        }));
    }

    const popover = (
        <Popover id={currentFieldId} style={{ zIndex: '500', backgroundColor: '#e6e6e6', border: 'none', color: '#40484E', font: 'var(--px15_19-font-Roboto)' }}>
            <Popover.Body className="nopadding">
                <Button onClick={handleShowCustomFieldModalEdit} className="m-1" style={{ backgroundColor: 'transparent', border: 'none', color: 'black', fontSize: 'inherit' }}>{t('configurations_consumers.list_of_custom_fields.edit_custom_field')}</Button>
                <div style={{ backgroundColor: '#707070', opacity: '0.5', height: '1px', width: '100%' }}></div>
                <Button onClick={confirmDeleteCustomField} className="m-1" style={{ backgroundColor: 'transparent', border: 'none', color: 'black', fontSize: 'inherit' }}>{t('configurations_consumers.list_of_custom_fields.custom_field_delete')}</Button>
            </Popover.Body>
        </Popover>
    );

    return <ListOfCustomFields
        t={t}
        currentPage={props.currentPage}
        datas={props.datas}
        isLoading={isLoading}
        showCustomFieldModalEdit={showCustomFieldModalEdit}
        handleCloseCustomFieldModalEdit={handleCloseCustomFieldModalEdit}
        customEdit={customEdit}
        callBackModalCustomField={props.callBackModalCustomField}
        updateListfunction={props.updateListfunction}
        HasMoreInformation={props.HasMoreInformation}
        setCurrentCustomInfo={setCurrentCustomInfo}
        popover={popover}
        showCreateCustomField={props.showCreateCustomField}
        customData={customData}
    />
}

export default ListOfCustomFieldsController;