import allStyles from './styles';
import {
  Modal,
  Row,
  Container,
  Col,
  Button,
  Form,
  InputGroup,
  FormControl,
} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Formik } from 'formik';
import { 
  setShowAlertFeedback 
} from '../../../../store/internal'
import { verifyCode } from '../../../../services/codeCxpressInterface';
import SelectComponentController from '../selectComponent/indexController';

const ModalRegisterEmail = ({
  modalShow,
  Hide,
  options_departments,
  setOptions_departments,
  selected_departments,
  setSelected_departments,
  email,
  setEmail,
  email_isActive,
  setEmail_isActive,
  setShowfeedbackEmail,
  showfeedbackEmail,
  validated,
  setIsLoading,
  dispatch,
  navigate,
  props,
  AppRequesterConst,
  t,
  isSubmitting,
  setIsSubmitting,
}) => {
  return (
    <Modal show={modalShow} onHide={Hide} aria-labelledby="" size="sm" centered={true} >
      <Modal.Header closeButton style={{ marginLeft: "6%", marginTop: "1%", marginBottom: "-1%" }}>
        <Modal.Title style={allStyles.textBlack}>
          {t("home_channels.modals.register_email_modal.modal_title")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ marginLeft: "4%", marginRight: "4%", marginBottom: "2%" }}>
        <Container>
          <Formik
            initialValues={{email: '',  }}
            validate={values => {
              let errors = {};
              setEmail(values.email);

              if (!values.email) {
                errors["email"] = t("home_channels.modals.register_email_modal.modal_email_error");
                setShowfeedbackEmail(true);
              }
              else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,10}$/i.test(values.email) && values.email) {
                errors["email"] = t("home_channels.modals.register_email_modal.model_invalid_email");
                setShowfeedbackEmail(true);
              } else {
                setShowfeedbackEmail(false);
              }

              return errors;
            }}

            onSubmit={async () => {
              let JsonSend_sector = {
                "email": email,
                "active": email_isActive
              }
              if (selected_departments && selected_departments.length >= 1) {
                JsonSend_sector["sector_id"] = selected_departments[0].value;
              }

              const headers = {
                "Content-Type": "application/json; charset=utf-8",
                "Authorization": "Bearer " + props.token
              };

              await AppRequesterConst.Post(
                '/company/email', JsonSend_sector, { headers },
                (response: Object) => {
                  return response;
                },
                (data: { data: { email: string; }; }) => {
                  dispatch(setShowAlertFeedback({ message: `${t("home_channels.modals.register_email_modal.email")} ${data.data.email}, ${t("home_channels.modals.register_email_modal.email_register_success")}`, visibility: true, signalIcon: true }));
                  props.callback(data.data);
                  Hide();
                  setIsSubmitting(true);
                },
                (error: {response:{data:{code_cxpress: number}}}) => {
                  dispatch(setShowAlertFeedback({ message: verifyCode(error.response.data.code_cxpress, t), visibility: true, signalIcon: false }));
                  setIsSubmitting(false);
                },navigate,dispatch, setIsLoading,
                () => {},
                {
                  error403: () => {
                    dispatch(setShowAlertFeedback({ message: t("home_channels.modals.register_email_modal.email_alredy_registered"), visibility: true, signalIcon: false }));
                  },
                  error422: () => {
                    dispatch(setShowAlertFeedback({ message: t("home_channels.modals.register_email_modal.unrecognized_format"), visibility: true, signalIcon: false }));
                  }
                }
              );
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,

            }) => (
              <Form onSubmit={handleSubmit} noValidate validated={validated}>
                <Row style={allStyles.textFontGlay} className=" d-flex justify-content-center">
                  <Col sm={true}>
                    <Form.Label htmlFor="email">{t("home_channels.modals.register_email_modal.email_address")}<span style={{ font: 'normal bold normal calc(0.2em + 0.7vw) Montserrat', color: 'black' }}>*</span></Form.Label>
                    <InputGroup className="mb-3">
                      <FormControl className="form-control-Default"
                        isInvalid={showfeedbackEmail}
                        placeholder={t("home_channels.modals.register_email_modal.provide_email_address")}
                        aria-label={t("home_channels.modals.register_email_modal.provide_email_address")}
                        aria-describedby={t("home_channels.modals.register_email_modal.provide_email_address")}
                        size='lg'
                        type='email'
                        id="email"
                        name="email"
                        autoComplete='email'
                        maxLength={100}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.email}
                      >
                      </FormControl>
                      <Form.Control.Feedback type="invalid" id="feedbackemail">
                        {errors.email}
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Col>
                </Row>

                <Row style={allStyles.textFontGlay} className=" d-flex justify-content-center">
                  <Col sm={true}>
                    <Form.Label htmlFor="basic-url">{t("home_channels.modals.register_email_modal.email_sector")}</Form.Label>
                    {/* <pre>{JSON.stringify(selected_departments)}</pre> */}
                    <SelectComponentController
                      className={"multiselectForm mb-3"}
                      options={options_departments}
                      setOptions={setOptions_departments}
                      selecteds={selected_departments}
                      setSelecteds={setSelected_departments}
                      singleSelect={true}
                      isFormNoFilter={true}
                      title={t("home_channels.modals.register_email_modal.select_sector_email")}
                      overrideStrings={
                        {"allItemsAreSelected": `${t("home_channels.modals.register_email_modal.selected_sector")}`,
                        "clearSearch": `${t("home_channels.modals.register_email_modal.clear_search")}`,
                        "clearSelected": `${t("home_channels.modals.register_email_modal.clear_selected")}`,
                        "noOptions": `${t("home_channels.modals.register_email_modal.no_options")}`,
                        "search": `${t("home_channels.modals.register_email_modal.search")}`,
                        "selectAll": `${t("home_channels.modals.register_email_modal.select_all")}`,
                        "selectAllFiltered": `${t("home_channels.modals.register_email_modal.select_all_filtered")}`,
                        "selectSomeItems": `${t("home_channels.modals.register_email_modal.select_some_itens")}`,
                        "create": `${t("home_channels.modals.register_email_modal.create")}`,}
                      }
                      hasCreateItem={false}
                      hasNullOption={false}
                      hasAllOption={false}
                    />
                  </Col>
                </Row>

                <Row className="d-flex justify-content-center">
                  <Col sm={true} style={allStyles.textFontGlay}>
                    <Form.Label htmlFor="basic-url">{t("home_channels.modals.register_email_modal.active_email")} &nbsp; &nbsp;</Form.Label>
                    <Form.Check name="radioIsAdministrator" label={t("home_channels.modals.register_email_modal.no")} type={'radio'} checked={!email_isActive} onChange={() => { setEmail_isActive(false) }} inline></Form.Check>
                    <Form.Check name="radioIsAdministrator" label={t("home_channels.modals.register_email_modal.yes")} type={'radio'} checked={email_isActive} onChange={() => { setEmail_isActive(true) }} inline></Form.Check>
                  </Col>
                </Row>
                <Row className="d-flex justify-content-center mt-4">
                  <Col xs={6} className="d-flex justify-content-start">
                    <Button size='lg' className="buttonWhite" style={{ width: '56%' }} onClick={() => Hide()} >{t("home_channels.modals.register_email_modal.cancel")}</Button>
                  </Col>
                  <Col xs={6} className="d-flex justify-content-end">
                    <Button size='lg' type='submit' className="buttonBlue" style={{ width: '56%' }} disabled={isSubmitting}>{t("home_channels.modals.register_email_modal.save")}</Button>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </Container>
      </Modal.Body>
    </Modal>
  );
}

export default ModalRegisterEmail;