import { useTranslation } from "react-i18next";
import { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import ModalFacebook from ".";
import { IModalEditFacebookController } from "./indexModal";
import { AppRequesterController } from "../../../../../../services/appRequester/appRequesterController";
import { getShowEditFacebookPage, setShowAlertFeedback, setShowEditFacebookPage } from "../../../../../../store/internal";
import { DataSectorActivesInterface, TokenInterface } from "../../../../../../services/requestsInterfacesModel";
import UserService from "../../../../../../services/user-service";
import { getToken } from "../../../../../../store/token";
import { getIdCompany } from "../../../../../../store/company";
import { Channels } from "../../../../../../core/enums/channels";
import { verifyCode } from "../../../../../../services/codeCxpressInterface";

const ModalEditFacebookController: FC<IModalEditFacebookController> = (props) => {

    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(false)
    const [validated] = useState(false);

    const [/*customers_phone*/, SetCustomers_phone] = useState(null as string || null);
    const [phoneBorder, /*setPhoneBorder*/] = useState('')
    const [modalShow, setModalShow] = useState(false);

    const [pageName, setPageName] = useState("");
    const [showFeedbackPageName, setShowFeebackPageName] = useState(false);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const AppRequesterConst = new AppRequesterController();

    const [selectedDepartments, setSelectedDepartments] = useState([]);
    const [optionsDepartments, setOptionsDepartments] = useState([]);

    const [isSubmitting, setIsSubmitting] = useState(false);

    const values: TokenInterface = {
        token: {
            value: useSelector(getToken)
        },
        company: {
            id: useSelector(getIdCompany),
            // cnpj: useSelector(getCNPJCompany)
        },
        /* employee: {
            id: useSelector(getIdEmployee)
        },
        user: {
            id: useSelector(getIdUser)
        }, */
        internal: {
            /* getShowConfirmationModal: useSelector(getShowConfirmationModal),
            getShowAcquirePlatformComponent: useSelector(getShowAcquirePlatformComponent).visibility, */
            getShowEditFacebookPage: useSelector(getShowEditFacebookPage)
        }
    }

    useEffect(() => {
        const controller = new AbortController();
       
        if (props.data === null) 
            hide();
        else if (props.data !== null && values.internal.getShowEditFacebookPage) {
            setModalShow(values.internal.getShowEditFacebookPage);
            getDepartments(controller);

            if (props.data?.sector)
                setSelectedDepartments([{ label: props.data.sector.name, value: props.data.sector.id }]);
            else 
                setSelectedDepartments([]);
        }

        return () => {
            controller.abort();
        }
    }, [values.internal.getShowEditFacebookPage, props.data])

    const selectDepartment = (value: any[]) => {
        if (value?.length > 0) {
            setSelectedDepartments([value[value?.length - 1]]);
        } else {
            setSelectedDepartments([]);
        }
    }

    const hide = () => {
        dispatch(setShowEditFacebookPage(false));
        setModalShow(false);
        setIsSubmitting(false);
    }

    const validate = (values) => {
        let errors = {};

        setPageName(values['pageName']);

        if (!values.pageName) {
            errors['pageName'] = t("home_channels.lists_channels.list_facebook.modal_edit_info.feedback_page_name");
            setShowFeebackPageName(true);
        } else {
            setShowFeebackPageName(false);
        }

        return errors;
    }

    const getDepartments = async (controller: AbortController) => {
        const headers = UserService.getHeaders();
        const signal = controller.signal;

        await AppRequesterConst.Get(
            '/sector/actives', { headers, signal, params: { companyid: values.company.id } },
            (response: Object) => { },
            (data: DataSectorActivesInterface) => {
                if (data.status === 200 && data && data.data && data.data.sectors) {
                    let departmentsTuples = [];
                    let allDepartments = data.data.sectors;

                    allDepartments.map(element => {
                        departmentsTuples.push({ 'label': element.name, 'value': element.id });
                    });
                    setOptionsDepartments(departmentsTuples);
                }
            },
            (error: Object) => { }, navigate, dispatch, setIsLoading, { values: values }
        );
    }

    const updatePageName = async () => {
        const headers = UserService.getHeaders();
        
        let infoRoute = props.isFacebookPage? `/channel-facebook/update-facebook-keys/api/${props.data.pageId}`: `/channel-instagram/update-instagram-keys/api/${props.data.userId}`;
        
        let jsonInfo = {
            'page_name': pageName
        }

        await AppRequesterConst.Put(
            infoRoute, jsonInfo, { headers },
            (response: Object) => {
                return response;
            },
            (data: { data: { id: string; }; }) => {
                dispatch(setShowAlertFeedback({ message: t("home_channels.lists_channels.list_facebook.modal_edit_info.page_updated"), visibility: true, signalIcon: true }));
                props.updateListAfterEdit();
                hide();
                setIsSubmitting(true);
            },
            (error: { response: { data: { code_cxpress: number, message } } }) => {
                if (error?.response.data.message[0].path[0] === 'sector_id') {
                    dispatch(setShowAlertFeedback({ message: t("home_channels.lists_channels.list_facebook.modal_edit_info.sector_required"), visibility: true, signalIcon: false }));
                } else {
                    dispatch(setShowAlertFeedback({ message: verifyCode(error.response.data.code_cxpress, t), visibility: true, signalIcon: false }));
                }
                setIsSubmitting(false);
            }, navigate, dispatch, setIsLoading
        );
    }

    const onSubmit = async () => {
        const headers = UserService.getHeaders();

        let JsonSend_sector = props.isFacebookPage? 
            {
                "channel_id":  Channels.Facebook.toString(),
                "facebook_keys_id": props.data.id 
            }:
            {
                "channel_id":  Channels.Instagram.toString(),
                "instagram_keys_id": props.data.id 
            }

        // JsonSend_sector['name'] = pageName;

        if (selectedDepartments && selectedDepartments?.length >= 1) {
            JsonSend_sector["sector_id"] = selectedDepartments[0].value;
        } else {
            JsonSend_sector["sector_id"] = undefined;
        }

        await AppRequesterConst.Patch(
            'sector/key-association', JsonSend_sector, { headers },
            (response: Object) => {
                return response;
            },
            (data: { data: { id: string; }; }) => {
                dispatch(setShowAlertFeedback({ message: t("home_channels.lists_channels.list_facebook.modal_edit_info.page_updated"), visibility: true, signalIcon: true }));
                // props.updateListAfterEdit();
                hide();
                setIsSubmitting(true);
            },
            (error: { response: { data: { code_cxpress: number, message } } }) => {
                if (error?.response.data.message[0].path[0] === 'sector_id') {
                    dispatch(setShowAlertFeedback({ message: t("home_channels.lists_channels.list_facebook.modal_edit_info.sector_required"), visibility: true, signalIcon: false }));
                } else {
                    dispatch(setShowAlertFeedback({ message: verifyCode(error.response.data.code_cxpress, t), visibility: true, signalIcon: false }));
                }
                setIsSubmitting(false);
            }, navigate, dispatch, setIsLoading, updatePageName
        );
    }
    
    return <ModalFacebook
        modalShow={modalShow}
        hide={hide}
        selectedDepartments={selectedDepartments}
        dispatch={dispatch}
        navigate={navigate}
        setIsLoading={setIsLoading}
        optionsDepartments={optionsDepartments}
        setOptionsDepartments={setOptionsDepartments}
        setSelectedDepartments={setSelectedDepartments}
        t={t}
        onSubmit={onSubmit}
        isFacebookPage={props.isFacebookPage}
        data={props.data}
        validate={validate}
        showFeedbackPageName={showFeedbackPageName} 
        isLoading={isLoading}
        isSubmitting={isSubmitting}
        setIsSubmitting={setIsSubmitting}    
    />
}

export default ModalEditFacebookController