import { ListGroup, Row, Col, OverlayTrigger, Tooltip, Button } from 'react-bootstrap';

import InfiniteScroll from 'react-infinite-scroll-component';
import { formatPhoneNumberIntl } from 'react-phone-number-input';
import Loading from '../../../../../components/loading';
import ModalEditPhoneController from '../../../components/modalEditPhone/indexController';
import { useSelector } from 'react-redux';
import { getShowEditPhoneModal, getShowLinkModal } from '../../../../../store/internal';
import { IconBrandWhatsapp, IconPhone, IconPhoneOutgoing } from '@tabler/icons-react';
import Utils from '../../../../../core/shared/utils';
import { ListName } from '../../../../../core/enums/order-sort-by';
import ModalEditLinkController from '../../../components/modalEditLink/indexController';

const ListPhones = ({
    t,
    props,
    values,
    isLoading,
    popover,
    infos,
    phone,
    sector,
    phoneId
}) => {
    
    return (
        <>
            <Loading visibility={isLoading}></Loading>
            <ModalEditPhoneController token={values.token.value} visibility={useSelector(getShowEditPhoneModal)} number={phone} sector={sector} departments={props?.departaments} phoneId={phoneId} getListWhatsapp={props?.getListWhatsapp}></ModalEditPhoneController>
            <ModalEditLinkController token={values.token.value} visibility={useSelector(getShowLinkModal)} number={phone} sector={sector} departments={props?.departaments} phoneId={phoneId} getListWhatsapp={props?.getListWhatsapp}></ModalEditLinkController>
            {
                props?.datas !== undefined && props?.datas?.length > 0 ?
                    <>

                        <ListGroup variant="flush">
                            <ListGroup.Item className="TicketsRowCollums">
                                <Row name="listOfEmailsHead">
                                    <Col md={props.activePhone === false ? 4 : 2} className="nopadding">
                                        <span style={{ cursor: 'pointer' }} className="me-1" >{t(props.activePhone === false ? "home_channels.lists_channels.list_phones.whatsapp_number" : "home_channels.lists_channels.list_phones.phone_number")}</span>
                                    </Col>
                                    {props.activePhone === false ?
                                        <>
                                            <Col md={4} className="nopadding ps-0">
                                                <span style={{ cursor: 'pointer', marginLeft: '-0.3rem' }} className="me-1">{t("home_channels.lists_channels.list_phones.sector")}</span>
                                            </Col>
                                            <Col md={4} className='d-flex justify-content-end' >
                                                <p>{t("home_channels.lists_channels.list_phones.actions")}</p>
                                            </Col>
                                        </>
                                        :
                                        <>
                                            <Col md={1} className="nopadding ps-0">
                                                <span style={{ cursor: 'pointer' }} className="md-1">{t("home_channels.lists_channels.list_phones.agent")}</span>
                                            </Col>
                                        </>
                                    }
                                </Row>
                            </ListGroup.Item>
                        </ListGroup>
                        <div id="listOfPhones" style={{ overflowY: 'auto', height: '37vh' }}>
                            <InfiniteScroll
                                dataLength={props.datas.length}
                                next={props.updateListfunction}
                                hasMore={props.HasMoreInformation as boolean}
                                loader={<h4> </h4>}
                                scrollableTarget={"listOfPhones"}
                                onScroll={() => Utils.hidePopoverOnScroll(ListName.ListOfPhones)}
                            >
                                {
                                    <ListGroup variant="flush" className="TicketsRow" >
                                        {
                                            props?.datas.map((item, index) => {
                                                return (
                                                    <ListGroup.Item style={{ color: '#707070' }} id={item.id} key={"keyAll" + index.toString()}>
                                                        <Row className='TicketsRowHeight'>
                                                            {props.activePhone === true ?
                                                                <>
                                                                    <Col md={2} className='align-self-center nopadding'>
                                                                        <span style={{ color: 'black' }}>
                                                                            {
                                                                                item.preferred_number ?
                                                                                    <b>{formatPhoneNumberIntl("+" + item.preferred_number) + " / " + item.extension}</b> :
                                                                                    <b>{item.extension}</b>
                                                                            }
                                                                        </span>
                                                                    </Col>
                                                                    <Col md={2} className='align-self-center nopadding'>
                                                                        <span className='oneLineClampEllipsis'>
                                                                            {item.employee != null && item.employee ?
                                                                                <>{item.employee.name}</>
                                                                                :
                                                                                <>N/A</>
                                                                            }
                                                                        </span>
                                                                    </Col>
                                                                </>
                                                                :
                                                                <Col md={4} className='align-self-center nopadding' style={{ cursor: 'pointer' }} id={item.name + '*ID*' + item.id + '*ID*' + item.active}>
                                                                    <div className='oneLineClampEllipsis'>
                                                                        <OverlayTrigger
                                                                            key='top'
                                                                            placement='top'
                                                                            overlay={
                                                                                <Tooltip id='tooltip'>
                                                                                    {formatPhoneNumberIntl("+" + item?.phone_number) === "" ? item?.phone_number : formatPhoneNumberIntl("+" + item?.phone_number)}
                                                                                </Tooltip>
                                                                            }
                                                                        >
                                                                            <span>
                                                                                {formatPhoneNumberIntl("+" + item?.phone_number) === "" ? item?.phone_number : formatPhoneNumberIntl("+" + item?.phone_number)}
                                                                            </span>
                                                                        </OverlayTrigger>
                                                                    </div>
                                                                </Col>
                                                            }
                                                            <Col md={4} className='align-self-center nopadding' style={{ cursor: 'pointer' }} id={item.name + '*ID*' + item.id + '*ID*' + item.active}>
                                                                {props.activePhone === false &&
                                                                    <div className='oneLineClampEllipsis'>
                                                                        <OverlayTrigger
                                                                            key='top'
                                                                            placement='top'
                                                                            overlay={
                                                                                <Tooltip id='tooltip'>
                                                                                    {item.sector && item.sector.name ? item.sector.name : "N/A"}
                                                                                </Tooltip>
                                                                            }
                                                                        >
                                                                            <span>
                                                                                {item.sector && item.sector.name ? item.sector.name : "N/A"}
                                                                            </span>
                                                                        </OverlayTrigger>
                                                                    </div>
                                                                }
                                                            </Col>
                                                            {props.activePhone === false &&
                                                            <Col md={4} className='d-flex justify-content-end' >
                                                                <OverlayTrigger rootClose={true} trigger={['click']} placement="left" overlay={popover}>
                                                                    <Button id={"button*ID*" + item?.phone_number} name={item?.phone_number} onClick={() => infos(item?.phone_number, item?.sector, item?.id)} className='nopadding d-flex justify-content-start align-self-center' style={{ marginTop: '', backgroundColor: 'transparent', border: 'none', width: "24px" }}>
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="90%" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#6A6A6A" fill="#6A6A6A" strokeLinecap="round" strokeLinejoin="round">
                                                                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                                            <circle cx="12" cy="12" r="1" />
                                                                            <circle cx="12" cy="19" r="1" />
                                                                            <circle cx="12" cy="5" r="1" />
                                                                        </svg>
                                                                    </Button>
                                                                </OverlayTrigger>

                                                            </Col>
                                                            }
                                                        </Row>
                                                    </ListGroup.Item>);
                                            })}
                                    </ListGroup>
                                }
                            </InfiniteScroll>
                        </div>
                    </>
                    :
                    <>
                        <div className='align-self-center' style={{ overflow: 'hidden', height: '42vh', backgroundColor: '#F9F9F9' }}>
                            <Row className='d-flex justify-content-center align-content-center' style={{ marginTop: '17vh', backgroundColor: '#F9F9F9' }}>
                                <Col md={1} className="d-flex justify-content-center">
                                    {
                                        props.activePhone === true ?
                                        <IconPhoneOutgoing size={80} color="#4a69bd"/>
                                        :
                                        <IconBrandWhatsapp size={80} color="#4a69bd"/>
                                    }
                                </Col>
                            </Row>
                            <Row className='d-flex justify-content-center align-content-center mt-2' style={{ backgroundColor: '#F9F9F9' }}>
                                <Col md={4} className="d-flex justify-content-center align-content-center">
                                    <p style={{ color: '#0C2461', font: 'normal normal bold calc(0.2em + 0.7vw) Montserrat' }}>{t("home_channels.lists_channels.no_results_numbers")}</p>
                                </Col>
                            </Row>
                        </div>
                    </>
            }

        </>

    );
}

export default ListPhones;