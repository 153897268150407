import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom';
import { setShowAlertFeedback } from '../../../../../../store/internal';
import { getContractStatusId } from "../../../../../../store/company";
import { TokenInterface } from '../../../../../../services/requestsInterfacesModel';
import TrainBot from './index';
import { useTranslation } from 'react-i18next';
import { AppRequesterController } from '../../../../../../services/appRequester/appRequesterController';
import UserService from '../../../../../../services/user-service';

const TrainBotController = (props) => {
  const { t } = useTranslation();
  const dataBot = useLocation();
  const dispatch = useDispatch();
  const iframeRef = useRef(null);
  const appRequesterConst = new AppRequesterController();
  const navigate = useNavigate();
  
  const [botId, setBotId] = useState(dataBot.state.data ? dataBot.state.data.id : "");
  const [urlProtocol] = useState(window.location.hostname.includes('localhost') ? 'http' : 'https');
  const [iframeUrl, setIframeUrl] = useState(`${urlProtocol}://${window.location.host}/chat-bot-iframe/${botId}/?origin=cx_test`);
  const [urlNotTest] = useState(`${urlProtocol}://${window.location.host}/chat-bot-iframe/${botId}`);

  const [options] = useState([{ label: t("configurations.chatbots.view.bot_info.default"), value: "default" }, { label: t("configurations.chatbots.view.bot_info.floating"), value: "floating" }]);
  const [behavior, setBehavior] = useState([options[0]]);

  const [nameColor, setNameColor] = useState("#AFAFAF");
  const [headerColor, setHeaderColor] = useState("#1E1E1E");
  const [backgroundColor, setBackgroundColor] = useState("#F9F9F9");
  const [consumerMessageColor, setConsumerMessageColor] = useState("#707070");
  const [agentColor, setAgentColor] = useState("#E8E8E8");
  const [sendButtonColor, setSendButtonColor] = useState("#707070");

  const [attachmentId, setAttachmentId] = useState([]);
  const [previewImageURL, setPreviewImageURL] = useState("");
  const [imageBackupURL, setImageBackupURL] = useState("");
  const [resetAllFilesFlag, setResetAllFilesFlag] = useState(false);

  const [loadingReq, setLoadingReq] = useState(false);

  const values: TokenInterface = {
    company: {
      contractStatusId: useSelector(getContractStatusId)
    }
  };

  useEffect(() => {
    if (iframeUrl)
      props.setIsLoading(true);
  }, [iframeUrl]);

  useEffect(() => {
    if (behavior.length === 0 || behavior[0]?.value === "default") {
      setBehavior([options[0]]);
    }
  }, [behavior[0]?.value]);

  // postmessage para atualizar o comportamento dentro do iframe
  useEffect(() => {
      if (behavior.length > 0 && props.isLoading === false && iframeRef) {
        const message = { type: 'JSON', behavior: behavior[0].value };
        iframeRef.current.contentWindow.postMessage(message, window.origin);
      }
  }, [behavior[0].value, props.isLoading, iframeRef]);

  // enviando url da logo para o iframe
  useEffect(() => {
    if (previewImageURL && props.isLoading === false && iframeRef) {
      const message = { type: 'JSON', previewImageURL };
      iframeRef.current.contentWindow.postMessage(message, window.origin);
    }
  }, [previewImageURL, props.isLoading, iframeRef]);

  // enviando as cores
  useEffect(() => {
    if (props.isLoading === false && iframeRef) {
      const message = { type: 'JSON', nameColor, headerColor, backgroundColor, consumerMessageColor, agentColor, sendButtonColor };
      iframeRef.current.contentWindow.postMessage(message, window.origin);
    }
  }, [nameColor, headerColor, backgroundColor, consumerMessageColor, agentColor, sendButtonColor, props.isLoading, iframeRef]);

  useEffect(() => {
    const controller = new AbortController();
    getBotCxpressID(controller);

    return () => {
      controller.abort();
    }
  }, [botId]);

  // neste caso a imagem foi removida então é melhor restaurar o backup
  useEffect(() => {
    if (previewImageURL === "" && imageBackupURL.length > 0) {
      setPreviewImageURL(imageBackupURL);
    }
  }, [previewImageURL, imageBackupURL]);

  const copyToClipboard = (e) => {
    if(behavior.length > 0 && behavior[0].value === "floating") {
      var copyText = `<iframe src="${urlNotTest}" style="position: fixed; bottom: 0; right: 0; width: 350px; height: 480px; border: none; z-index: 9999;"></iframe>`;
    } else {
      var copyText = `<iframe src="${urlNotTest}"></iframe>`;
    }
    navigator.clipboard.writeText(copyText)
    dispatch(setShowAlertFeedback({ message: t("configurations.chatbots.view.bot_info.clipboard_copy"), visibility: true, signalIcon: true }));
  }

  const getBotCxpressID = async (controller: AbortController) => {
    const headers = UserService.getHeaders();
    const signal = controller.signal;

    await appRequesterConst.Get(
      "/bot-cxpress/" + botId,
      { headers: headers, params: {}, signal },
      (response: Object) => { },
      (data) => {
        if (data.data.botCxpress?.webchat_keys) {
          const botCustomData = data.data.botCxpress.webchat_keys;
    
          setNameColor(botCustomData.bot_name_color);
          setConsumerMessageColor(botCustomData.balloon_consumer_color);
          setAgentColor(botCustomData.balloon_agent_color);
          setSendButtonColor(botCustomData.button_color);
          setHeaderColor(botCustomData.header_background_color);
          setBackgroundColor(botCustomData.background_color);
          setBehavior([botCustomData.behavior === 'default'? options[0]: options[1]]);
          if (botCustomData.bot_logo_url) {
            setPreviewImageURL(botCustomData.bot_logo_url);
            setImageBackupURL(botCustomData.bot_logo_url);
          }
        }
      },
      (error: Object) => { },
      navigate, dispatch, () => {}, { values: values }
    )
  }

  const onSubmit = async () => {
    if (isHexColor(nameColor) && isHexColor(headerColor) && isHexColor(backgroundColor) && isHexColor(consumerMessageColor) && isHexColor(agentColor) && isHexColor(sendButtonColor)) {
      const JsonSend = {
        webchat_keys_id: dataBot.state.data.webchat_keys_id,
        bot_name_color: nameColor,
        header_background_color: headerColor,
        background_color: backgroundColor,
        button_color: sendButtonColor,
        balloon_consumer_color: consumerMessageColor,
        balloon_agent_color: agentColor,
        behavior: behavior[0].value === "floating"? "float": "default",
      };

      if (attachmentId[0]) {
        JsonSend['logo_id'] = attachmentId.join('');
      }

      const headers = UserService.getHeaders();
  
      await appRequesterConst.Put(
        '/bot-cxpress/iframe/configuration', JsonSend, { headers },
        (response: Object) => { },
        (data: Object) => {
          dispatch(setShowAlertFeedback({ message: t("configurations.chatbots.view.custom_chat_bot.changes_salved"), visibility: true, signalIcon: true }));
        },
        (error: { response: { status: number; }; }) => {
          if (error.response !== undefined) {
            if (error.response.status === 400) {
            }
            if (error.response.status === 401) {
            }
          }
        }, navigate, dispatch, setLoadingReq
      );
    } else {
      dispatch(setShowAlertFeedback({ message: t("configurations.chatbots.view.custom_chat_bot.wrong_hex_color"), visibility: true, signalIcon: false }));
    }
  }

  // Função que valida se uma string é uma cor hexadecimal válida
  function isHexColor(color: string) {
    return /^#([0-9A-F]{3}){1,2}$/i.test(color);
  }

  const resetIcon = () => {
    setAttachmentId([]);
    setPreviewImageURL(null);
    setResetAllFilesFlag(true);
  }

  return (
    <TrainBot
      t={t}
      props={props}
      dataBot={dataBot}
      values={values}
      iframeUrl={iframeUrl}
      setIframeUrl={setIframeUrl}
      urlNotTest={urlNotTest}
      copyToClipboard={copyToClipboard}
      channelId={props.channelId}
      iframeRef={iframeRef}
      options={options}
      behavior={behavior}
      setBehavior={setBehavior}
      nameColor={nameColor}
      setNameColor={setNameColor} 
      headerColor={headerColor} 
      setHeaderColor={setHeaderColor} 
      backgroundColor={backgroundColor} 
      setBackgroundColor={setBackgroundColor} 
      consumerMessageColor={consumerMessageColor} 
      setConsumerMessageColor={setConsumerMessageColor} 
      agentColor={agentColor} 
      setAgentColor={setAgentColor} 
      sendButtonColor={sendButtonColor} 
      setSendButtonColor={setSendButtonColor}    
      onsubmit={onSubmit}
      setAttachmentId={setAttachmentId}
      previewImageURL={previewImageURL}
      setPreviewImageURL={setPreviewImageURL}
      isLoading={loadingReq}
      resetIcon={resetIcon}
      resetAllFilesFlag={resetAllFilesFlag}
      setResetAllFilesFlag={setResetAllFilesFlag}
    />
  );
}

export default TrainBotController;