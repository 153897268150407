import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getShowRegisterTagsModal, setShowAlertFeedback, setShowRegisterTagsModal } from "../../../../store/internal";
import ModalRegisterTagsTickets from ".";
import { AppRequesterController } from "../../../../services/appRequester/appRequesterController";
import UserService from "../../../../services/user-service";
import { DataTagIdInterface, TokenInterface } from "../../../../services/requestsInterfacesModel";
import { getToken } from "../../../../store/token";

const ModalRegisterTagsTicketsController = (props) => {

    const AppRequesterConst = new AppRequesterController();
    const [isLoading, setIsLoading] = useState(false)
    const [tagsOptions, setTagsOptions] = useState([]);
    const [currentTags, setCurrentTags] = useState([]);

    const [modalShow, setModalShow] = useState(props.visibility);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const values: TokenInterface = {
        token: {
            value: useSelector(getToken)
        },
        internal: {
            getShowRegisterTagsModal: useSelector(getShowRegisterTagsModal)
        }
    }

    useEffect(() => {
        if (values.internal.getShowRegisterTagsModal) {
            dispatch(setShowRegisterTagsModal(false))
        }
    }, [])

    useEffect(() => {
        setModalShow(props.visibility);
        if (props.visibility) {
            getTags();
        }
    }, [props.visibility]);


    const Hide = () => {
        setTagsOptions([]);
        setCurrentTags([]);
        props.setDeleteListTickets([])
        dispatch(setShowRegisterTagsModal(false));
    }

    const getTags = async () => {
        const headers = UserService.getHeaders();

        await AppRequesterConst.Get(
            '/tag?is_active=true&limit=1000', { headers },
            (response: Object) => { },
            (data: DataTagIdInterface) => {
                if (data.status === 200 && data.data.tags) {
                    let tags = [];
                    data.data.tags.map((item) => {
                        tags.push({ label: item.name, value: item.id });
                    });
                    setTagsOptions(state => {
                        return state.concat(tags);
                    });
                }
            },
            (error: Object) => { }, navigate, dispatch, setIsLoading, { values: values }
        );
    }

    return <ModalRegisterTagsTickets
        t={t}
        modalShow={modalShow}
        Hide={Hide}
        AppRequesterConst={AppRequesterConst}
        dispatch={dispatch}
        setShowAlertFeedback={setShowAlertFeedback}
        callback={props.callback}
        navigate={navigate}
        setIsLoading={setIsLoading}
        tagsOptions={tagsOptions}
        setTagsOptions={setTagsOptions}
        currentTags={currentTags}
        setCurrentTags={setCurrentTags}
        deleteListTickets={props.deleteListTickets}
    />

}

export default ModalRegisterTagsTicketsController