import { useState, useEffect, FC } from 'react';
import { useDispatch } from 'react-redux'
import 'bootstrap/dist/css/bootstrap.min.css';
import { setShowEditEmailModal } from '../../../../store/internal'
import { AppRequesterController } from '../../../../services/appRequester/appRequesterController';
import { useNavigate } from 'react-router-dom';
import { ModalEditEmailInterface } from './indexModel';
import ModalEditEmail from '.';
import { useTranslation } from 'react-i18next';

const AppRequesterConst = new AppRequesterController();

const ModalEditEmailController: FC<ModalEditEmailInterface> = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {t} = useTranslation();

    const [/*isLoading*/, setIsLoading] = useState(false)
    const [validated] = useState(false);
    const [email_id, setEmail_id] = useState((props.data && props.data.id != null) ? props.data.id : "");
    const [/*currentEmail*/, setCurrentEmail] = useState(null as string || null);
    const [showfeedbackEmail, setShowfeedbackEmail] = useState(false);
    const [email_isActive, setEmail_isActive] = useState((props.data && props.data.active != undefined) ? props.data.active : true);
    const [selected_departments, setSelected_departments] = useState((props.data && props.data.department_selected) ? props.data.department_selected : []);
    const [modalShow, setModalShow] = useState(props.visibility);
    const [options_departments, setOptions_departments] = useState((props.data && props.data.departments) ? props.data.departments : []);
    const [isSubmitting, setIsSubmitting] = useState(false);

    useEffect(() => {
        setModalShow(props.visibility);
        setEmail_isActive((props.data && props.data.active != null) ? props.data.active : true);
        setOptions_departments((props.data && props.data.departments) ? props.data.departments : []);
        setSelected_departments((props.data && props.data.department_selected) ? props.data.department_selected : []);
        setEmail_id((props.data && props.data.id != null) ? props.data.id : "");
    }, [props.visibility, props.data])

// const selectDepartment = (value: any[]) => {
//     if(value.length > 0) {
//         setSelected_departments([value[value.length - 1]]);
//     } else {
//         setSelected_departments([]);
//     }
// }
    
    const Hide = () => {
        dispatch(setShowEditEmailModal(false));
        setModalShow(false);
        setIsSubmitting(false);
    }

    const Validate = (values) => {
        let errors = {};

        if (!values.email) {
            errors["email"] = 'Preencha este campo';
            setShowfeedbackEmail(true);
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,10}$/i.test(values.email) && values.email) {
            errors["email"] = 'E-mail inválido';
            setShowfeedbackEmail(true);
        } else {
            setShowfeedbackEmail(false);
        }

        return errors;
    }

    return <ModalEditEmail
        modalShow={modalShow}
        Hide={Hide}
        validated={validated}
        setIsLoading={setIsLoading}
        options_departments={options_departments}
        setOptions_departments={setOptions_departments}
        selected_departments={selected_departments}
        setSelected_departments={setSelected_departments}
        email_isActive={email_isActive}
        setEmail_isActive={setEmail_isActive}
        email_id={email_id}
        setCurrentEmail={setCurrentEmail}
        showfeedbackEmail={showfeedbackEmail}
        Validate={Validate}
        AppRequesterConst={AppRequesterConst}
        props={props}
        dispatch={dispatch}
        navigate={navigate}
        t={t}
        isSubmitting={isSubmitting}
        setIsSubmitting={setIsSubmitting}
    />
}

export default ModalEditEmailController;