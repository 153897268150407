import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import ModalRegisterCustomFields from ".";
import { useTranslation } from "react-i18next";

const ModalRegisterCustomFieldsController = ({ show, handleClose, callback }) => {
    const { t } = useTranslation()

    const [isLoading, setIsLoading] = useState(false)
    const [validated] = useState(false);
    const [name, setName] = useState('');
    const [min, setMin] = useState<number>();
    const [max, setMax] = useState<number>();
    const [showFeedbackName, setShowFeedbackName] = useState(false);
    const [type1, setType1] = useState([
        { 'label': t('configurations_ticket.modal_register_custom_field.text_type'), 'value': t('configurations_ticket.modal_register_custom_field.text_type') },
        { 'label': t('configurations_ticket.modal_register_custom_field.number_type'), 'value': t('configurations_ticket.modal_register_custom_field.number_type') },
        { 'label': t('configurations_ticket.modal_register_custom_field.general_type'), 'value': t('configurations_ticket.modal_register_custom_field.general_type') },]);
    const [type, setType] = useState([
        { 'label': t('configurations_consumers.modal_register_custom_field.simple_text_label'), 'value': t('configurations_consumers.modal_register_custom_field.simple_text_value') },
        { 'label': t('configurations_consumers.modal_register_custom_field.multiline_text_label'), 'value': t('configurations_consumers.modal_register_custom_field.multiline_text_value') },
    ]);
    const [selectedType, setSelectedType] = useState([])
    const [selectedType1, setSelectedType1] = useState([])
    const [selectedOptions, setSelectedOptions] = useState([])
    const [selectedsOptions, setSelectedsOptions] = useState([])
    const [/*defaultValue*/, setDefaultValue] = useState('')
    const [labelledOptions, setLabelledOptions] = useState([])
    const [placeholder, setPlaceholder] = useState('')
    const [showFeedbackPlaceholder, setShowFeedbackPlaceholder] = useState(false)
    const [showFeedbackMin, setShowFeedbackMin] = useState(false);
    const [showFeedbackMax, setShowFeedbackMax] = useState(false);
    const entityId = 'b93b0740-a77d-4474-aad9-7aa0044d5654'

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const clearFields = () => {
        setName('')
        setShowFeedbackName(false)
        setSelectedType([])
        setSelectedType1([])
        setSelectedsOptions([])
        setSelectedOptions([])
        setPlaceholder('')
        setShowFeedbackPlaceholder(false)
        handleClose()
        setShowFeedbackMin(false)
        setShowFeedbackMax(false)
        setMin(undefined)
        setMax(undefined)
    }

    const onChangeTags = (e: { detail: { tagify: { getCleanValue: Function; }; }; }) => {
        setSelectedOptions(e.detail.tagify.getCleanValue().map((tag) => {
            return tag.value;
        }));
    }

    useEffect(() => {
        if (selectedOptions.length > 0) {
            setLabelledOptions(selectedOptions ? selectedOptions?.map((option, index) => {
                return ({ label: option, value: option })
            }) : [])
        }

    }, [selectedOptions])

    const handleChangeWithValidation = (e, setFieldValue) => {
        const { id, value } = e.target;
        const validValue = value.replace(/[^a-zA-Z0-9À-ÿÇç ]/g, '');
        setFieldValue(id, validValue);
    };

    return <ModalRegisterCustomFields
        show={show}
        clearFields={clearFields}
        type={type}
        type1={type1}
        setName={setName}
        setType={setType}
        setType1={setType1}
        setSelectedOptions={setSelectedOptions}
        setDefaultValue={setDefaultValue}
        setPlaceholder={setPlaceholder}
        setShowFeedbackName={setShowFeedbackName}
        showFeedbackMin={showFeedbackMin}
        showFeedbackMax={showFeedbackMax}
        setShowFeedbackPlaceholder={setShowFeedbackPlaceholder}
        placeholder={placeholder}
        entityId={entityId}
        selectedType={selectedType}
        selectedType1={selectedType1}
        labelledOptions={labelledOptions}
        selectedsOptions={selectedsOptions}
        dispatch={dispatch}
        callback={callback}
        navigate={navigate}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        showFeedbackName={showFeedbackName}
        setShowFeedbackMin={setShowFeedbackMin}
        setShowFeedbackMax={setShowFeedbackMax}
        validated={validated}
        setSelectedType={setSelectedType}
        setSelectedType1={setSelectedType1}
        onChangeTags={onChangeTags}
        selectedOptions={selectedOptions}
        showFeedbackOptionList={selectedOptions}
        setLabelledOptions={setLabelledOptions}
        setSelectedsOptions={setSelectedsOptions}
        showFeedbackPlaceholder={showFeedbackPlaceholder}
        name={name}
        min={min}
        max={max}
        setMin={setMin}
        setMax={setMax}
        handleChangeWithValidation={handleChangeWithValidation}
    />
}

export default ModalRegisterCustomFieldsController;