import React, { useEffect, useState } from 'react'
import ViewEmailActivity from '.';
import UserService from '../../../services/user-service';
import { AppRequesterController } from '../../../services/appRequester/appRequesterController';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { TokenInterfaceControlPanel } from '../../../services/requestsInterfacesModel';
import { getToken } from '../../../store/control_panel';
import BackendConstants from '../../../core/constants/backend-constants';
import { setShowAlertFeedback } from '../../../store/internal';

const ViewEmailActivityController = () => {

    const values: TokenInterfaceControlPanel = {
        token: {
            value: useSelector(getToken)
        }
    }

    const AppRequesterConst = new AppRequesterController();

    const [data, setData] = useState([]);
    const [hasMoreInformation, setHasMoreInformation] = useState(false);
    const [aux, setAux] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const [currentParams, setCurrentParams] = useState({});
    const [currentPage, setCurrentPage] = useState(1);
    const [currentState, setCurrentState] = useState("");
    const [currentFilterSearch, setCurrentFilterSearch] = useState(null);

    const [noResults, setNoResults] = useState(false); // flag para verificar se deve mostrar a mensagem de sem resultados ou não


    const [currentLimit] = useState(BackendConstants.messagesTriggersLimit.typesLimit);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        const controller = new AbortController()

        setCurrentParams((params) => {
            if (params !== null && Object.keys(params).length > 0) {
                setCurrentState((state) => {
                    getEmailActivityList(Object.assign(currentParams), controller);
                    return state;
                });
            }
            else {
                setData([]); /* deixando a listagem vazia até ser usado um filtro */
            }
            return params;
        });
    }, [currentPage, currentState])


    const getEmailActivityList = async (params, controller) => {
        const headers = UserService.getHeaders();
        const signal = controller.signal;

        await AppRequesterConst.Get(
        'panel/sendgrid/activity', { params, headers: headers, signal},
        (response: Object) => {
            return response;
        },
        (data) => {
            if (data.status === 200 && data) {
                setHasMoreInformation(true);
                setAux((state) => {
                    let aux = state.concat(data?.data.data);
                    setData(aux)
                    return state;
                });
            }
            else if (data.status === 204) {
               setNoResults(true); 
            }
        },
        (error) => {
            if (error.response.status === 422) {
                dispatch(setShowAlertFeedback({ visibility: true, message: "E-mail inválido.", signalIcon: false }));
            }
            else {
                dispatch(setShowAlertFeedback({ visibility: true, message: "Ocorreu um erro inesperado, tente novamente mais tarde.", signalIcon: false }));
            }
        }, navigate, dispatch, setIsLoading, { values: values }
        );
    }
    
    const filteringEmails = (e: { currentTarget: { name: string; }; target: { name: string; }; }, auxObj: Object, action: string) => {
        setCurrentPage(1)
        setAux([])
        if (document.getElementById("listOfTickets") !== null) {
            document.getElementById("listOfTickets").scrollTop = 0
        }

        let name = ""
        if (e.currentTarget) {
            name = e.currentTarget.name
        } else if (e.target) {
            name = e.target.name
        }

        if (name === "cleanSearchButtonName" || action === "clean") {
            setCurrentFilterSearch("")
            auxObj = {}
            setCurrentState("searching")
            if (currentState === "searching") {
                setCurrentState("")
            }
        } else if (name === "searchButtonName" || name === "searchButtonName") {
            auxObj = {}

            setCurrentState("searching")
            if (currentState === "searching") {
                setCurrentState("")
            }

            if (currentFilterSearch !== null && currentFilterSearch !== "") {
                auxObj["search"] = currentFilterSearch
            }
        } else {
            setCurrentState("filtering")

            if (currentState === "filtering") {
                setCurrentState("")
            }
        }

        setCurrentParams(auxObj)
    }

    const cleanList = () => {
        setCurrentParams({});
        setCurrentPage(1);
        setAux([]);
        setNoResults(false);

        setCurrentState("filtering");

        if (currentState === "filtering") {
            setCurrentState("");
        }
    }

    const updateListfunction = () => {
        setCurrentPage(currentPage + 1);
    };

    return (
        <ViewEmailActivity 
            setCurrentFilterSearch={setCurrentFilterSearch}
            currentFilterSearch={currentFilterSearch}
            setCurrentState={setCurrentState}
            currentState={currentState}
            setCurrentPage={setCurrentPage}
            setAux={setAux}
            setCurrentParams={setCurrentParams}
            filteringTickets={filteringEmails}
            cleanList={cleanList}
            updateListfunction={updateListfunction}
            hasMoreInformation={hasMoreInformation}
            data={data}
            isLoading={isLoading}
            noResults={noResults}
        />
    )
}

export default ViewEmailActivityController;