import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { AppRequesterController } from '../../../../services/appRequester/appRequesterController';
import { getIdCompany } from '../../../../store/company';
import { getIdEmployee } from '../../../../store/employee';
import { getIdUser } from '../../../../store/user';
import { getToken } from '../../../../store/token';
import { getShowConfirmationModal, getShowMeetButton } from '../../../../store/internal';
import { getOwner_id } from '../../../../store/callcenter';
import { TokenInterface } from '../../../../services/requestsInterfacesModel';
import ModalEditCustomFieldTickets from '.';
import { useTranslation } from 'react-i18next';
import { CustomField } from '../../../../core/enums/custom-field-type';

export interface optionsType {
    value: string,
    label: string,
}

const ModalEditCustomFieldTicketsController = ({ show, handleClose, data, callBackModalCustomField }) => {
    const { t } = useTranslation()

    const [validated] = useState(false);
    const [name, setName] = useState('');
    const [min, setMin] = useState<number>();
    const [max, setMax] = useState<number>();
    const [showFeedbackName, setShowFeedbackName] = useState(false);
    const [showFeedbackplaceholder, setShowFeedbackplaceholder] = useState(false);
    const [type1, /*setType1*/] = useState([
        { 'label': t('configurations_ticket.modal_register_custom_field.text_type'), 'value': t('configurations_ticket.modal_register_custom_field.text_type') },
        { 'label': t('configurations_ticket.modal_register_custom_field.number_type'), 'value': t('configurations_ticket.modal_register_custom_field.number_type') },
        { 'label': t('configurations_ticket.modal_register_custom_field.general_type'), 'value': t('configurations_ticket.modal_register_custom_field.general_type') },]);
    const [type, /*setType*/] = useState([
        { 'label': t('configurations_ticket.modal_edit_custom_field.simple_text_label'), 'value': t('configurations_ticket.modal_edit_custom_field.simple_text_value') },
        { 'label': t('configurations_ticket.modal_edit_custom_field.multiline_text_label'), 'value': t('configurations_ticket.modal_edit_custom_field.multiline_text_value') },
        { 'label': t('configurations_ticket.modal_edit_custom_field.simple_select'), 'value': t('configurations_ticket.modal_edit_custom_field.simple_select') },
        { 'label': t('configurations_ticket.modal_edit_custom_field.multiple_select'), 'value': t('configurations_ticket.modal_edit_custom_field.multiple_select') }]);
    const [selectedType, setSelectedType] = useState([])
    const [selectedType1, setSelectedType1] = useState([])
    const [selectedOptions, setSelectedOptions] = useState([])
    const [selectedsOptions, setSelectedsOptions] = useState([])
    const [labelledOptions, setLabelledOptions] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [/*idType*/, setIdType] = useState("")
    const [placeholder, setPlaceholder] = useState("")
    const [customTicketIsRequired, setCustomTicketIsRequired] = useState(false)
    const [showFeedbackMin, setShowFeedbackMin] = useState(false);
    const [showFeedbackMax, setShowFeedbackMax] = useState(false);

    const clearFields = () => {
        setSelectedType([])
        setSelectedType1([])
        setSelectedsOptions([])
        setSelectedOptions([])
        setShowFeedbackName(false);
        handleClose()
        setShowFeedbackMin(false)
        setShowFeedbackMax(false)
        setMin(undefined)
        setMax(undefined)
    }

    const onChangeTags = (e: { detail: { tagify: { getCleanValue: Function; }; }; }) => {
        setSelectedOptions(e.detail.tagify.getCleanValue().map((tag) => {
            return tag.value;
        }));
    }

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const AppRequesterConst = new AppRequesterController()

    const values: TokenInterface = {
        company: {
            id: useSelector(getIdCompany)
        },
        employee: {
            id: useSelector(getIdEmployee)
        },
        user: {
            id: useSelector(getIdUser),
        },
        token: {
            value: useSelector(getToken)
        },
        internal: {
            getShowConfirmationModal: useSelector(getShowConfirmationModal),
        },
        callcenter: {
            owner_id: useSelector(getOwner_id),
            buttonShowPhone: useSelector(getShowMeetButton)
        }
    };

    useEffect(() => {
        if (selectedOptions?.length > 0) {
            setLabelledOptions(selectedOptions ? selectedOptions?.map((option, index) => {
                return ({ label: option, value: option })
            }) : [])
        }
    }, [selectedOptions])

    useEffect(() => {
        if (data !== null) {
            // if (data.custom_field_type_id === 'd31ebd9e-af9f-4559-b04a-729439218a6f') {
            //     setSelectedType([type[0]])
            //     setIdType(data.custom_field_type_id)
            // } else if (data.custom_field_type_id === '2982a738-4de3-440a-8ed1-cc1fdb51bc9b') {
            //     setSelectedType([type[1]])
            //     setIdType(data.custom_field_type_id)
            // }
            // else if (data.custom_field_type_id === '74bf4b70-3415-4e8a-ac81-7ebe435c1424') {
            //     setSelectedType([type[2]])
            //     setIdType(data.custom_field_type_id)
            // }
            // else if (data.custom_field_type_id === '49d495a8-50d4-4d61-9d62-e1cc03227573') {
            //     setSelectedType([type[3]])
            //     setIdType(data.custom_field_type_id)
            // }
            if (data.custom_field_datatype_id === CustomField.Text1) {
                setSelectedType1([type1[0]])
                setIdType(data.custom_field_datatype_id)
            } else if (data.custom_field_datatype_id === CustomField.Number) {
                setSelectedType1([type1[1]])
                setIdType(data.custom_field_datatype_id)
            }
            else if (data.custom_field_datatype_id === CustomField.General) {
                setSelectedType1([type1[2]])
                setIdType(data.custom_field_datatype_id)
            }
            
            setSelectedOptions(data?.options)
            setSelectedsOptions([{ label: data?.default_value, value: data?.default_value }])
            setCustomTicketIsRequired(data?.required)
        }
    }, [data])

    const handleChangeWithValidation = (e, setFieldValue) => {
        const { id, value } = e.target;
        const validValue = value.replace(/[^a-zA-Z0-9À-ÿÇç ]/g, '');
        setFieldValue(id, validValue);
    };

    return <ModalEditCustomFieldTickets
        show={show}
        clearFields={clearFields}
        data={data}
        selectedOptions={selectedOptions}
        setName={setName}
        setPlaceholder={setPlaceholder}
        setShowFeedbackName={setShowFeedbackName}
        setShowFeedbackMin={setShowFeedbackMin}
        setShowFeedbackMax={setShowFeedbackMax}
        setShowFeedbackplaceholder={setShowFeedbackplaceholder}
        placeholder={placeholder}
        selectedType={selectedType}
        selectedType1={selectedType1}
        name={name}
        labelledOptions={labelledOptions}
        selectedsOptions={selectedsOptions}
        AppRequesterConst={AppRequesterConst}
        dispatch={dispatch}
        callBackModalCustomField={callBackModalCustomField}
        navigate={navigate}
        validated={validated}
        setIsLoading={setIsLoading}
        showFeedbackName={showFeedbackName}
        showFeedbackMin={showFeedbackMin}
        showFeedbackMax={showFeedbackMax}
        onChangeTags={onChangeTags}
        setLabelledOptions={setLabelledOptions}
        setSelectedsOptions={setSelectedsOptions}
        showFeedbackplaceholder={showFeedbackplaceholder}
        t={t}
        customTicketIsRequired={customTicketIsRequired}
        setCustomTicketIsRequired={setCustomTicketIsRequired}
        min={min}
        max={max}
        handleChangeWithValidation={handleChangeWithValidation}
    />
}

export default ModalEditCustomFieldTicketsController