import React, { FC } from 'react';
import { Col, Container, FormLabel, Row, Spinner } from 'react-bootstrap';
import styles from './styles';
import { AttachmentsInMessageProps } from './indexModel';
import Loading from '../../../../../../components/loading';
import { useTranslation } from 'react-i18next';

const AttachmentsInMessage: FC<AttachmentsInMessageProps> = (props) => {
  const { t } = useTranslation();
    const showFiles = props.files.map((file, index) => (
        <span key={file.name + index}
          style={{
            background: '#E8E8E8 0% 0% no-repeat padding-box',
            borderRadius: '3px',
            opacity: '1',
            marginRight: '0.5vw',
            marginBottom: '1vh',
            whiteSpace: 'pre', display: 'inline-block',
          }}>
          <svg id="Grupo_11419" data-name="Grupo 11419" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <path id="Caminho_9725" data-name="Caminho 9725" d="M0,0H24V24H0Z" fill="none" />
            <path id="Caminho_9726" data-name="Caminho 9726" d="M14,3V7a1,1,0,0,0,1,1h4" fill="none" stroke="#707070" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
            <path id="Caminho_9727" data-name="Caminho 9727" d="M17,21H7a2,2,0,0,1-2-2V5A2,2,0,0,1,7,3h7l5,5V19A2,2,0,0,1,17,21Z" fill="none" stroke="#707070" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
            <line id="Linha_391" data-name="Linha 391" y2="6" transform="translate(12 11)" fill="none" stroke="#707070" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
            <path id="Caminho_9728" data-name="Caminho 9728" d="M9,14l3-3,3,3" fill="none" stroke="#707070" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
          </svg>
          <span
            onClick={ (e) => { e.stopPropagation(); props.handleDownloadFile(file); }}
            style={{
              textAlign: 'left',
              font: 'var(--px10_13-font-Roboto)',
              letterSpacing: '0px',
              color: '#1E1E1E',
              opacity: '1',
              // maxWidth: '70%'
            }}
            // className='oneLineClampEllipsis'
          >
            <span 
              style={{  }}
            >
              {file.name}
            </span>
          </span>
          { props.fileLoading[index] === false ? file.isLoading = false : "" }
          {!props.fileLoading[index] ?
            props?.visualization ? 
              undefined :
              <svg name={file.name} onClick={(e) => { props.deleteFile(file.name); e.stopPropagation(); } } style={{ cursor: 'pointer', marginRight: '3px', marginLeft: '3px' }} id="Grupo_11428" data-name="Grupo 11428" xmlns="http://www.w3.org/2000/svg" width="14.438" height="14.438" viewBox="0 0 14.438 14.438">
                <path id="Caminho_9729" data-name="Caminho 9729" d="M0,0H14.438V14.438H0Z" fill="none" />
                <line id="Linha_392" data-name="Linha 392" x1="7" y2="7" transform="translate(4 3.438)" fill="none" stroke="#707070" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                <line id="Linha_393" data-name="Linha 393" x2="7" y2="7" transform="translate(4 3.438)" fill="none" stroke="#707070" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
              </svg>
            :
            <Spinner animation="border" role="status" style={{ marginRight: '3px', marginLeft: '3px', width: "14.438px", height: "14.438px" }} >
            </Spinner>
          }
        </span>
      ));

    return (
        <Container>
            {/* <Loading isLoading={props.isLoading} /> */}
            <Row className="mb-3 d-flex justify-content-center" style={styles.textFontGlay}>
                <Col /* sm={true} */ className={props.largerStyle? 'px-0': ''}>
                  { (!props.hiddenTitle) && <FormLabel htmlFor='basic-url'>{t('home_departments.template_message_tab.attachment')}</FormLabel> }
                  <div style={{ width: props.largerStyle? "100%":'70%', display: 'flex', overflowY: 'hidden'}}>
                    <div {...props.getRootProps({ className: 'dropzone' })}
                      style={styles.dragDropzone}>
                      <input style={{backgroundColor: 'red'}} {...props.getInputProps({})} />
                      {props.files.length > 0 ?
                        <div name="showFilesAttachments" style={{ width: '100%' }} className="ps-2 py-1 pt-2">
                          <div style={{ display: 'block', height: '3vh', overflowY: 'auto' }}>
                              {showFiles}
                          </div>
                        </div>
                        :
                        props.dropMessage? props.dropMessage: 
                        <div>
                          <div>{props.selectedChannelId ? t("home_message_trigger.list_of_messages_triggers.message_file") : "Selecione o Canal"}</div>
                        </div>
                      }
                    </div>
                  </div>
                </Col>
            </Row>
        </Container>
    );
}

export default AttachmentsInMessage;